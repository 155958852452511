import styled from 'styled-components'
import type { InputProps } from './InputProps'

const Input = styled.input<InputProps>`
	border-bottom: 1px solid #ddd;
	border: ${({ isValid }: InputProps) => (!isValid ? 'red 1px solid' : 'inherit')};
`

const TextArea = styled.textarea<InputProps>`
	border-bottom: 1px solid #ddd;
	border: ${({ isValid }: InputProps) => (!isValid ? 'red 1px solid' : 'inherit')};
`

export { Input, TextArea }
