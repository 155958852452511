import type { ExecuteShowActions } from '../interfaces/actions/ExecuteShowActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function executeShow(state = initialState, action: ExecuteShowActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.EXECUTE_SHOW_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.EXECUTE_SHOW_FAILURE:
			console.error('EXECUTE_SHOW_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.EXECUTE_SHOW_SUCCESS:
			console.log('EXECUTE_SHOW_SUCCESS')
			return { ...state, isLoading: false }
		default:
			return state
	}
}
