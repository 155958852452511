import dayjs from 'dayjs'
import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React from 'react'
import { tooltipId } from '../../config/const'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { useNextShowTimeUpdateCommand } from '../../utils/hooks/commands/useNextShowTimeUpdate'
import { useNextShowDateTime } from '../../utils/hooks/dashboardInputs/useNextShowTime'

function NextShowTime(): FunctionComponentElement<typeof CardWrapper> {
	const dateFormat = 'YYYY-MM-DD'
	const today = dayjs().format(dateFormat)

	const [nextShowDateTime, updateNextShowDateTime] = useNextShowDateTime()
	const [{ isUpdating }, update] = useNextShowTimeUpdateCommand()

	const onChangeDate = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value: date },
		} = event

		updateNextShowDateTime({ time: nextShowDateTime.time.value, date })
	}

	const onChangeTime = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value: time },
		} = event

		updateNextShowDateTime({ date: nextShowDateTime.date.value, time })
	}

	const onOverwriteClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.stopPropagation()

		update()
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Reset Date & Time</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Date</label>
					<CardInput
						type="date"
						onChange={onChangeDate}
						value={nextShowDateTime.date.value}
						isValid={nextShowDateTime.date.isValid}
						min={today}
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>Time</label>
					<CardInput
						type="time"
						onChange={onChangeTime}
						value={nextShowDateTime.time.value}
						isValid={nextShowDateTime.time.isValid}
						step="60"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<CardButton
						type="button"
						disabled={isUpdating}
						onClick={onOverwriteClick}
						data-tooltip-content="This will ONLY overwrite the countdown timer shown on the main screen"
						data-tooltip-id={tooltipId}
					>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default NextShowTime
