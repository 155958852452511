import type { ChatBanUnbanActions, GetChatBannedPlayersActions } from '../interfaces/ChatBanActions'
import type { ChatBansState } from '../interfaces/ChatBansState'
import { REDUX_CHAT_BANS_TYPES } from '../types'

export const initialState: ChatBansState = {
	isLoading: false,
	errorOnGet: null,
	errorOnBan: null,
	errorOnUnban: null,
	banned: [],
	tryingToBan: [],
}

export default function chatBans(state = initialState, action: GetChatBannedPlayersActions | ChatBanUnbanActions): ChatBansState {
	switch (action.type) {
		case REDUX_CHAT_BANS_TYPES.GET_CHAT_BANS_START:
			return { ...state, isLoading: true }
		case REDUX_CHAT_BANS_TYPES.GET_CHAT_BANS_FAILURE:
			return { ...state, isLoading: false, errorOnGet: action.error }
		case REDUX_CHAT_BANS_TYPES.GET_CHAT_BANS_SUCCESS: {
			const { payload } = action
			return { ...state, isLoading: false, errorOnGet: null, banned: payload }
		}

		case REDUX_CHAT_BANS_TYPES.CHAT_BAN_PLAYER_START: {
			const playFabId = action.payload
			return { ...state, tryingToBan: [...state.tryingToBan, playFabId] }
		}
		case REDUX_CHAT_BANS_TYPES.CHAT_BAN_PLAYER_FAILURE: {
			const { error: errorOnBan, playFabId } = action.payload
			return { ...state, errorOnBan, tryingToBan: state.tryingToBan.filter((statePlayFabId) => statePlayFabId !== playFabId) }
		}
		case REDUX_CHAT_BANS_TYPES.CHAT_BAN_PLAYER_SUCCESS: {
			const bannedPlayer = action.payload
			return {
				...state,
				errorOnBan: null,
				banned: [...state.banned, bannedPlayer],
				tryingToBan: state.tryingToBan.filter((playFabId) => playFabId !== bannedPlayer.playFabId),
			}
		}

		case REDUX_CHAT_BANS_TYPES.CHAT_UNBAN_PLAYER_START: {
			const playFabId = action.payload
			return {
				...state,
				banned: state.banned.map((bannedPlayer) =>
					bannedPlayer.playFabId === playFabId ? { ...bannedPlayer, tryingToUnban: true } : bannedPlayer,
				),
			}
		}
		case REDUX_CHAT_BANS_TYPES.CHAT_UNBAN_PLAYER_FAILURE: {
			const { error: errorOnUnban, playFabId } = action.payload
			return {
				...state,
				errorOnUnban,
				banned: state.banned.map((bannedPlayer) =>
					bannedPlayer.playFabId === playFabId ? { ...bannedPlayer, tryingToUnban: false } : bannedPlayer,
				),
			}
		}
		case REDUX_CHAT_BANS_TYPES.CHAT_UNBAN_PLAYER_SUCCESS: {
			const playFabId = action.payload
			return { ...state, errorOnUnban: null, banned: state.banned.filter((bannedPlayer) => bannedPlayer.playFabId !== playFabId) }
		}
		default:
			return state
	}
}
