import type { BeatGuestMessagesState } from '../../redux/dashboardInputs/interfaces/BeatGuestMessagesState'
import type { BeatGuestMessagesValidActionProps } from '../../redux/dashboardInputs/interfaces/BeatGuestMessagesValidActionProps'
import { isValueValid } from './isValueValid'

export const validateBeatGuestMessages = ({ yesFirst, yesSecond, noFirst, noSecond }: BeatGuestMessagesState): BeatGuestMessagesValidActionProps => {
	const isYesFirstValid = isValueValid(yesFirst)
	const isYesSecondValid = isValueValid(yesSecond)
	const isNoFirstValid = isValueValid(noFirst)
	const isNoSecondValid = isValueValid(noSecond)

	return {
		isYesFirstValid,
		isYesSecondValid,
		isNoFirstValid,
		isNoSecondValid,
	}
}
