type RefreshToken = (studioKey: string) => Promise<void>

export default function setupTokenRefresh(tokenExpirationTime: number, refreshToken: RefreshToken, studioKey: string): NodeJS.Timeout {
	const currentTime = new Date().getTime()
	const refreshTime = tokenExpirationTime * 1000 - currentTime - 10000
	const nextRefresh = Math.max(0, refreshTime)
	const interval = setInterval(() => {
		refreshToken(studioKey)
	}, nextRefresh)

	return interval
}
