import type { ForceUpdateVersionActions } from '../interfaces/ForceUpdateVersionActions'
import type { ForceUpdateVersionPropertyState } from '../interfaces/ForceUpdateVersionPropertyState'
import type { ForceUpdateVersionState } from '../interfaces/ForceUpdateVersionState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const versionInitialState: ForceUpdateVersionPropertyState = {
	value: '',
	isValid: true,
}

const initialState: ForceUpdateVersionState = {
	android: versionInitialState,
	iOS: versionInitialState,
}

export default function forceUpdateVersion(state = initialState, action: ForceUpdateVersionActions): ForceUpdateVersionState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.FORCE_UPDATE_VERSION_STATE_UPDATE: {
			const { android, iOS } = action.props
			return {
				...initialState,
				android: {
					...versionInitialState,
					value: android,
				},
				iOS: {
					...versionInitialState,
					value: iOS,
				},
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.FORCE_UPDATE_VERSION_STATE_VALID: {
			const { isAndroidValid, isIOSValid } = action.props
			return {
				...initialState,
				android: {
					value: state.android.value,
					isValid: isAndroidValid,
				},
				iOS: {
					value: state.iOS.value,
					isValid: isIOSValid,
				},
			}
		}
		default:
			return state
	}
}
