import type { Dispatch } from 'redux'
import type { StopShowToggleAction } from '../interfaces/actions/StopShowActions'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const stopShowToggle = (isAvailable: boolean): StopShowToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_TOGGLE, isAvailable }
}

export default function toggleStopShow(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(stopShowToggle(isAvailable))
	}
}
