import { jwtEndpoint } from '../../../config'
import { jwtUserName } from '../../../config/app'
import type { JWT } from '../../../redux/auth/interfaces/JWT'
import request from '../../../utils/request'

export const verifyJWTRequest = (studioKey: string, userName?: string): Promise<JWT> => {
	return request<JWT>(jwtEndpoint, {
		method: 'POST',
		headers: {
			Authorization: studioKey,
			'Content-type': 'application/json',
		},
		body: JSON.stringify({ name: userName ?? jwtUserName }),
	})
}
