import type { Dispatch } from 'redux'
import type { NewsArticles } from '../interfaces/NewsState'
import type { NewsStateUpdateAction } from '../interfaces/NewsStateUpdateAction'
import { REDUX_NEWS_TYPES } from '../types'

const newsStateUpdate = (props: NewsArticles): NewsStateUpdateAction => {
	return {
		type: REDUX_NEWS_TYPES.NEWS_STATE_UPDATE,
		props,
	}
}

export default function newsState(props: NewsArticles) {
	return (dispatch: Dispatch): void => {
		dispatch(newsStateUpdate(props))
	}
}
