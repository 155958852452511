export enum Alignment {
	Default,
	Top,
	Left,
	Right,
	Bottom,
	Center,
}

export enum TextColor {
	Default,
	Red,
	Blue,
	Green,
	Black,
}

export enum Template {
	Template1 = 1,
	Template2 = 2,
	Template3 = 3,
}

export enum TextFont {
	Default,
	ChunkFiveEx,
	TimesNewRoman,
	RusselSquareRegular,
	Pixellari,
}

export interface ArticleText {
	text: string
	alignment: Alignment
	font: TextFont
	color: TextColor
}

export interface ArticleImage {
	link: string
	alignment: Alignment
}

export interface Article {
	id: string
	name: string
	templateId: Template
	headers: ArticleText[]
	subHeaders: ArticleText[]
	footers: ArticleText[]
	messages: ArticleText[]
	images: ArticleImage[]
	isActive: boolean
	isCritical?: boolean
	createdAt: number
	updatedAt: number
}

export interface NewsArticlesConfig {
	isCritical: boolean
	currentNewsId: number
	order: string[]
}

export interface Articles {
	[key: string]: Article
}

export interface NewsArticles {
	articles: Articles
	config: NewsArticlesConfig
}

export interface NewsState {
	data: Partial<NewsArticles>
	isLoading: boolean
}
