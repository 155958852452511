import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled from 'styled-components'

export const ToggleContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 15px 0;
	> label {
		text-align: center;
	}
`

export const ToggleInputContainer = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
`

export const InputContainer = styled.label`
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	> input {
		display: none;
	}
`

interface InputProps extends DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	active_color?: string
}

export const Input = styled.input<InputProps>`
	&:checked + span {
		background-color: ${(props) => (props.active_color ? props.active_color : '#2193f3')};
	}
	&:disabled + span {
		background-color: #ccc;
		opacity: 0.4;
		cursor: not-allowed;
	}
	&:disabled:checked + span {
		background-color: #fff;
		opacity: 0.4;
		cursor: not-allowed;
	}
	&:focus + span {
		box-shadow: 0 0 1px ${(props) => (props.active_color ? props.active_color : '#2193f3')};
	}
	&:checked + span:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}
`

export const Slider = styled.span`
	position: absolute;
	cursor: pointer;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.2s;
	transition: 0.2s;
	border-radius: 34px;
	&:before {
		position: relative;
		border-radius: 50%;
		content: '';
		height: 26px;
		width: 26px;
		left: 4px;
		background-color: #fff;
		-webkit-transition: 0.2s;
		transition: 0.2s;
	}
`

export const Label = styled.span`
	color: #777;
	font-size: 15px;
	font-family: helvetica;
	padding: 0 10px;
`
