import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import resetChallenges from '../../../redux/studioCommand/actions/resetChallenges'
import toggleResetChallenges from '../../../redux/studioCommand/actions/toggleResetChallenges'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'
import { useNextShowTimeValidation } from '../validations/useNextShowTimeValidation'

type UseResetChallenges = [PromiseVoid, ToggleCommand]

export default function useResetChallenges(): UseResetChallenges {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const { isAvailable } = useAppSelector(
		({
			wsMessage: {
				resetChallenges: { isAvailable },
			},
		}: StateTree) => ({ isAvailable }),
	)
	const toggleResetChallengesButton = useCallback((isAvailable: boolean) => toggleResetChallenges(isAvailable)(dispatch), [dispatch])
	const sendResetChallenges = useCallback(() => resetChallenges()(dispatch, getState), [dispatch, getState])
	const validate = useNextShowTimeValidation()

	useEffect(() => {
		toggleResetChallengesButton(isAvailable)
	}, [isAvailable, toggleResetChallengesButton])

	const sendResetChallengesWithValidation = async (): Promise<void> => {
		const isValid = validate()

		if (!isValid) {
			console.error('Invalid NextShowTime value!')
		}
		await sendResetChallenges()
	}

	return [sendResetChallengesWithValidation, toggleResetChallengesButton]
}
