import type { Dispatch } from 'redux'
import type { ForceUpdateStateValidAction } from '../interfaces/ForceUpdateStateValidAction'
import type { ForceUpdateVersionStateAction } from '../interfaces/ForceUpdateVersionStateAction'
import type { ForceUpdateVersionStateProps } from '../interfaces/ForceUpdateVersionStateProps'
import type { ForceUpdateVersionStateValidProps } from '../interfaces/ForceUpdateVersionStateValidProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const forceUpdateVersionState = (props: ForceUpdateVersionStateProps): ForceUpdateVersionStateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.FORCE_UPDATE_VERSION_STATE_UPDATE,
		props,
	}
}

export const forceUpdateVersionIsValid = (props: ForceUpdateVersionStateValidProps): ForceUpdateStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.FORCE_UPDATE_VERSION_STATE_VALID,
		props,
	}
}

export default function forceUpdateVersionStateChange(props: ForceUpdateVersionStateProps) {
	return (dispatch: Dispatch): void => {
		dispatch(forceUpdateVersionState(props))
	}
}
