import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import updateGuestInfo, { updateGuestInfoFailure, updateGuestInfoStart } from '../../../redux/studioCommand/actions/updateGuestInfo'
import type { UpdateGuestInfoState } from '../../../redux/studioCommand/interfaces/reducers/UpdateGuestInfoState'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid } from '../useCommands'
import { useGuestInfoValidation } from '../validations/useGuestInfoValidation'

type UseGuestInfoUpdateCommand = [UpdateGuestInfoState, PromiseVoid]

export const useGuestInfoUpdateCommand = (): UseGuestInfoUpdateCommand => {
	const { updateGuestInfoCommandState } = useAppSelector(({ commands: { updateGuestInfo: updateGuestInfoCommandState } }: StateTree) => ({
		updateGuestInfoCommandState,
	}))
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const sendUpdateGuestInfoCommand = useCallback(() => updateGuestInfo()(dispatch, getState), [dispatch, getState])
	const validate = useGuestInfoValidation()

	const sendUpdateWithValidation = async (): Promise<void> => {
		dispatch(updateGuestInfoStart())

		const isValid = await validate()

		if (isValid) {
			sendUpdateGuestInfoCommand()
		} else {
			console.error('invalid guestInfo values')
			dispatch(updateGuestInfoFailure())
		}
	}

	return [updateGuestInfoCommandState, sendUpdateWithValidation]
}
