import type { SeasonTransitionToggleActions } from '../interfaces/actions/SeasonTransitionToggleActions'
import type { SeasonTransitionToggleState } from '../interfaces/reducers/SeasonTransitionToggleState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: SeasonTransitionToggleState = {
	seasonTransitionStatus: false,
	isAvailable: true,
}

export default function seasonTransitionToggle(state = initialState, action: SeasonTransitionToggleActions): SeasonTransitionToggleState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.SEASON_TRANSITION_TOGGLE:
			return { ...state, seasonTransitionStatus: action.payload }
		case REDUX_STUDIO_COMMAND_TYPES.SEASON_TRANSITION_DISABLED_TOGGLE:
			return { ...state, isAvailable: action.payload }
		default:
			return state
	}
}
