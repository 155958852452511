import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import type { NextShowTimeState } from '../redux/dashboardInputs/interfaces/NextShowTimeActions'
import { getSelectedRegionTimeZone } from './getSelectedRegionTimeZone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getDateTimeTimestamp = (nextShowDateTime: NextShowTimeState): number => {
	const {
		date: { value: date },
		time: { value: time },
	} = nextShowDateTime

	return dayjs.tz(`${date} ${time}`, getSelectedRegionTimeZone()).valueOf()
}
