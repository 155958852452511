import type { SeasonWinnerState, SeasonWinnerUpdateAction } from '../interfaces/SeasonWinnerState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const initialState: SeasonWinnerState = {
	userId: '',
}

export default function seasonWinnerState(state = initialState, action: SeasonWinnerUpdateAction): SeasonWinnerState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.SEASON_WINNER_STATE_UPDATE:
			return { userId: action.userId }
		default:
			return state
	}
}
