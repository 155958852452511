import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React, { Fragment, useEffect, useState } from 'react'
import { tooltipId } from '../../config/const'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import type { KeyHash } from '../../interfaces/KeyHash'
import { sendCommandToStudio } from '../../services/api/serverless'

function MaintenanceWhitelist(): FunctionComponentElement<typeof CardWrapper> {
	const [whitelist, setWhitelist] = useState('')
	const [isUpdating, setIsUpdating] = useState<boolean>(false)

	const onWhitelistChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		setWhitelist(value)
	}

	const confirmUpdate = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This action will overwrite list of whitelist players affected during a Maintenance. Are you sure you want to proceed?'

		return confirmDialog(confirmMessage)
	}

	const onUpdateClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()

		if (!confirmUpdate()) {
			return
		}

		setIsUpdating(true)

		await updateMaintenanceWhitelist(whitelist.trim().replace(' ', ''))

		setIsUpdating(false)
	}

	useEffect(() => {
		;(async () => {
			const currentWhitelist = await getCurrentWhitelist()
			setWhitelist(currentWhitelist)
		})()
	}, [])

	return (
		<Fragment>
			<CardWrapper>
				<CardHeader>
					<CardHeading>Maintenance Whitelist</CardHeading>
				</CardHeader>
				<CardBody>
					<CardFieldSet>
						<label>Whitelist</label>
						<CardInput
							type="text"
							value={whitelist}
							isValid={true}
							onChange={onWhitelistChange}
							data-tooltip-content="Whitelist format: PlayFabId1,PlayFabId2,PlayFabId3"
							data-tooltip-id={tooltipId}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<CardButton type="button" disabled={isUpdating} onClick={onUpdateClick}>
							Update
						</CardButton>
					</CardFieldSet>
				</CardBody>
			</CardWrapper>
		</Fragment>
	)
}

async function getCurrentWhitelist(): Promise<string> {
	try {
		return await sendCommandToStudio<KeyHash>({ command: 'getKeys', keyIds: ['whitelist'], keyType: 'maintenance' }).then(
			({ whitelist }) => whitelist?.value || '',
		)
	} catch {
		console.error('Error retrieving current whitelisted players')
		return ''
	}
}

async function updateMaintenanceWhitelist(playFabIds: string): Promise<void> {
	try {
		await sendCommandToStudio({ command: 'setMaintenanceWhitelistedPlayers', playFabIds })

		console.info('Whitelist - updated successfully!')
	} catch {
		console.error('Error updating whitelist')
	}
}

export default MaintenanceWhitelist
