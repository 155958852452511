import type { Dispatch } from 'redux'
import type { PrepareCelebrationToggleAction } from '../interfaces/actions/PrepareCelebrationToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const prepareCelebrationToggle = (isAvailable: boolean): PrepareCelebrationToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_TOGGLE, isAvailable }
}

export default function togglePrepareCelebration(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(prepareCelebrationToggle(isAvailable))
	}
}
