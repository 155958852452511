import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { CardBody } from '../../containers/Card'
import type { ArticleImage, ArticleText } from '../../redux/news/interfaces/NewsState'
import ArticleInputTypeEdit from '../ArticleInputTypeEdit'
import Toggle from '../Toggle'

interface Props {
	headers: ArticleText[]
	subHeaders: ArticleText[]
	messages: ArticleText[]
	footers: ArticleText[]
	images: ArticleImage[]
	isCritical?: boolean | undefined
	isActive: boolean
	id: string
	onChangeArticleHeader: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeArticleSubHeader: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeArticleMessage: (index: number) => (event: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => void
	onChangeArticleFooter: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeArticleImage: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onAddField: (fieldType: 'headers' | 'subHeaders' | 'messages' | 'footers' | 'images') => () => void
	onChangeIsCritical: () => void
}

function NewsArticleEdit({
	headers,
	subHeaders,
	messages,
	footers,
	images,
	id,
	isCritical,
	isActive,
	onChangeIsCritical,
	onChangeArticleHeader,
	onChangeArticleSubHeader,
	onChangeArticleMessage,
	onChangeArticleFooter,
	onChangeArticleImage,
	onAddField,
}: Props): FunctionComponentElement<typeof HTMLDivElement> {
	return (
		<div>
			<CardBody>
				{headers.map((header, index) => (
					<ArticleInputTypeEdit
						key={`${id}-${index}-header-group`}
						onChangeInputTypeEdit={onChangeArticleHeader(index)}
						elementName="header"
						index={index}
						textItem={header}
						id={id}
						onAddField={onAddField('headers')}
					/>
				))}
				{subHeaders.map((subHeader, index) => (
					<ArticleInputTypeEdit
						key={`${id}-${index}-subHeader-group`}
						onChangeInputTypeEdit={onChangeArticleSubHeader(index)}
						elementName="subHeader"
						index={index}
						textItem={subHeader}
						id={id}
						onAddField={onAddField('subHeaders')}
					/>
				))}
				{messages.map((message, index) => (
					<ArticleInputTypeEdit
						key={`${id}-${index}-message-group`}
						onChangeInputTypeEdit={onChangeArticleMessage(index)}
						elementName="message"
						index={index}
						textItem={message}
						id={id}
						onAddField={onAddField('messages')}
					/>
				))}
				{footers.map((footer, index) => (
					<ArticleInputTypeEdit
						key={`${id}-${index}-footer-group`}
						onChangeInputTypeEdit={onChangeArticleFooter(index)}
						elementName="footer"
						index={index}
						textItem={footer}
						id={id}
						onAddField={onAddField('footers')}
					/>
				))}
				{images.map((image, index) => (
					<ArticleInputTypeEdit
						key={`${id}-${index}-image-group`}
						onChangeInputTypeEdit={onChangeArticleImage(index)}
						elementName="image"
						index={index}
						imageItem={image}
						id={id}
						onAddField={onAddField('images')}
					/>
				))}
				{isActive && <Toggle onChange={onChangeIsCritical} status={isCritical === true} label="isCritical" />}
			</CardBody>
		</div>
	)
}

export default NewsArticleEdit
