import { useCallback } from 'react'
import semVerRegex from 'semver-regex'
import { forceUpdateVersionIsValid } from '../../../redux/dashboardInputs/actions/forceUpdateVersionState'
import type { ForceUpdateVersionStateValidProps } from '../../../redux/dashboardInputs/interfaces/ForceUpdateVersionStateValidProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

export const useForceUpdateVersionsValidation = (): ((validateAll?: boolean) => boolean) => {
	const { iOS, android } = useAppSelector(
		({
			dashboardInputs: {
				forceUpdateVersion: {
					iOS: { value: iOS },
					android: { value: android },
				},
			},
		}: StateTree) => ({ iOS, android }),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: ForceUpdateVersionStateValidProps) => {
			dispatch(forceUpdateVersionIsValid(props))
		},
		[dispatch],
	)

	const confirmSendUpdate = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = `Are you sure you want to force all users to use android version ${android} or higher, and ios version ${iOS} or higher?`

		return confirmDialog(confirmMessage)
	}

	return (all = false): boolean => {
		const isAndroidValid = semVerRegex().test(android)
		const isIOSValid = semVerRegex().test(iOS)

		if (isAndroidValid && isIOSValid) {
			return all || confirmSendUpdate()
		}

		triggerDispatchValidation({ isAndroidValid, isIOSValid })

		return false
	}
}
