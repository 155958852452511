import type { Dispatch } from 'redux'
import type { VideoStateUpdateAction } from '../interfaces/VideoStateUpdateAction'
import type { VideoStateUpdateActionProps } from '../interfaces/VideoStateUpdateActionProps'
import type { VideoStateValidAction } from '../interfaces/VideoStateValidAction'
import type { VideoStateValidActionProps } from '../interfaces/VideoStateValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const videoStateUpdate = (props: VideoStateUpdateActionProps): VideoStateUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.VIDEO_STATE_UPDATE,
		props,
	}
}

export const videoStateValid = (props: VideoStateValidActionProps): VideoStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.VIDEO_STATE_VALID,
		props,
	}
}

export default function videoState(props: VideoStateUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		const { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore } = props

		dispatch(videoStateUpdate({ highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore }))
	}
}
