import { useCallback, useEffect, useState } from 'react'
import type { StateTree } from '../../redux/interfaces'
import fetchNews from '../../redux/news/actions/fetchNews'
import updateNewsState from '../../redux/news/actions/updateNewsState'
import type { NewsArticles, NewsState } from '../../redux/news/interfaces/NewsState'
import { initialNewsState } from '../../redux/news/reducers/newsState'
import { useAppDispatch, useAppSelector } from '.'

type UseNewsState = [NewsState, (props: NewsArticles) => void]

export const useNewsState = (): UseNewsState => {
	const [newsState, setNewsState] = useState<NewsState>(initialNewsState)
	const news = useAppSelector(({ news }: StateTree) => news)
	const dispatch = useAppDispatch()
	const updateNewsStateValues = useCallback((props: NewsArticles) => updateNewsState(props)(dispatch), [dispatch])
	const requestFetchNews = useCallback(() => fetchNews()(dispatch), [dispatch])

	useEffect(() => {
		requestFetchNews()
	}, [requestFetchNews])

	useEffect(() => {
		if (Object.keys(news.data).length > 0) {
			setNewsState(news)
		}
	}, [news])

	return [newsState, updateNewsStateValues]
}
