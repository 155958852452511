import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

export const getMonth = (date: Date | dayjs.Dayjs = new Date()): string => {
	return dayjs(date).format('YYYY-MM')
}
