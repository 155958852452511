import { Country } from '../../../config/interfaces/Country'
import type { SelectCountryActions } from '../interfaces/actions/SelectCountry/SelectCountryActions'
import type { SelectCountryState } from '../interfaces/reducers/SelectCountryState'
import { REDUX_AUTH_TYPES } from '../types'

const initialState: SelectCountryState = {
	value: Country.NO,
	isValid: true,
}

export default function selectCountry(state = initialState, action: SelectCountryActions): SelectCountryState {
	switch (action.type) {
		case REDUX_AUTH_TYPES.SELECT_COUNTRY_UPDATE:
			return { ...state, value: action.props.value }
		case REDUX_AUTH_TYPES.SELECT_COUNTRY_VALID:
			return { ...state, isValid: action.props.isValid }
		default:
			return state
	}
}
