import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import { leaguesResetIsActiveToggleAction, leaguesResetToggleAction } from '../../../redux/studioCommand/actions/leaguesResetToggle'
import type { LeaguesResetToggleState } from '../../../redux/studioCommand/interfaces/reducers/LeaguesResetToggleState'
import { useAppDispatch, useAppSelector } from '..'

type UseLeaguesResetToggle = [LeaguesResetToggleState, (leaguesResetStatus?: boolean) => void, (isAvailable: boolean) => void]

export const useLeaguesResetToggle = (): UseLeaguesResetToggle => {
	const dispatch = useAppDispatch()
	const leaguesReset = useAppSelector(({ commands: { leaguesReset } }: StateTree) => leaguesReset)
	const toggleLeaguesReset = useCallback(
		(leaguesResetStatus?: boolean) => {
			dispatch(leaguesResetToggleAction(leaguesResetStatus ?? !leaguesReset.leaguesResetStatus))
		},
		[dispatch, leaguesReset],
	)
	const toggleLeaguesResetAvailability = useCallback(
		(isAvailable: boolean) => {
			dispatch(leaguesResetIsActiveToggleAction(isAvailable))
		},
		[dispatch],
	)

	const sendToggleLeaguesReset = (leaguesResetStatus?: boolean): void => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This setting should ONLY be used when the leagues need to be reset during the show. Are you sure you want to proceed?'

		if (leaguesReset.leaguesResetStatus || confirmDialog(confirmMessage)) {
			toggleLeaguesReset(leaguesResetStatus)
		}
	}

	return [leaguesReset, sendToggleLeaguesReset, toggleLeaguesResetAvailability]
}
