import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import stopShow from '../../../redux/studioCommand/actions/stopShow'
import toggleStopShow from '../../../redux/studioCommand/actions/toggleStopShow'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UseStopShow = [PromiseVoid, ToggleCommand]

export default function useStopShow(): UseStopShow {
	const dispatch = useAppDispatch()
	const { isAvailable } = useAppSelector(
		({
			wsMessage: {
				stopShow: { isAvailable },
			},
		}: StateTree) => ({ isAvailable }),
	)
	const toggleStopShowButton = useCallback((isAvailable: boolean) => toggleStopShow(isAvailable)(dispatch), [dispatch])
	const sendStopShow = useCallback(() => stopShow()(dispatch), [dispatch])

	useEffect(() => {
		toggleStopShowButton(isAvailable)
	}, [isAvailable, toggleStopShowButton])

	return [sendStopShow, toggleStopShowButton]
}
