export enum REDUX_CHAT_BANS_TYPES {
	GET_CHAT_BANS_START = 'GET_CHAT_BANS_START',
	GET_CHAT_BANS_FAILURE = 'GET_CHAT_BANS_FAILURE',
	GET_CHAT_BANS_SUCCESS = 'GET_CHAT_BANS_SUCCESS',

	CHAT_BAN_PLAYER_START = 'CHAT_BAN_PLAYER_START',
	CHAT_BAN_PLAYER_FAILURE = 'CHAT_BAN_PLAYER_FAILURE',
	CHAT_BAN_PLAYER_SUCCESS = 'CHAT_BAN_PLAYER_SUCCESS',

	CHAT_UNBAN_PLAYER_START = 'CHAT_UNBAN_PLAYER_START',
	CHAT_UNBAN_PLAYER_FAILURE = 'CHAT_UNBAN_PLAYER_FAILURE',
	CHAT_UNBAN_PLAYER_SUCCESS = 'CHAT_UNBAN_PLAYER_SUCCESS',
}
