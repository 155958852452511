import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { ChatUnbanPlayer } from '../../studioCommand/interfaces/ChatUnbanPlayer'
import type { ChatUnbanPlayerFailureAction, ChatUnbanPlayerStartAction, ChatUnbanPlayerSuccessAction } from '../interfaces/ChatBanActions'
import { REDUX_CHAT_BANS_TYPES } from '../types'

const chatUnbanPlayerStartAction = (payload: string): ChatUnbanPlayerStartAction => {
	return { type: REDUX_CHAT_BANS_TYPES.CHAT_UNBAN_PLAYER_START, payload }
}

const chatUnbanPlayerFailureAction = (playFabId: string, error: string): ChatUnbanPlayerFailureAction => {
	return { type: REDUX_CHAT_BANS_TYPES.CHAT_UNBAN_PLAYER_FAILURE, payload: { playFabId, error } }
}

const chatUnbanPlayerSuccessAction = (payload: string): ChatUnbanPlayerSuccessAction => {
	return { type: REDUX_CHAT_BANS_TYPES.CHAT_UNBAN_PLAYER_SUCCESS, payload }
}

export const chatUnbanPlayer =
	(userId: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		const chatUnbanPlayerCommand: ChatUnbanPlayer = {
			command: 'chatUnbanPlayer',
			userId,
		}

		dispatch(chatUnbanPlayerStartAction(userId))

		try {
			await sendCommandToStudio(chatUnbanPlayerCommand)
			dispatch(chatUnbanPlayerSuccessAction(userId))
		} catch (error) {
			dispatch(chatUnbanPlayerFailureAction(userId, `Failed to unban user ${userId}: ${error}`))
		}
	}
