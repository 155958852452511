import type { FunctionComponentElement } from 'react'
import React, { useEffect } from 'react'
import { Game } from '../../config/enums'
import { CardGroup, CardGroupDelimiter } from '../../containers/Card'
import { useDefaultValues } from '../../utils/hooks/commands/useDefaultValues'
import { useSeasonTransitionToggle } from '../../utils/hooks/commands/useSeasonTransitionToggle'
import AnalyticsCuePoints from '../AnalyticsCuePoints'
import BaseGameSettings from '../BaseGameSettings'
import BeatGuestMessages from '../BeatGuestMessages'
import BlockbusterSettings from '../BlockbusterSettings'
import ChatBans from '../ChatBans'
import DashboardHeader from '../DashboardHeader'
import EmployeeMarker from '../EmployeeMarker'
import FeaturedGame from '../FeaturedGame'
import ForceUpdateVersion from '../ForceUpdateVersion'
import GuestInfo from '../GuestInfo'
import MaintenanceMode from '../MaintenanceMode'
import MaintenanceWhitelist from '../MaintenanceWhitelist'
import News from '../News'
import NextDailyGoals from '../NextDailyGoals'
import NextMonthlyGoals from '../NextMonthlyGoals'
import NextShowTime from '../NextShowTime'
import NextWeeklyGoals from '../NextWeeklyGoals'
import Prizes from '../Prizes'
import ReferralCode from '../ReferralCode'
import SeasonWinner from '../SeasonWinner'
import TimestampsAutomation from '../TimestampsAutomation'
import Video from '../Video'
import { DashboardContainer } from './DashboardContainer'

function Dashboard(): FunctionComponentElement<typeof DashboardContainer> {
	const [, replaceValuesWithDefaults] = useDefaultValues()
	const [seasonTransition] = useSeasonTransitionToggle()

	useEffect(() => {
		replaceValuesWithDefaults()
	}, [replaceValuesWithDefaults])

	return (
		<DashboardContainer>
			<DashboardHeader />
			<CardGroupDelimiter />
			<CardGroup>
				<Video />
				<CardGroup flexDirection={'column'}>
					<TimestampsAutomation />
					<AnalyticsCuePoints />
				</CardGroup>
				<BeatGuestMessages />
				<GuestInfo />
			</CardGroup>
			<CardGroupDelimiter />
			<CardGroup>
				<BlockbusterSettings />
				<BaseGameSettings gameName={Game.Centipede} />
				<BaseGameSettings gameName={Game.Beat} />
				<BaseGameSettings gameName={Game.Rally} />
				<BaseGameSettings gameName={Game.Breakout} />
				<FeaturedGame />
			</CardGroup>
			<CardGroupDelimiter />
			<CardGroup>
				<NextDailyGoals />
				<NextWeeklyGoals />
				<NextMonthlyGoals />
			</CardGroup>
			<CardGroupDelimiter />
			<CardGroup>
				<NextShowTime />
				<Prizes />
				{seasonTransition.seasonTransitionStatus && <SeasonWinner />}
			</CardGroup>
			<CardGroupDelimiter />
			<ChatBans />
			<CardGroupDelimiter />
			<News />
			<CardGroupDelimiter />
			<CardGroup>
				<ForceUpdateVersion />
				<MaintenanceMode />
				<MaintenanceWhitelist />
				<EmployeeMarker />
				<ReferralCode />
			</CardGroup>
		</DashboardContainer>
	)
}

export default Dashboard
