import { useCallback } from 'react'
import nextWeeklyGoalsStateUpdate, { nextWeeklyGoalsReset } from '../../../redux/dashboardInputs/actions/nextWeeklyGoalsState'
import type { NextWeeklyGoalsState } from '../../../redux/dashboardInputs/interfaces/goals/weekly/NextWeeklyGoalsState'
import type { NextWeeklyGoalsUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/goals/weekly/NextWeeklyGoalsUpdateActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseNextWeeklyGoalsState = [NextWeeklyGoalsState, (props: NextWeeklyGoalsUpdateActionProps) => void, () => void]

export const useNextWeeklyGoalsState = (): UseNextWeeklyGoalsState => {
	const { nextWeeklyGoals } = useAppSelector(({ dashboardInputs: { nextWeeklyGoals } }: StateTree) => ({ nextWeeklyGoals }))
	const dispatch = useAppDispatch()
	const updateNextWeeklyGoalsState = useCallback(
		(props: NextWeeklyGoalsUpdateActionProps) => nextWeeklyGoalsStateUpdate(props)(dispatch),
		[dispatch],
	)

	const toggleWeeklyReset = () => {
		dispatch(nextWeeklyGoalsReset())
	}

	return [nextWeeklyGoals, updateNextWeeklyGoalsState, toggleWeeklyReset]
}
