import { useCallback, useEffect, useState } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import { getStepFunctionExecutionId } from '../../../redux/showAutomation/actions/getStepFunctionExecutionId'
import executeShow from '../../../redux/studioCommand/actions/executeShow'
import { useAppDispatch, useAppSelector } from '..'
import { useCommands } from '../useCommands'

export type UseExecuteShow = [boolean, () => Promise<void>]

export const useExecuteShow = (): UseExecuteShow => {
	const dispatch = useAppDispatch()
	const [isAutomationRunning, setIsAutomationRunning] = useState(false)
	const getStepFunctionExecution = useCallback(() => getStepFunctionExecutionId()(dispatch), [dispatch])
	const { executeShowStatus, executionShowInfo } = useAppSelector(({ wsMessage: { executeShowStatus }, executionShowInfo }: StateTree) => ({
		executeShowStatus,
		executionShowInfo,
	}))
	const sendExecuteShow = useCallback((signal: boolean) => executeShow(signal)(dispatch), [dispatch])
	const { toggleCommands } = useCommands()

	useEffect(() => {
		getStepFunctionExecution()
	}, [getStepFunctionExecution])

	useEffect(() => {
		if (executionShowInfo.isLoading) return

		setIsAutomationRunning(executionShowInfo.status)
	}, [executionShowInfo])

	useEffect(() => {
		setIsAutomationRunning(executeShowStatus)
	}, [executeShowStatus])

	const executeShowConfirmation = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = !isAutomationRunning
			? 'This will run the show automation for the latest saved parameters'
			: 'This will stop the show automation'

		return confirmDialog(confirmMessage)
	}

	const confirmExecuteShow = async (): Promise<void> => {
		if (executeShowConfirmation()) {
			if (!isAutomationRunning) {
				await sendExecuteShow(true)
				return
			}
			await sendExecuteShow(false)
			return toggleCommands(true)
		}
	}

	return [isAutomationRunning, confirmExecuteShow]
}
