import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { getSelectedRegionShowHour } from './getSelectedRegionShowHour'
import { getSelectedRegionTimeZone } from './getSelectedRegionTimeZone'

dayjs.extend(utc)
dayjs.extend(timezone)

// TODO: add unit tests for this implementation
export const getNextShowTime = (date?: string, isNextResetDateTime = false): dayjs.Dayjs => {
	const localShowHour = getSelectedRegionShowHour()
	const timezone = getSelectedRegionTimeZone()
	const localizedDate = date ? dayjs.tz(date, timezone) : dayjs().tz(timezone)
	const hour = localizedDate.hour()
	const minute = localizedDate.minute()
	let showTime = localizedDate
	if (isNextResetDateTime || hour > localShowHour || (hour === localShowHour && minute > 0)) {
		showTime = showTime.add(1, 'day')
	}
	return showTime.hour(localShowHour).startOf('hour')
}
