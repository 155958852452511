import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { delay } from '../../../utils/delay/delay'
import type { StateTree } from '../../interfaces'
import type { StartCountdownAndShowFailureAction } from '../interfaces/actions/StartCountdownAndShowFailureAction'
import type { StartCountdownAndShowStartAction } from '../interfaces/actions/StartCountdownAndShowStartAction'
import type { StartCountdownAndShowSuccessAction } from '../interfaces/actions/StartCountdownAndShowSuccessAction'
import type { StartCountdown } from '../interfaces/StartCountdown'
import type { StartShow } from '../interfaces/StartShow'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const startCountdownAndShowStart = (): StartCountdownAndShowStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_START }
}

export const startCountdownAndShowFailure = (): StartCountdownAndShowFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_FAILURE }
}

const startCountdownAndShowSuccess = (): StartCountdownAndShowSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_SUCCESS }
}

export default function startCountdownAndShow() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(startCountdownAndShowStart())

		try {
			const {
				dashboardInputs: {
					video: {
						highQualityVideoUrl: { value: highQualityVideoUrl },
						videoUrl: { value: videoUrl },
						lowQualityVideoUrl: { value: lowQualityVideoUrl },
						guestScore: { value: guestScore },
					},
					analyticsCuePointsState: { props: analyticsCuePoints },
				},
				commands: {
					showType: { showType },
				},
			} = getState()

			const countdownData: StartCountdown = {
				command: 'gameCountdown',
				highQualityVideoUrl,
				videoUrl,
				lowQualityVideoUrl,
				guestScore,
			}

			await sendCommandToStudio(countdownData)
			await delay(3000)

			const startShowData: StartShow = {
				command: 'startShow',
				highQualityVideoUrl,
				videoUrl,
				lowQualityVideoUrl,
				showType,
				analyticsCuePoints,
			}

			await sendCommandToStudio(startShowData)

			dispatch(startCountdownAndShowSuccess())
		} catch {
			dispatch(startCountdownAndShowFailure())
		}
	}
}
