import { useCallback } from 'react'
import { verifyJWT } from '../../redux/auth/actions/verifyJWT'
import { useAppDispatch, useAppStore } from '.'

export const useAuth = (): ((key: string) => Promise<void>) => {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const authenticate = useCallback((key: string) => verifyJWT(key)(dispatch, getState), [dispatch, getState])

	return authenticate
}
