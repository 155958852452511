import { useCallback } from 'react'
import type { StateTree } from '../../redux/interfaces'
import { sendChatMessage } from '../../redux/webSocket/actions/sendChatMessage'
import type { ChatMessage } from '../../redux/webSocket/interfaces/ChatMessage'
import { useAppDispatch, useAppSelector } from '.'

type SendWSMMessage = [ChatMessage[], (content: string) => void]

export const useSendWSMessage = (): SendWSMMessage => {
	const dispatch = useAppDispatch()
	const chatMessages = useAppSelector(({ wsMessage: { chatMessages } }: StateTree) => chatMessages)

	const sendMessage = useCallback((content: string) => sendChatMessage(content)(dispatch), [dispatch])

	return [chatMessages, sendMessage]
}
