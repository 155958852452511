import styled from 'styled-components'

export const MessagesContainer = styled.ul`
	list-style-type: none;
	padding: 0;
	overflow-wrap: break-word;
	display: flex;
	max-height: 300px;
	min-height: 100%;
	flex-wrap: wrap;
	overflow: auto;
	scrollbar-gutter: stable;
`

export const MessageItem = styled.li`
	padding-top: 10px;
	padding-bottom: 5px;
	display: inline-flex;
	width: 100%;
	border-bottom: 1px solid;
	padding-right: 20px;
`

export const MessageWrapper = styled.div<{ isRemoved?: boolean }>`
	flex: 1 0;
	padding: 0 15px;
	text-decoration-line: ${(props) => (props.isRemoved ? 'line-through' : 'none')};
`

export const MessageHeader = styled.div`
	display: flex;
`

export const UserName = styled.span`
	font-size: 10px;
	font-weight: bold;
`

export const MessageContent = styled.p`
	font-size: 12px;
	line-height: 20px;
	margin: 0;
`

export const MessageTime = styled.span`
	font-size: 10px;
	margin-left: auto;
`
export const ChatBanButton = styled.button`
	display: block;
	background-color: transparent;
	color: gray;
	margin: 0;

	&:hover {
		background-color: transparent;
	}

	&:disabled {
		color: red;
		cursor: not-allowed;
	}
`
