import { isURLValid } from './isURLValid'

export const isURLFileValid = (url: string): Promise<boolean> => {
	if (!isURLValid(url)) {
		return Promise.resolve(false)
	}

	return fetch(url, { method: 'GET', cache: 'no-cache' })
		.then(({ status }) => status === 200)
		.catch(() => false)
}
