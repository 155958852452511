import styled from 'styled-components'
import { commandsMenuWidth } from '../../config/styles/theme'

export const CommandsMenuContainer = styled.div`
	width: ${commandsMenuWidth}px;
	display: flex;
	flex-direction: column;
	padding-left: 10px;

	> button {
		padding: 10px;
		margin: 10px 0;
		box-shadow: 0 5px #999;
	}

	> button:hover {
		background-color: darkgrey;
	}

	> button:active {
		background-color: #3e8e41;
		box-shadow: 0 2px #666;
		transform: translateY(4px);
	}
`
