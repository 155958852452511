import { getDay } from '../../../utils/getDay'
import { getMonth } from '../../../utils/getMonth'
import type { ShowCalendarActions } from '../interfaces/actions/ShowCalendarActions'
import type { ShowCalendarState } from '../interfaces/reducers/ShowCalendarState'
import { REDUX_SHOW_CALENDAR_TYPES } from '../types'

export const initialState: ShowCalendarState = {
	isLoading: false,
	selectedDate: getDay(),
	viewedMonth: getMonth(),
	currentMonthSavedShows: [],
}

export default function showCalendar(state = initialState, action: ShowCalendarActions): ShowCalendarState {
	switch (action.type) {
		case REDUX_SHOW_CALENDAR_TYPES.LIST_SAVED_SHOW_DAYS_START:
			return { ...state, isLoading: true }
		case REDUX_SHOW_CALENDAR_TYPES.LIST_SAVED_SHOW_DAYS_FAILURE:
			console.error('LIST_SAVED_SHOW_DAYS_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_SHOW_CALENDAR_TYPES.LIST_SAVED_SHOW_DAYS_SUCCESS: {
			const { payload: currentMonthSavedShows } = action

			return { ...state, isLoading: false, currentMonthSavedShows }
		}
		case REDUX_SHOW_CALENDAR_TYPES.SHOW_CALENDAR_STATE_UPDATE: {
			const { selectedDate = state.selectedDate, viewedMonth = state.viewedMonth, newSavedShow } = action.props
			let { currentMonthSavedShows } = state
			if (newSavedShow) {
				currentMonthSavedShows = [...currentMonthSavedShows, newSavedShow]
			}
			return { ...state, currentMonthSavedShows, selectedDate, viewedMonth }
		}
		default:
			return state
	}
}
