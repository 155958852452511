import { useEffect, useState } from 'react'
import type { StateTree } from '../../redux/interfaces'
import setupTokenRefresh from '../tokenRefresh/setupTokenRefresh'
import { useAppSelector } from '.'
import { useAuth } from './useAuth'

type UseToken = string | undefined

export const useToken = (): UseToken => {
	const [jwtToken, setJWTToken] = useState<string | undefined>()
	const { isLoading, jwt, tokenExpirationTime, studioKey } = useAppSelector(({ auth }: StateTree) => auth)
	const refreshToken = useAuth()

	useEffect(() => {
		if (isLoading || jwt === null) {
			return
		}

		setJWTToken(jwt)
	}, [jwt, isLoading])

	useEffect(() => {
		if (studioKey === null) {
			return
		}

		if (tokenExpirationTime === null || jwt === null) {
			return (): void => {
				refreshToken(studioKey)
			}
		}

		const interval = setupTokenRefresh(tokenExpirationTime, refreshToken, studioKey)

		return (): void => clearInterval(interval)
	}, [jwt, tokenExpirationTime, refreshToken, studioKey])

	return jwtToken
}
