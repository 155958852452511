import { useCallback } from 'react'
import type { TimeFrame } from '../../../interfaces/TimeFrame'
import type { StateTree } from '../../../redux/interfaces'
import overwriteNextGoals from '../../../redux/studioCommand/actions/overwriteNextGoals'
import type { OverwriteNextGoalsState } from '../../../redux/studioCommand/interfaces/reducers/OverwriteNextGoalsState'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid } from '../useCommands'

type UseNextGoalsOverwriteCommand = [OverwriteNextGoalsState, PromiseVoid]

export const useNextGoalsOverwriteCommand = (timeFrame: TimeFrame): UseNextGoalsOverwriteCommand => {
	const { overwriteNextGoalsState } = useAppSelector(({ commands: { overwriteNextGoals: overwriteNextGoalsState } }: StateTree) => ({
		overwriteNextGoalsState,
	}))
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const sendOverwriteDailyGoalsCommand = useCallback(() => overwriteNextGoals(timeFrame)(dispatch, getState), [dispatch, getState, timeFrame])

	const confirmSendOverwrite = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = `This will overwrite the current ${timeFrame} Goals on the ongoing 24H Tournament. Are you sure you want to proceed?`

		return confirmDialog(confirmMessage)
	}

	const sendOverwriteWithValidation = async (): Promise<void> => {
		if (confirmSendOverwrite()) {
			await sendOverwriteDailyGoalsCommand()
		}
	}

	return [overwriteNextGoalsState, sendOverwriteWithValidation]
}
