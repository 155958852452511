import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import prepareCelebration from '../../../redux/studioCommand/actions/prepareCelebration'
import togglePrepareCelebration from '../../../redux/studioCommand/actions/togglePrepareCelebration'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UsePrepareCelebration = [PromiseVoid, ToggleCommand]

export default function usePrepareCelebration(): UsePrepareCelebration {
	const dispatch = useAppDispatch()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				prepareCelebration: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const togglePrepareCelebrationButton = useCallback((isAvailable: boolean) => togglePrepareCelebration(isAvailable)(dispatch), [dispatch])
	const sendPrepareCelebration = useCallback(() => prepareCelebration()(dispatch), [dispatch])

	useEffect(() => {
		togglePrepareCelebrationButton(isAvailable)
	}, [isAvailable, togglePrepareCelebrationButton])

	return [sendPrepareCelebration, togglePrepareCelebrationButton]
}
