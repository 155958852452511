import type { ResetChallengesActions } from '../interfaces/actions/ResetChallengesActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function resetChallenges(state = initialState, action: ResetChallengesActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_FAILURE:
			console.error('RESET_CHALLENGES_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_SUCCESS:
			console.log('RESET_CHALLENGES_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
