import type { Dispatch } from 'redux'
import { verifyJWTRequest } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { VerifyJWTFailureAction } from '../interfaces/actions/verifyJWT/VerifyJWTFailureAction'
import type { VerifyJWTStartAction } from '../interfaces/actions/verifyJWT/VerifyJWTStartAction'
import type { VerifyJWTSuccessAction } from '../interfaces/actions/verifyJWT/VerifyJWTSuccessAction'
import { REDUX_AUTH_TYPES } from '../types'

const verifyJWTStart = (): VerifyJWTStartAction => {
	return { type: REDUX_AUTH_TYPES.VERIFY_JWT_START }
}

const verifyJWTFailure = (): VerifyJWTFailureAction => {
	return { type: REDUX_AUTH_TYPES.VERIFY_JWT_FAILURE }
}

const verifyJWTSuccess = (payload: string, studioKey: string): VerifyJWTSuccessAction => {
	return { type: REDUX_AUTH_TYPES.VERIFY_JWT_SUCCESS, payload, studioKey }
}

export const verifyJWT =
	(studioKey: string) =>
	async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(verifyJWTStart())

		try {
			const {
				auth: { studioKey: storedKey },
			} = getState()
			const key = storedKey ?? studioKey
			const { jwt } = await verifyJWTRequest(key)
			dispatch(verifyJWTSuccess(jwt, key))
		} catch {
			dispatch(verifyJWTFailure())
		}
	}
