import { useState } from 'react'
import { Game } from '../../../config/enums'
import type { StateTree } from '../../../redux/interfaces'
import type { OverwriteGameSettings } from '../../../redux/studioCommand/interfaces/OverwriteGameSettings'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { BaseParsedGameSettings, BlockbusterParsedGameSettings } from '../../getGameSettingsValues'
import { parseBaseGameSettingsValues, parseBlockbusterSettingsValues } from '../../getGameSettingsValues'
import { useAppSelector } from '..'
import type { PromiseVoid } from '../useCommands'

type UseOverwriteGameSettingsCommand = [boolean, PromiseVoid]

export const useOverwriteGameSettingsCommand = (gameName: Game): UseOverwriteGameSettingsCommand => {
	const { blockbusterSettings, centipedeSettings, beatSettings, rallySettings, breakoutSettings } = useAppSelector(
		({ dashboardInputs: { blockbusterSettings, centipedeSettings, beatSettings, rallySettings, breakoutSettings } }: StateTree) => ({
			blockbusterSettings,
			centipedeSettings,
			beatSettings,
			rallySettings,
			breakoutSettings,
		}),
	)

	const [isOverwriteGameCommandState, setIsOverwriteGameCommandState] = useState<boolean>(false)

	const sendOverwriteGameSettingsCommand = async () => {
		setIsOverwriteGameCommandState(true)
		try {
			let settings: BaseParsedGameSettings | BlockbusterParsedGameSettings

			switch (gameName) {
				case Game.Blockbuster:
					settings = parseBlockbusterSettingsValues(blockbusterSettings)
					break
				case Game.Centipede:
					settings = parseBaseGameSettingsValues(centipedeSettings, Game.Centipede)
					break
				case Game.Beat:
					settings = parseBaseGameSettingsValues(beatSettings, Game.Beat)
					break
				case Game.Rally:
					settings = parseBaseGameSettingsValues(rallySettings, Game.Rally)
					break
				case Game.Breakout:
					settings = parseBaseGameSettingsValues(breakoutSettings, Game.Breakout)
			}

			const command: OverwriteGameSettings = { command: 'overwriteGameSettings', ...settings, gameName }
			await sendCommandToStudio(command)

			console.info(`${gameName} Game Settings has been successfully overwritten!`)
		} catch {
			console.error(`Error when trying to overwrite ${gameName} Game Settings`)
		} finally {
			setIsOverwriteGameCommandState(false)
		}
	}

	const confirmSendOverwrite = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = `This will overwrite the ${gameName} Game Settings. Are you sure you want to proceed?`

		return confirmDialog(confirmMessage)
	}

	const sendOverwriteWithValidation = async (): Promise<void> => {
		if (confirmSendOverwrite()) {
			await sendOverwriteGameSettingsCommand()
		}
	}

	return [isOverwriteGameCommandState, sendOverwriteWithValidation]
}
