import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { timeFramedGoals } from '../../config/app'
import type { Goals } from '../../interfaces/Goals'
import type { TimeFrame } from '../../interfaces/TimeFrame'

const deprecatedGoalsIds = ['winner-tournament-champion', 'winner-tournament-top10']

interface Props {
	value?: Goals
	rootKey: string
	goalNumber: number
	timeFrame: TimeFrame
	onChange: (event: ChangeEvent<HTMLSelectElement>, goalNumber: number) => void
	leaguesEnabled?: boolean
}

function GoalSelect({
	value,
	rootKey,
	goalNumber,
	timeFrame,
	onChange,
	leaguesEnabled = false,
}: Props): FunctionComponentElement<typeof HTMLElement> {
	const timeFramedGoalsOptions = leaguesEnabled
		? timeFramedGoals[timeFrame].filter((item) => !deprecatedGoalsIds.includes(item.id))
		: timeFramedGoals[timeFrame]

	return (
		<select
			name={rootKey}
			id={rootKey}
			style={{ width: '83%' }}
			value={JSON.stringify(value?.[goalNumber])}
			onChange={(event) => {
				onChange(event, goalNumber)
			}}
		>
			{timeFramedGoalsOptions.map((goal, index) => (
				<option key={`${rootKey}${goalNumber}-${index}`} value={JSON.stringify(goal)}>
					{goal.description}
				</option>
			))}
		</select>
	)
}

export default GoalSelect
