import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import showTopThree from '../../../redux/studioCommand/actions/showTopThree'
import toggleShowTopThree from '../../../redux/studioCommand/actions/toggleShowTopThree'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UseShowTopThree = [PromiseVoid, ToggleCommand]

export default function useShowTopThree(): UseShowTopThree {
	const dispatch = useAppDispatch()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				topThreePlayers: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const toggleShowTopThreeButton = useCallback((isAvailable: boolean) => toggleShowTopThree(isAvailable)(dispatch), [dispatch])
	const sendShowTopThree = useCallback(() => showTopThree()(dispatch), [dispatch])

	useEffect(() => {
		toggleShowTopThreeButton(isAvailable)
	}, [isAvailable, toggleShowTopThreeButton])

	return [sendShowTopThree, toggleShowTopThreeButton]
}
