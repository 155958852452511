import { useCallback } from 'react'
import { isValidGuestInfoState } from '../../../redux/dashboardInputs/actions/guestInfoState'
import type { GuestInfoStateValidProps } from '../../../redux/dashboardInputs/interfaces/GuestInfoStateValidProps'
import type { StateTree } from '../../../redux/interfaces'
import { isURLFileValid } from '../../validation/isURLFileValid'
import { isURLValid } from '../../validation/isURLValid'
import { isValueValid } from '../../validation/isValueValid'
import { useAppDispatch, useAppSelector } from '..'

export const useGuestInfoValidation = (): (() => Promise<boolean>) => {
	const { guestDetails, guestDetailsPictureURL, guestExternalURL, guestRoomPosterPictureURL, guestTitle } = useAppSelector(
		({
			dashboardInputs: {
				guestInfo: { guestDetails, guestDetailsPictureURL, guestExternalURL, guestRoomPosterPictureURL, guestTitle },
			},
		}: StateTree) => ({ guestDetails, guestDetailsPictureURL, guestExternalURL, guestRoomPosterPictureURL, guestTitle }),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: GuestInfoStateValidProps) => {
			dispatch(isValidGuestInfoState(props))
		},
		[dispatch],
	)

	return async (): Promise<boolean> => {
		const promisesFetch = [isURLFileValid(guestDetailsPictureURL.value), isURLFileValid(guestRoomPosterPictureURL.value)]
		const [isGuestDetailsPictureUrlValid, isGuestRoomPosterPictureURLValid] = await Promise.all(promisesFetch)
		const isGuestExternalUrlValid = isURLValid(guestExternalURL.value)
		const isGuestTitleValid = isValueValid(guestTitle)
		const isGuestDetailsValid = isValueValid(guestDetails)

		if (
			isGuestDetailsPictureUrlValid &&
			isGuestDetailsValid &&
			isGuestRoomPosterPictureURLValid &&
			isGuestExternalUrlValid &&
			isGuestTitleValid
		) {
			return true
		}

		triggerDispatchValidation({
			isGuestRoomPosterPictureURLValid,
			isGuestDetailsPictureUrlValid,
			isGuestExternalUrlValid,
			isGuestDetailsValid,
			isGuestTitleValid,
		})

		return false
	}
}
