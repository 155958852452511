import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import forceUpdateVersion from '../../../redux/studioCommand/actions/forceUpdateVersion'
import type { ForceUpdateVersion } from '../../../redux/studioCommand/interfaces/ForceUpdateVersion'
import type { ForceUpdateVersionCommand } from '../../../redux/studioCommand/interfaces/reducers/ForceUpdateVersionCommand'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import { useForceUpdateVersionsValidation } from '../validations/useForceUpdateVersionsValidation'

type UseForceUpdateVersionCommand = [ForceUpdateVersionCommand, (forceUpdateVersion: ForceUpdateVersion) => Promise<void>]

export const useForceUpdateVersionCommand = (): UseForceUpdateVersionCommand => {
	const { forceUpdateVersionCommandState } = useAppSelector(({ commands: { forceUpdateVersion: forceUpdateVersionCommandState } }: StateTree) => ({
		forceUpdateVersionCommandState,
	}))
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const sendForceUpdateVersions = useCallback(() => forceUpdateVersion()(dispatch, getState), [dispatch, getState])
	const validate = useForceUpdateVersionsValidation()

	const sendForceUpdateVersionsWithValidation = async (): Promise<void> => {
		const isValid = validate()

		if (isValid) {
			await sendForceUpdateVersions()
		}
	}

	return [forceUpdateVersionCommandState, sendForceUpdateVersionsWithValidation]
}
