import type { PickSeasonWinnerActions } from '../interfaces/actions/PickSeasonWinnerActions'
import type { PickSeasonWinnerState } from '../interfaces/PickSeasonWinnerState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: PickSeasonWinnerState = {
	isLoading: false,
	isAvailable: true,
	result: null,
}

export default function pickSeasonWinner(state = initialState, action: PickSeasonWinnerActions): PickSeasonWinnerState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.PICK_SEASON_WINNER_START:
			return { ...initialState, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.PICK_SEASON_WINNER_FAILURE:
			console.error('PICK_SEASON_WINNER_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.PICK_SEASON_WINNER_SUCCESS:
			console.info('PICK_SEASON_WINNER_SUCCESS')
			console.table(Object.fromEntries(action.payload.map((seasonWinner, index) => [index + 1, seasonWinner])))
			return { ...state, isLoading: false, result: action.payload }
		default:
			return state
	}
}
