import styled from 'styled-components'
import { CardButton } from '../Card'
import type { ExecuteAutomationButtonWrapperProps } from './ExecuteAutomationButtonWrapperProps'

export const ExecuteAutomationButtonContainer = styled(CardButton)<ExecuteAutomationButtonWrapperProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0 !important;
`
