import { useCallback } from 'react'
import forceUpdateVersion from '../../../redux/dashboardInputs/actions/forceUpdateVersionState'
import type { ForceUpdateVersionState } from '../../../redux/dashboardInputs/interfaces/ForceUpdateVersionState'
import type { ForceUpdateVersionStateProps } from '../../../redux/dashboardInputs/interfaces/ForceUpdateVersionStateProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseForceUpdateVersionState = [ForceUpdateVersionState, (forceUpdateVersion: ForceUpdateVersionStateProps) => void]

export const useForceUpdateVersionState = (): UseForceUpdateVersionState => {
	const forceUpdateVersionState = useAppSelector(({ dashboardInputs: { forceUpdateVersion } }: StateTree) => forceUpdateVersion)
	const dispatch = useAppDispatch()
	const updateState = useCallback(
		(forceUpdateVersionData: ForceUpdateVersionStateProps) => forceUpdateVersion(forceUpdateVersionData)(dispatch),
		[dispatch],
	)

	return [forceUpdateVersionState, updateState]
}
