import type { Dispatch } from 'redux'
import type { ShowTopThreeToggleAction } from '../interfaces/actions/ShowTopThreeToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const showTopThreeToggle = (isAvailable: boolean): ShowTopThreeToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_TOP_THREE_TOGGLE, isAvailable }
}

export default function toggleShowTopThree(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(showTopThreeToggle(isAvailable))
	}
}
