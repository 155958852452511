import { useCallback } from 'react'
import { Game } from '../../../config/enums'
import beatSettingsState from '../../../redux/dashboardInputs/actions/beatSettingsState'
import breakoutSettingsState from '../../../redux/dashboardInputs/actions/breakoutSettingsState'
import centipedeSettingsState from '../../../redux/dashboardInputs/actions/centipedeSettingsState'
import rallySettingsState from '../../../redux/dashboardInputs/actions/rallySettingsState'
import type { BaseGameSettingsProps, BaseGameSettingsState } from '../../../redux/dashboardInputs/interfaces/GamesSettings'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseBaseGameSettings = [BaseGameSettingsState, (props: BaseGameSettingsProps) => void, () => void]

export const useBaseGameSettings = (gameName: Game): UseBaseGameSettings => {
	const gameSettings = useAppSelector(({ dashboardInputs: { beatSettings, centipedeSettings, rallySettings, breakoutSettings } }: StateTree) => {
		switch (gameName) {
			case Game.Centipede:
				return centipedeSettings
			case Game.Rally:
				return rallySettings
			case Game.Breakout:
				return breakoutSettings
			case Game.Beat:
			default:
				return beatSettings
		}
	})
	const dispatch = useAppDispatch()
	const updateGameSettingsState = useCallback(
		(props: BaseGameSettingsProps) => {
			switch (gameName) {
				case Game.Centipede:
					return centipedeSettingsState(props)(dispatch)
				case Game.Rally:
					return rallySettingsState(props)(dispatch)
				case Game.Breakout:
					return breakoutSettingsState(props)(dispatch)
				case Game.Beat:
				default:
					return beatSettingsState(props)(dispatch)
			}
		},
		[dispatch, gameName],
	)

	const toggleBeatify = () => {
		updateGameSettingsState({
			stage: { ...gameSettings.stage.value, beatified: !gameSettings.stage.value.beatified },
			level: gameSettings.level.value,
		})
	}

	return [gameSettings, updateGameSettingsState, toggleBeatify]
}
