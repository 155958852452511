import styled from 'styled-components'
import { commandsMenuWidth, liveChatContainerBottom } from '../../config/styles/theme'
import { CardBody } from '../../containers/Card'
import { LiveChatButton, LiveChatWrapper } from '../LiveChat/LiveChatContainer'

export const LogWrapper = styled(LiveChatWrapper)`
	width: 450px;
	background: darkslategray;
`

export const LogContainer = styled.div`
	position: fixed;
	bottom: ${liveChatContainerBottom}px;
	left: ${commandsMenuWidth + 65}px;
	color: white;
`

export const LogBody = styled(CardBody)`
	overflow-y: auto;
	max-height: 400px;
`

export const LogFooter = styled.div`
	display: flex;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	padding-top: 20px;
`

export const LogClearButton = styled.button`
	display: block;
	background-color: transparent;
	color: white;
	margin: 0 15px;

	&:hover {
		background-color: transparent;
	}
`

export const LogExportLink = styled.a`
	display: block;
	background-color: transparent;
	color: white;
	margin-left: auto;
`

export const LogButton = styled(LiveChatButton)`
	right: 0;
	left: ${commandsMenuWidth + 20}px;
`
