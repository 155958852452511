import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { getDateTimeTimestamp } from '../../../utils/getDateTimeTimestamp'
import { isTimeValid } from '../../../utils/isTimeValid'
import { nextShowTimeStateValid } from '../../dashboardInputs/actions/nextShowDateTimeState'
import type { StateTree } from '../../interfaces'
import type { UpdateNextShowTimeFailureAction } from '../interfaces/actions/UpdateNextShowTimeFailureAction'
import type { UpdateNextShowTimeStartAction } from '../interfaces/actions/UpdateNextShowTimeStartAction'
import type { UpdateNextShowTimeSuccessAction } from '../interfaces/actions/UpdateNextShowTimeSuccessAction'
import type { OverwriteNextShowTime } from '../interfaces/OverwriteNextShowTime'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const updateNextShowTimeStart = (): UpdateNextShowTimeStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_SHOW_TIME_COMMAND_START }
}

const updateNextShowTimeFailure = (): UpdateNextShowTimeFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_SHOW_TIME_COMMAND_FAILURE }
}

const updateNextShowTimeSuccess = (): UpdateNextShowTimeSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_SHOW_TIME_COMMAND_SUCCESS }
}

export default function updateNextShowTime() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(updateNextShowTimeStart())

		try {
			const {
				dashboardInputs: { nextShowDateTime },
			} = getState()

			const nextShowTimeStamp = getDateTimeTimestamp(nextShowDateTime)

			if (!isTimeValid(nextShowTimeStamp)) {
				dispatch(nextShowTimeStateValid({ isTimeValid: false, isDateValid: false }))
				console.error('Invalid date')
				dispatch(updateNextShowTimeFailure())
				return
			}

			const overwriteNextShowTimeCommand: OverwriteNextShowTime = {
				command: 'overwriteNextShowTime',
				value: nextShowTimeStamp,
			}
			await sendCommandToStudio(overwriteNextShowTimeCommand)

			dispatch(updateNextShowTimeSuccess())
		} catch {
			dispatch(updateNextShowTimeFailure())
		}
	}
}
