import type { Dispatch } from 'redux'
import type { KeyHash } from '../../../interfaces/KeyHash'
import type { KeyValueRecord } from '../../../interfaces/KeyValueRecord'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { GetStepFunctionExecutionCommand } from '../interfaces/GetStepFunctionExecutionCommand'
import type {
	GetStepFunctionExecutionIdFailureAction,
	GetStepFunctionExecutionIdStartAction,
	GetStepFunctionExecutionIdSuccessAction,
} from '../interfaces/GetStepFunctionExecutionId'
import { REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES } from '../types'

const getStepFunctionExecutionIdStart = (): GetStepFunctionExecutionIdStartAction => {
	return { type: REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES.GET_STEP_FUNCTION_EXECUTION_ID_START }
}

const getStepFunctionExecutionIdFailure = (): GetStepFunctionExecutionIdFailureAction => {
	return { type: REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES.GET_STEP_FUNCTION_EXECUTION_ID_FAILURE }
}

const getStepFunctionExecutionIdSuccess = (payload?: KeyValueRecord): GetStepFunctionExecutionIdSuccessAction => {
	return { type: REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES.GET_STEP_FUNCTION_EXECUTION_ID_SUCCESS, payload }
}

export const getStepFunctionExecutionId =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(getStepFunctionExecutionIdStart())

		try {
			const getStepFunctionExecutionIdCommand: GetStepFunctionExecutionCommand = {
				command: 'getKeys',
				keyIds: ['stepFunctionExecutionArn'],
				keyType: 'global',
			}

			const stepFunctionExecutionId = await sendCommandToStudio<KeyHash>(getStepFunctionExecutionIdCommand).then(
				({ stepFunctionExecutionArn }) => stepFunctionExecutionArn,
			)

			dispatch(getStepFunctionExecutionIdSuccess(stepFunctionExecutionId))
		} catch {
			dispatch(getStepFunctionExecutionIdFailure())
		}
	}
