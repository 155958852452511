import type { FunctionComponentElement } from 'react'
import React from 'react'
import type { StateTree } from '../../redux/interfaces'
import { getDay } from '../../utils/getDay'
import { useAppSelector } from '../../utils/hooks'
import { CalendarDay } from './Calendar'

function RenderDay({ date, label }: { date: Date; label: string }): FunctionComponentElement<typeof CalendarDay> {
	const currentMonthSavedShows = useAppSelector(({ showCalendar }: StateTree) => showCalendar.currentMonthSavedShows)
	const hasData = currentMonthSavedShows.includes(getDay(date))
	return <CalendarDay hasData={hasData}>{label}</CalendarDay>
}

export default RenderDay
