import type { ShowType } from '../../../config/enums'
import type { ShowTypeSelectAction } from '../interfaces/actions/ShowTypeSelectAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const showTypeSelectAction = (payload: ShowType): ShowTypeSelectAction => {
	return {
		type: REDUX_STUDIO_COMMAND_TYPES.SHOW_TYPE_SELECT,
		payload,
	}
}
