import { useCallback } from 'react'
import seasonWinnerState from '../../../redux/dashboardInputs/actions/seasonWinnerState'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseSeasonWinner = [string, (userId: string) => void]

export const useSeasonWinner = (): UseSeasonWinner => {
	const userId = useAppSelector(
		({
			dashboardInputs: {
				seasonWinnerState: { userId },
			},
		}: StateTree) => userId,
	)

	const dispatch = useAppDispatch()
	const updateSeasonWinnerState = useCallback((userId: string) => seasonWinnerState(userId)(dispatch), [dispatch])

	return [userId, updateSeasonWinnerState]
}
