import type { UpdateGuestActions } from '../interfaces/actions/UpdateGuestInfoActions'
import type { UpdateGuestInfoState } from '../interfaces/reducers/UpdateGuestInfoState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: UpdateGuestInfoState = {
	isUpdating: false,
}

export default function updateGuestInfo(state = initialState, action: UpdateGuestActions): UpdateGuestInfoState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_GUEST_INFO_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_GUEST_INFO_COMMAND_FAILURE:
			console.error('UPDATE_GUEST_INFO_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_GUEST_INFO_COMMAND_SUCCESS:
			console.log('UPDATE_GUEST_INFO_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
