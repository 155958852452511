import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { rallyExclusiveStages, stages } from '../../config/app'
import { Game } from '../../config/enums'
import type { GameStageSettings } from '../../redux/dashboardInputs/interfaces/GamesSettings'
import Toggle from '../Toggle'

interface Props {
	gameName: Game
	value: GameStageSettings
	keyValue: string
	onChangeName: (event: ChangeEvent<HTMLSelectElement>) => void
	onChangeBeatifiedToggle?: (status?: boolean) => void
}

function GameStageSelect({
	value: { name: stageName, beatified },
	keyValue,
	gameName,
	onChangeName,
	onChangeBeatifiedToggle,
}: Props): FunctionComponentElement<typeof HTMLElement> {
	const selectStages = gameName === Game.Rally ? [...stages, ...rallyExclusiveStages] : stages
	return (
		<>
			<select name={keyValue} id={keyValue} value={stageName} onChange={onChangeName}>
				{selectStages.map(({ key, name }, index) => (
					<option key={`${keyValue}-${index}`} value={key}>
						{name}
					</option>
				))}
			</select>
			{gameName === Game.Beat && onChangeBeatifiedToggle && (
				<Toggle label="Beatify stage" status={beatified} onChange={onChangeBeatifiedToggle} />
			)}
		</>
	)
}

export default GameStageSelect
