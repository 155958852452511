import { timeFramedGoals } from '../../../config/app'
import { TimeFrame } from '../../../interfaces/TimeFrame'
import type { NextDailyGoalsActions } from '../interfaces/goals/daily/NextDailyGoalsActions'
import type { NextDailyGoalsState } from '../interfaces/goals/daily/NextDailyGoalsState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const initialState: NextDailyGoalsState = {
	value: {
		0: timeFramedGoals[TimeFrame.Daily][0],
		1: timeFramedGoals[TimeFrame.Daily][0],
		2: timeFramedGoals[TimeFrame.Daily][0],
	},
	isValid: true,
}

export default function nextDailyGoalsState(state = initialState, action: NextDailyGoalsActions): NextDailyGoalsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_DAILY_GOALS_UPDATE: {
			const nextDailyGoals = action.props
			return {
				isValid: initialState.isValid,
				value: nextDailyGoals,
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_DAILY_GOALS_VALID: {
			const { isNextDailyGoalsValid } = action.props
			return {
				...state,
				isValid: isNextDailyGoalsValid,
			}
		}
		default:
			return state
	}
}
