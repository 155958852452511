import type { FunctionComponentElement } from 'react'
import React from 'react'
import { CardBody, CardButton, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { BannedPlayersTable } from '../../containers/ChatBans'
import { useChatBans } from '../../utils/hooks/useChatBans'

function ChatBans(): FunctionComponentElement<typeof CardWrapper> {
	const [chatBansState, updateFromServer, unbanPlayer] = useChatBans()
	return (
		<CardWrapper style={{ width: '90%' }}>
			<CardHeader>
				<CardHeading>Chat Bans</CardHeading>
			</CardHeader>
			<CardBody>
				{chatBansState.errorOnBan && <p style={{ color: 'red' }}>{chatBansState.errorOnBan}</p>}
				{chatBansState.errorOnUnban && <p style={{ color: 'red' }}>{chatBansState.errorOnUnban}</p>}
				{chatBansState.errorOnGet ? (
					<p style={{ color: 'red' }}>{chatBansState.errorOnGet}</p>
				) : (
					<BannedPlayersTable>
						<thead>
							<tr>
								<th>PlayFabId</th>
								<th>Display Name</th>
								<th>Original Message Content</th>
								<th>Unban Player</th>
							</tr>
						</thead>
						<tbody>
							{chatBansState.banned.map((bannedPlayer) => (
								<tr key={bannedPlayer.playFabId}>
									<td>{bannedPlayer.playFabId}</td>
									<td>{bannedPlayer.name}</td>
									<td>{bannedPlayer.content}</td>
									<td>
										<button disabled={bannedPlayer.tryingToUnban} onClick={() => unbanPlayer(bannedPlayer)}>
											Unban
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</BannedPlayersTable>
				)}
				<CardButton disabled={chatBansState.isLoading} onClick={updateFromServer}>
					{chatBansState.isLoading ? 'Loading...' : 'Refresh'}
				</CardButton>
			</CardBody>
		</CardWrapper>
	)
}

export default ChatBans
