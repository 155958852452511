import { createGlobalStyle } from 'styled-components'
import { darkTheme } from './theme'

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  *:focus {
    outline:none
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  a, body, footer {
    color: ${darkTheme.text};
  }

  body {
    background: ${darkTheme.body};
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: all 0.25s linear;
  }

  input, button {
    border-radius: 4px;
    border: ${darkTheme.body};
    text-align: center;
  }

  button {
    cursor: pointer;
  }

  button:hover {
    background-color: darkgrey;
  }
`
