import { useCallback } from 'react'
import saveScheduledShowInformation, { saveScheduledShowInformationFailure } from '../../../redux/studioCommand/actions/saveScheduledShowInformation'
import saveShowInformation, { saveShowInformationFailure } from '../../../redux/studioCommand/actions/saveShowInformation'
import { useAppDispatch, useAppStore } from '..'

export const useSaveShow = (): ((isNextShow: boolean) => Promise<void>) => {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()

	const saveNextShow = useCallback(() => saveShowInformation()(dispatch, getState), [dispatch, getState])
	const saveScheduledShow = useCallback(() => saveScheduledShowInformation()(dispatch, getState), [dispatch, getState])

	const confirmSaveShow = async (isNextShow: boolean): Promise<void> => {
		const { confirm: confirmDialog } = window
		const confirmMessage =
			'This will possibly overwrite the currently stored values for the SELECTED DATE if any. Are you sure you want to proceed?'

		if (confirmDialog(confirmMessage)) {
			await saveScheduledShow()
			if (isNextShow) {
				await saveNextShow()
			}
		} else {
			console.warn('action not confirmed')
			dispatch(saveScheduledShowInformationFailure())
			if (isNextShow) dispatch(saveShowInformationFailure())
		}
	}

	return confirmSaveShow
}
