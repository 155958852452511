import { useCallback } from 'react'
import beatGuestMessagesUpdate from '../../../redux/dashboardInputs/actions/beatGuestMessagesState'
import type { BeatGuestMessageState } from '../../../redux/dashboardInputs/interfaces/BeatGuestMessageState'
import type { BeatGuestMessagesUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/BeatGuestMessagesUpdateActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseBeatGuestMessagesState = [
	BeatGuestMessageState,
	BeatGuestMessageState,
	BeatGuestMessageState,
	BeatGuestMessageState,
	(props: BeatGuestMessagesUpdateActionProps) => void,
]

export const useBeatGuestMessagesState = (): UseBeatGuestMessagesState => {
	const { yesFirst, yesSecond, noFirst, noSecond } = useAppSelector(({ dashboardInputs: { beatGuestMessages } }: StateTree) => beatGuestMessages)
	const dispatch = useAppDispatch()
	const updateBeatGuestMessagesState = useCallback(
		(props: BeatGuestMessagesUpdateActionProps) => beatGuestMessagesUpdate(props)(dispatch),
		[dispatch],
	)

	return [yesFirst, yesSecond, noFirst, noSecond, updateBeatGuestMessagesState]
}
