import type { NewsState } from '../interfaces/NewsState'
import type { NewsStateActions } from '../interfaces/NewsStateActions'
import { REDUX_NEWS_TYPES } from '../types'

export const initialNewsState: NewsState = {
	data: {},
	isLoading: false,
}

export default function newsState(state = initialNewsState, action: NewsStateActions): NewsState {
	switch (action.type) {
		case REDUX_NEWS_TYPES.NEWS_FETCH_START: {
			return { ...initialNewsState, isLoading: true }
		}
		case REDUX_NEWS_TYPES.NEWS_FETCH_FAILURE: {
			return initialNewsState
		}
		case REDUX_NEWS_TYPES.NEWS_FETCH_SUCCESS: {
			return { ...initialNewsState, data: action.payload }
		}
		case REDUX_NEWS_TYPES.NEWS_STATE_UPDATE: {
			const isCritical = action.props.config.order.some((id) => action.props.articles?.[id].isCritical)
			const newConfig = { ...action.props.config, isCritical, currentNewsId: action.props.config.currentNewsId + 1 }

			return { ...initialNewsState, ...state, data: { articles: action.props.articles, config: newConfig } }
		}
		default:
			return state
	}
}
