import type { FunctionComponentElement } from 'react'
import React from 'react'
import { CardBody, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import NewsList from '../NewsList'

function News(): FunctionComponentElement<typeof CardWrapper> {
	return (
		<CardWrapper style={{ width: '60%' }}>
			<CardHeader>
				<CardHeading>News</CardHeading>
			</CardHeader>
			<CardBody>
				<NewsList isActive={true} />
				<NewsList isActive={false} />
			</CardBody>
		</CardWrapper>
	)
}

export default News
