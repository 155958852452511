import dayjs from 'dayjs'
import type { NextShowTimeActions, NextShowTimeState } from '../interfaces/NextShowTimeActions'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const initialState: NextShowTimeState = {
	date: {
		value: dayjs().add(1, 'day').format('YYYY-MM-DD'),
		isValid: true,
	},
	time: { value: '21:00', isValid: true },
}

export default function nextShowTimeState(state = initialState, action: NextShowTimeActions): NextShowTimeState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_SHOW_DATE_TIME_UPDATE: {
			const date = { ...initialState.date, value: action.payload.date }
			const time = { ...initialState.time, value: action.payload.time }
			return { date, time }
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_SHOW_DATE_TIME_VALID: {
			const { isDateValid, isTimeValid } = action.validations
			const validationDate = {
				...state.date,
				isValid: isDateValid,
			}
			const validationTime = { ...state.time, isValid: isTimeValid }
			return { date: validationDate, time: validationTime }
		}
		default:
			return state
	}
}
