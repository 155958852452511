import type { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { darkTheme } from '../../config/styles/theme'
import { Input, TextArea } from '../Input/Input'

export const CardWrapper = styled.div`
	overflow: hidden;
	padding: 0 0 32px;
	margin: 0 40px 20px;
	width: 300px;
	display: inline-block;
	font-family: Quicksand, arial, sans-serif;
	box-shadow: ${darkTheme.boxShadow};
	border-radius: 5px;
`

export const CardGroup = styled.div<{ flexDirection?: string }>`
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'row')};
	align-items: flex-start;
	justify-content: center;
`

export const CardGroupDelimiter = styled.span`
	display: inline-block;
	width: 100%;
	height: 5px;
	background-color: ${darkTheme.delimiter};
	margin-bottom: 20px;
`

export const CardHeader = styled.div`
	padding-top: 15px;
	padding-bottom: 32px;
`

export const CardHeading = styled.h2`
	font-size: 1.17em;
	font-weight: bold;
	text-align: center;
`

export const CardBody = styled.div`
	padding-right: 32px;
	padding-left: 32px;
`

export const CardFieldSet = styled.fieldset`
	position: relative;
	padding: 0;
	margin: 0;
	border: 0;

	& + & {
		margin-top: 24px;
		text-align: center;
	}
`

export const CardTextArea = styled(TextArea)`
	padding: 7px;
	width: 100%;
	font-family: inherit;
	font-size: 0.8em;
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid #ddd;
	border-left: 0;
	transition: border-bottom-color 0.25s ease-in;
	resize: vertical;

	&:focus {
		border-bottom-color: #e5195f;
		outline: 0;
	}
`

export const CardInput = styled(Input)`
	padding: 7px;
	width: 100%;
	font-family: inherit;
	font-size: 0.8em;
	border-top: 0;
	border-right: 0;
	border-left: 0;
	transition: border-bottom-color 0.25s ease-in;

	&:focus {
		border-bottom-color: #e5195f;
		outline: 0;
	}
`

export const CardListContainer = styled.div``

export const EqualDivider = styled.div`
  display: flex;
  margin: 0.5rem;
  padding: 1rem;

  > * {
    flex: 1;

    &:not(:first-child) {
      margin-top: 1rem
			margin-left: 1rem;
    }
  }
`

export const Child = styled.div`
	padding: 0.25rem 0.5rem;
`

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isAvailable?: boolean
}

export const CardButton = styled.button<ButtonProps>`
	display: block;
	width: 100%;
	padding: 12px 0;
	font-family: inherit;
	font-size: 0.9em;
	font-weight: 700;
	border: 0;
	border-radius: 35px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
	cursor: pointer;
	transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

	&:hover:enabled {
		box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
		transform: translate(0, -5px);
	}

	&:disabled,
	&:hover:disabled {
		cursor: not-allowed;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
		transform: none;
	}

	${({ isAvailable }) =>
		isAvailable === false &&
		css`
			background: green;
			color: white;
		`}
`
