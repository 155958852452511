import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import pickWinners from '../../../redux/studioCommand/actions/pickWinners'
import togglePickWinners from '../../../redux/studioCommand/actions/togglePickWinners'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'
import { usePickWinnersValidation } from '../validations/usePickWinnersValidation'

type UsePickWinners = [PromiseVoid, ToggleCommand]

export default function usePickWinners(): UsePickWinners {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				pickWinners: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const togglePickWinnersButton = useCallback((isAvailable: boolean) => togglePickWinners(isAvailable)(dispatch), [dispatch])
	const sendPickWinners = useCallback(() => pickWinners()(dispatch, getState), [dispatch, getState])
	const validate = usePickWinnersValidation()

	useEffect(() => {
		togglePickWinnersButton(isAvailable)
	}, [isAvailable, togglePickWinnersButton])

	const triggerPickWinners = async (): Promise<void> => {
		if (validate()) {
			await sendPickWinners()
		}
	}

	return [triggerPickWinners, togglePickWinnersButton]
}
