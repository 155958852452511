import dayjs from 'dayjs'
import type { ChangeEvent, FunctionComponentElement } from 'react'
import React, { useState } from 'react'
import type { Article } from '../../redux/news/interfaces/NewsState'
import { Template } from '../../redux/news/interfaces/NewsState'
import { useNewsUpdateCommand } from '../../utils/hooks/commands/useNewsUpdate'
import EnumSelect from '../EnumSelect'
import NewsArticleEdit from '../NewsArticleEdit'

interface Props {
	article: Article
	position: number
	onChangeOrder: (event: ChangeEvent<HTMLInputElement>) => void
	onChangeArticleName: (event: ChangeEvent<HTMLInputElement>) => void
	onChangeArticleHeader: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeArticleSubHeader: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeArticleMessage: (index: number) => (event: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => void
	onChangeArticleFooter: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeArticleImage: (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onChangeTemplate: (event: ChangeEvent<HTMLSelectElement>) => void
	onActivationClick: () => void
	onAddField: (fieldType: 'headers' | 'subHeaders' | 'messages' | 'footers' | 'images') => () => void
	onChangeIsCritical: () => void
}

function NewsListItem({
	article,
	position,
	onAddField,
	onChangeOrder,
	onChangeIsCritical,
	onChangeArticleName,
	onChangeTemplate,
	onChangeArticleHeader,
	onChangeArticleSubHeader,
	onChangeArticleMessage,
	onChangeArticleFooter,
	onChangeArticleImage,
	onActivationClick,
}: Props): FunctionComponentElement<typeof HTMLSelectElement> {
	const [isEditing, setIsEditing] = useState(false)
	const [updateCommandState, update] = useNewsUpdateCommand()

	const onEditClick = (): void => {
		setIsEditing(!isEditing)
	}

	const onUpdateClick = async (): Promise<void> => {
		await update()
		setIsEditing(!isEditing)
	}

	return (
		<>
			<li style={{ display: 'flex', justifyContent: 'center', margin: '15px 0' }}>
				<input type="number" value={position} placeholder="1" min={1} step={1} style={{ width: '10%' }} onChange={onChangeOrder} />
				<EnumSelect
					keyElement={`${article.id}-temp-select`}
					selectedEnum={article.templateId}
					onChangeEnumSelect={onChangeTemplate}
					Enum={Template}
				/>
				<input type="text" style={{ width: '25%' }} value={article.name} onChange={onChangeArticleName} />
				<label style={{ width: '15%' }}>{dayjs(article.updatedAt).format('DD.MM.YY')}</label>
				<button onClick={onActivationClick} disabled={updateCommandState.isUpdating}>
					{article.isActive ? 'Deactivate' : 'Activate'}
				</button>
				{!isEditing ? (
					<button disabled={updateCommandState.isUpdating} onClick={onEditClick}>
						Edit
					</button>
				) : (
					<button disabled={updateCommandState.isUpdating} onClick={onUpdateClick}>
						Update
					</button>
				)}
			</li>
			{isEditing && (
				<NewsArticleEdit
					onChangeArticleHeader={onChangeArticleHeader}
					onChangeArticleSubHeader={onChangeArticleSubHeader}
					onChangeArticleMessage={onChangeArticleMessage}
					onChangeArticleFooter={onChangeArticleFooter}
					onChangeArticleImage={onChangeArticleImage}
					onAddField={onAddField}
					onChangeIsCritical={onChangeIsCritical}
					{...article}
				/>
			)}
		</>
	)
}

export default NewsListItem
