import { useCallback } from 'react'
import nextShowDateTimeStateUpdate from '../../../redux/dashboardInputs/actions/nextShowDateTimeState'
import type { DateTimeProps, NextShowTimeState } from '../../../redux/dashboardInputs/interfaces/NextShowTimeActions'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseNextShowDateTimeState = [NextShowTimeState, (props: DateTimeProps) => void]

export const useNextShowDateTime = (): UseNextShowDateTimeState => {
	const nextShowDateTime = useAppSelector(({ dashboardInputs: { nextShowDateTime } }: StateTree) => nextShowDateTime)
	const dispatch = useAppDispatch()
	const updateNextShowDateTimeState = useCallback((props: DateTimeProps) => nextShowDateTimeStateUpdate(props)(dispatch), [dispatch])

	return [nextShowDateTime, updateNextShowDateTimeState]
}
