import { useState } from 'react'
import type { DayThirtyRollingRetention } from '../../redux/studioCommand/interfaces/DayThirtyRollingRetention'
import { sendCommandToStudio } from '../../services/api/serverless'
import { isInternalEmailValid } from '../validation/isInternalEmailValid'
import type { PromiseVoid } from './useCommands'

type UseRollingRetention = [boolean, PromiseVoid]

export const useRollingRetention = (): UseRollingRetention => {
	const [isLoading, setIsLoading] = useState(false)

	const promptForEmail = (): string | null => {
		const { prompt: promptDialog } = window
		const promptMessage = 'Please enter internal (@portalone.com) email to send the result to'
		let answer = promptDialog(promptMessage)
		if (answer === null) return answer
		answer = answer.trim().toLowerCase()
		if (!isInternalEmailValid(answer)) return promptForEmail()
		return answer
	}

	const getRollingRetentionData = async () => {
		setIsLoading(true)

		const email = promptForEmail()
		if (!email) {
			console.warn('action not confirmed')
			setIsLoading(false)
			return
		}

		const command: DayThirtyRollingRetention = {
			command: 'dayThirtyRollingRetention',
			email,
		}

		await sendCommandToStudio(command)
		console.info(`DayThirtyRollingRetention will be sent to ${email} once it's ready`)
		setIsLoading(false)
	}

	return [isLoading, getRollingRetentionData]
}
