import type { SaveScheduledShowInformationActions } from '../interfaces/actions/SaveScheduledShowInformationActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function saveScheduledShowInformation(state = initialState, action: SaveScheduledShowInformationActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_FAILURE:
			console.error(action.type)
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_SUCCESS:
			console.log(action.type)
			return initialState
		default:
			return state
	}
}
