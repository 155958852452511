import type { Dispatch } from 'redux'
import type { PrizesUpdateAction } from '../interfaces/PrizesUpdateAction'
import type { PrizesUpdateActionProps } from '../interfaces/PrizesUpdateActionProps'
import type { PrizesValidAction } from '../interfaces/PrizesValidAction'
import type { PrizesValidActionProps } from '../interfaces/PrizesValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const prizesStateUpdate = (props: PrizesUpdateActionProps): PrizesUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.PRIZES_STATE_UPDATE,
		props,
	}
}

export const prizesStateValid = (props: PrizesValidActionProps): PrizesValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.PRIZES_STATE_VALID,
		props,
	}
}

export default function prizesState(props: PrizesUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		dispatch(prizesStateUpdate(props))
	}
}
