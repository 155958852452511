import { useCallback, useEffect, useState } from 'react'
import fetchFeatureFlags from '../../redux/featureFlags/actions/fetchFeatureFlags'
import type { FeatureFlagsState } from '../../redux/featureFlags/interfaces/FeatureFlagsState'
import { initialFeatureFlagsState } from '../../redux/featureFlags/reducers/featureFlagsState'
import type { StateTree } from '../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '.'

type UseFeatureFlagsState = [FeatureFlagsState]

export const useFeatureFlagsState = (): UseFeatureFlagsState => {
	const [featureFlagsState, setFeatureFlagsState] = useState<FeatureFlagsState>(initialFeatureFlagsState)
	const featureFlags = useAppSelector(({ featureFlags }: StateTree) => featureFlags)
	const dispatch = useAppDispatch()
	const requestFetchFeatureFlags = useCallback(() => fetchFeatureFlags()(dispatch), [dispatch])

	useEffect(() => {
		requestFetchFeatureFlags()
	}, [requestFetchFeatureFlags])

	useEffect(() => {
		if (Object.keys(featureFlags.data).length > 0) {
			setFeatureFlagsState(featureFlags)
		}
	}, [featureFlags])

	return [featureFlagsState]
}
