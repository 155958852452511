import type { LeaguesResetIsActiveToggleAction, LeaguesResetToggleAction } from '../interfaces/actions/LeaguesResetToggleActions'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const leaguesResetToggleAction = (payload: boolean): LeaguesResetToggleAction => {
	return {
		type: REDUX_STUDIO_COMMAND_TYPES.LEAGUES_RESET_TOGGLE,
		payload,
	}
}

export const leaguesResetIsActiveToggleAction = (payload: boolean): LeaguesResetIsActiveToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.LEAGUES_RESET_DISABLED_TOGGLE, payload }
}
