import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { ForceUpdateVersionFailureAction } from '../interfaces/actions/ForceUpdateVersionFailureAction'
import type { ForceUpdateVersionStartAction } from '../interfaces/actions/ForceUpdateVersionStartAction'
import type { ForceUpdateVersionSuccessAction } from '../interfaces/actions/ForceUpdateVersionSuccessAction'
import type { ForceUpdateVersion } from '../interfaces/ForceUpdateVersion'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const forceUpdateVersionStart = (): ForceUpdateVersionStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.FORCE_UPDATE_VERSION_COMMAND_START }
}

const forceUpdateVersionFailure = (): ForceUpdateVersionFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.FORCE_UPDATE_VERSION_COMMAND_FAILURE }
}

const forceUpdateVersionSuccess = (): ForceUpdateVersionSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.FORCE_UPDATE_VERSION_COMMAND_SUCCESS }
}

export default function forceUpdateVersion() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(forceUpdateVersionStart())

		try {
			const {
				dashboardInputs: {
					forceUpdateVersion: {
						android: { value: minVersionAndroid },
						iOS: { value: minVersionIos },
					},
				},
			} = getState()

			const versionsData: ForceUpdateVersion = {
				command: 'setMinVersion',
				minVersionAndroid,
				minVersionIos,
			}
			await sendCommandToStudio(versionsData)

			dispatch(forceUpdateVersionSuccess())
		} catch {
			dispatch(forceUpdateVersionFailure())
		}
	}
}
