import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { ExecuteShowFailureAction } from '../interfaces/actions/ExecuteShowFailureAction'
import type { ExecuteShowStartAction } from '../interfaces/actions/ExecuteShowStartAction'
import type { ExecuteShowSuccessAction } from '../interfaces/actions/ExecuteShowSuccessAction'
import type { ExecuteShow } from '../interfaces/ExecuteShow'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const executeShowStart = (): ExecuteShowStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.EXECUTE_SHOW_START }
}

export const executeShowFailure = (): ExecuteShowFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.EXECUTE_SHOW_FAILURE }
}

const executeShowSuccess = (): ExecuteShowSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.EXECUTE_SHOW_SUCCESS }
}

export default function executeShow(signal: boolean) {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(executeShowStart())

		try {
			const executeShowCommand: ExecuteShow = {
				command: 'executeShow',
				signal,
			}
			await sendCommandToStudio<string>(executeShowCommand)
			dispatch(executeShowSuccess())
		} catch {
			dispatch(executeShowFailure())
		}
	}
}
