import type { OverwriteNextGoalsActions } from '../interfaces/actions/OverwriteNextGoalsActions'
import type { OverwriteNextGoalsState } from '../interfaces/reducers/OverwriteNextGoalsState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: OverwriteNextGoalsState = {
	isUpdating: false,
}

export default function overwriteNextGoals(state = initialState, action: OverwriteNextGoalsActions): OverwriteNextGoalsState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_GOALS_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_GOALS_COMMAND_FAILURE:
			console.error('OVERWRITE_DAILY_GOALS_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_GOALS_COMMAND_SUCCESS:
			console.log('OVERWRITE_DAILY_GOALS_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
