import type { Dispatch } from 'redux'
import type { BeatGuestMessagesUpdateAction } from '../interfaces/BeatGuestMessagesUpdateAction'
import type { BeatGuestMessagesUpdateActionProps } from '../interfaces/BeatGuestMessagesUpdateActionProps'
import type { BeatGuestMessagesValidAction } from '../interfaces/BeatGuestMessagesValidAction'
import type { BeatGuestMessagesValidActionProps } from '../interfaces/BeatGuestMessagesValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const beatGuestMessagesStateUpdate = (props: BeatGuestMessagesUpdateActionProps): BeatGuestMessagesUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BEAT_GUEST_MESSAGES_STATE_UPDATE,
		props,
	}
}

export const beatGuestMessagesStateValid = (props: BeatGuestMessagesValidActionProps): BeatGuestMessagesValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BEAT_GUEST_MESSAGES_STATE_VALID,
		props,
	}
}

export default function beatGuestMessagesState(props: BeatGuestMessagesUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		const { yesFirst, yesSecond, noFirst, noSecond } = props

		dispatch(beatGuestMessagesStateUpdate({ yesFirst, yesSecond, noFirst, noSecond }))
	}
}
