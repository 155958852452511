import type { LeaguesResetToggleActions } from '../interfaces/actions/LeaguesResetToggleActions'
import type { LeaguesResetToggleState } from '../interfaces/reducers/LeaguesResetToggleState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: LeaguesResetToggleState = {
	leaguesResetStatus: false,
	isAvailable: true,
}

export default function leaguesResetToggle(state = initialState, action: LeaguesResetToggleActions): LeaguesResetToggleState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.LEAGUES_RESET_TOGGLE:
			return { ...state, leaguesResetStatus: action.payload }
		case REDUX_STUDIO_COMMAND_TYPES.LEAGUES_RESET_DISABLED_TOGGLE:
			return { ...state, isAvailable: action.payload }
		default:
			return state
	}
}
