import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type {
	ListSavedShowDaysFailureAction,
	ListSavedShowDaysStartAction,
	ListSavedShowDaysSuccessAction,
} from '../interfaces/actions/ShowCalendarActions'
import type { ListSavedShowDays } from '../interfaces/ListSavedShowDays'
import { REDUX_SHOW_CALENDAR_TYPES } from '../types'

const listSavedShowDaysStart = (): ListSavedShowDaysStartAction => {
	return { type: REDUX_SHOW_CALENDAR_TYPES.LIST_SAVED_SHOW_DAYS_START }
}

const listSavedShowDaysFailure = (): ListSavedShowDaysFailureAction => {
	return { type: REDUX_SHOW_CALENDAR_TYPES.LIST_SAVED_SHOW_DAYS_FAILURE }
}

const listSavedShowDaysSuccess = (payload: string[]): ListSavedShowDaysSuccessAction => {
	return { type: REDUX_SHOW_CALENDAR_TYPES.LIST_SAVED_SHOW_DAYS_SUCCESS, payload }
}

export const listSavedShowDays =
	(month: string) =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(listSavedShowDaysStart())

		try {
			const listSavedShowDaysCommand: ListSavedShowDays = {
				command: 'listSavedShowsDays',
				month,
			}

			const listSavedShowsDaysResult = await sendCommandToStudio<string[]>(listSavedShowDaysCommand)

			dispatch(listSavedShowDaysSuccess(listSavedShowsDaysResult))
		} catch {
			dispatch(listSavedShowDaysFailure())
		}
	}
