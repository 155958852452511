import type { ForceUpdateVersionActions } from '../interfaces/actions/ForceUpdateVersionActions'
import type { ForceUpdateVersionCommand } from '../interfaces/reducers/ForceUpdateVersionCommand'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: ForceUpdateVersionCommand = {
	isUpdating: false,
}

export default function forceUpdateVersion(state = initialState, action: ForceUpdateVersionActions): ForceUpdateVersionCommand {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.FORCE_UPDATE_VERSION_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.FORCE_UPDATE_VERSION_COMMAND_FAILURE:
			console.error('FORCE_UPDATE_VERSION_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.FORCE_UPDATE_VERSION_COMMAND_SUCCESS:
			console.log('FORCE_UPDATE_VERSION_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
