import reduxWebsocket from '@giantmachines/redux-websocket'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './rootReducer'

const reduxWebsocketMiddleware = reduxWebsocket()

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware({
			serializableCheck: false, // due to this check not working well with @giantmachines/redux-websocket
		}),
		reduxWebsocketMiddleware,
	],
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
