import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React, { useState } from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import type { MarkPlayerAsEmployee } from '../../redux/studioCommand/interfaces/MarkPlayerAsEmployee'
import { sendCommandToStudio } from '../../services/api/serverless'

function EmployeeMarker(): FunctionComponentElement<typeof CardWrapper> {
	const [isUpdating, setIsUpdating] = useState<boolean>(false)
	const [emailToMark, setEmailToMark] = useState<string>('')

	const onEmailToMarkChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		setEmailToMark(value)
	}

	const clearFields = (): void => {
		setEmailToMark('')
	}

	const onUpdateClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()
		setIsUpdating(true)

		try {
			const markPlayerAsEmployeeCommand: MarkPlayerAsEmployee = {
				command: 'markPlayerAsEmployee',
				email: emailToMark,
			}

			const result = await sendCommandToStudio(markPlayerAsEmployeeCommand)
			console.info(`Player with the email ${emailToMark} has been marked as an employee`, result)
			clearFields()
		} catch {
			console.error('Error marking player as an employee')
		}
		setIsUpdating(false)
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Mark Player as Employee</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Email</label>
					<CardInput value={emailToMark} type="text" onChange={onEmailToMarkChange} />
				</CardFieldSet>
				<CardFieldSet>
					<CardButton type="button" disabled={isUpdating} onClick={onUpdateClick}>
						Submit
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default EmployeeMarker
