import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import type { Game, GameStage } from '../../config/enums'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { useOverwriteGameSettingsCommand } from '../../utils/hooks/commands/useGameSettingsOverwriteCommand'
import { useBaseGameSettings } from '../../utils/hooks/dashboardInputs/useBaseGameSettings'
import ArtLevelSelect from '../GameStageSelect'

interface Props {
	gameName: Game
}

function BaseGameSettings({ gameName }: Props): FunctionComponentElement<typeof CardWrapper> {
	const [gameSettings, updateGameSettings, toggleBeatify] = useBaseGameSettings(gameName)
	const { level, stage } = gameSettings
	const [isOverwriteGameCommandState, overwriteGameSettingsCommand] = useOverwriteGameSettingsCommand(gameName)

	const onChangeLevel = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateGameSettings({
			stage: stage.value,
			level: Number(value),
		})
	}

	const onChangeStageName = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateGameSettings({
			stage: { ...stage.value, name: value as GameStage },
			level: level.value,
		})
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Game ({gameName})</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Level</label>
					<CardInput type="number" value={level.value} isValid={level.isValid} step={1} min={0} placeholder="0" onChange={onChangeLevel} />
				</CardFieldSet>
				<CardFieldSet>
					<label>Stage: </label>
					<ArtLevelSelect
						keyValue="art-level"
						value={stage.value}
						onChangeName={onChangeStageName}
						onChangeBeatifiedToggle={toggleBeatify}
						gameName={gameName}
					/>
				</CardFieldSet>
				<CardFieldSet>
					<CardButton type="button" disabled={isOverwriteGameCommandState} onClick={overwriteGameSettingsCommand}>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default BaseGameSettings
