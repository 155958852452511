import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { GetBannedPlayers } from '../../studioCommand/interfaces/GetBannedPlayers'
import type { BannedPlayer } from '../interfaces/BannedPlayer'
import type {
	GetChatBannedPlayersFailureAction,
	GetChatBannedPlayersStartAction,
	GetChatBannedPlayersSuccessAction,
} from '../interfaces/ChatBanActions'
import { REDUX_CHAT_BANS_TYPES } from '../types'

const getChatBannedPlayersStart = (): GetChatBannedPlayersStartAction => {
	return { type: REDUX_CHAT_BANS_TYPES.GET_CHAT_BANS_START }
}

const getChatBannedPlayersFailure = (error: string): GetChatBannedPlayersFailureAction => {
	return { type: REDUX_CHAT_BANS_TYPES.GET_CHAT_BANS_FAILURE, error }
}

const getChatBannedPlayersSuccess = (payload: BannedPlayer[]): GetChatBannedPlayersSuccessAction => {
	return { type: REDUX_CHAT_BANS_TYPES.GET_CHAT_BANS_SUCCESS, payload }
}

interface GetBannedPlayersResponse {
	[userId: string]: {
		name: string
		content: string
	}
}

export const getChatBannedPlayers =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(getChatBannedPlayersStart())

		const getChatBannedPlayersCommand: GetBannedPlayers = {
			command: 'getBannedPlayers',
		}

		try {
			const data = await sendCommandToStudio<GetBannedPlayersResponse>(getChatBannedPlayersCommand)
			const payload: BannedPlayer[] = Object.entries(data).map(([playFabId, info]) => ({ playFabId, ...info }))
			dispatch(getChatBannedPlayersSuccess(payload))
		} catch {
			dispatch(getChatBannedPlayersFailure(`Failed to retrieve Chat Bans info from the server`))
		}
	}
