import { timeFramedGoals } from '../../../config/app'
import { TimeFrame } from '../../../interfaces/TimeFrame'
import type { NextMonthlyGoalsActions } from '../interfaces/goals/monthly/NextMonthlyGoalsActions'
import type { NextMonthlyGoalsState } from '../interfaces/goals/monthly/NextMonthlyGoalsState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const initialState: NextMonthlyGoalsState = {
	value: {
		0: timeFramedGoals[TimeFrame.Monthly][0],
		1: timeFramedGoals[TimeFrame.Monthly][0],
		2: timeFramedGoals[TimeFrame.Monthly][0],
		3: timeFramedGoals[TimeFrame.Monthly][0],
		4: timeFramedGoals[TimeFrame.Monthly][0],
	},
	reset: false,
	isValid: true,
}

export default function nextMonthlyGoalsState(state = initialState, action: NextMonthlyGoalsActions): NextMonthlyGoalsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_MONTHLY_GOALS_UPDATE: {
			const nextMonthlyGoals = action.props
			return {
				...state,
				isValid: initialState.isValid,
				value: nextMonthlyGoals,
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_MONTHLY_GOALS_RESET: {
			return {
				...state,
				reset: !state.reset,
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_MONTHLY_GOALS_VALID: {
			const { isNextMonthlyGoalsValid } = action.props
			return {
				...state,
				isValid: isNextMonthlyGoalsValid,
			}
		}
		default:
			return state
	}
}
