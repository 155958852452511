import { studioCommandEndPoint } from '../../../config'
import { store } from '../../../redux/store'
import type { StudioCommands } from '../../../redux/studioCommand/interfaces/StudioCommands'
import request from '../../../utils/request'

export const sendCommandToStudio = async <T>(command: StudioCommands): Promise<T> => {
	const { getState } = store
	const {
		auth: { jwt },
	} = getState()

	return await request<T>(studioCommandEndPoint, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${jwt}`,
		},
		body: JSON.stringify(command),
	})
}
