import type { GuestInfoActions } from '../interfaces/GuestInfoActions'
import type { GuestInfoPropertyState } from '../interfaces/GuestInfoPropertyState'
import type { GuestInfoState } from '../interfaces/GuestInfoState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const guestInfoPropsInitialState: GuestInfoPropertyState = {
	value: '',
	isValid: true,
}

const initialState: GuestInfoState = {
	guestRoomPosterPictureURL: {
		value: 'https://portalone-arcade.s3-eu-west-1.amazonaws.com/guest/T_mobilePoster_host_MarkusBailey.png',
		isValid: true,
	},
	guestDetailsPictureURL: {
		value: 'https://portalone-arcade.s3-eu-west-1.amazonaws.com/guest/T_WidgetPortrait_host_MarkusBailey.png',
		isValid: true,
	},
	guestTitle: { value: 'Markus', isValid: true },
	guestDetails: {
		value: 'Join our fabulous host Markus Bailey, alongside a new and exciting guest every Wednesday and Sunday at 21.00 in the Arcade Show! (photo: Kristoffer Myhre)',
		isValid: true,
	},
	guestExternalURL: {
		value: 'https://www.instagram.com/markusbailey',
		isValid: true,
	},
}

export default function guestInfo(state = initialState, action: GuestInfoActions): GuestInfoState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.GUEST_INFO_STATE_UPDATE: {
			const { guestRoomPosterPictureURL, guestDetailsPictureURL, guestTitle, guestExternalURL, guestDetails } = action.props
			return {
				...initialState,
				guestRoomPosterPictureURL: {
					...guestInfoPropsInitialState,
					value: guestRoomPosterPictureURL,
				},
				guestDetailsPictureURL: {
					...guestInfoPropsInitialState,
					value: guestDetailsPictureURL,
				},
				guestTitle: { ...guestInfoPropsInitialState, value: guestTitle },
				guestDetails: { ...guestInfoPropsInitialState, value: guestDetails },
				guestExternalURL: {
					...guestInfoPropsInitialState,
					value: guestExternalURL,
				},
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.GUEST_INFO_STATE_VALID: {
			const {
				isGuestDetailsPictureUrlValid,
				isGuestDetailsValid,
				isGuestExternalUrlValid,
				isGuestRoomPosterPictureURLValid,
				isGuestTitleValid,
			} = action.props
			return {
				...initialState,
				guestRoomPosterPictureURL: {
					value: state.guestRoomPosterPictureURL.value,
					isValid: isGuestRoomPosterPictureURLValid,
				},
				guestDetails: {
					value: state.guestDetails.value,
					isValid: isGuestDetailsValid,
				},
				guestDetailsPictureURL: {
					value: state.guestDetailsPictureURL.value,
					isValid: isGuestDetailsPictureUrlValid,
				},
				guestTitle: {
					value: state.guestTitle.value,
					isValid: isGuestTitleValid,
				},
				guestExternalURL: {
					value: state.guestExternalURL.value,
					isValid: isGuestExternalUrlValid,
				},
			}
		}
		default:
			return state
	}
}
