import type { Dispatch } from 'redux'
import type { ResetChallengesToggleAction } from '../interfaces/actions/ResetChallengesActions'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const resetChallengesToggle = (isAvailable: boolean): ResetChallengesToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.RESET_CHALLENGES_TOGGLE, isAvailable }
}

export default function toggleResetChallenges(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(resetChallengesToggle(isAvailable))
	}
}
