import type { SeasonTransitionIsActiveToggleAction } from '../interfaces/actions/SeasonTransitionIsActiveToggleAction'
import type { SeasonTransitionToggleAction } from '../interfaces/actions/SeasonTransitionToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const seasonTransitionToggleAction = (payload: boolean): SeasonTransitionToggleAction => {
	return {
		type: REDUX_STUDIO_COMMAND_TYPES.SEASON_TRANSITION_TOGGLE,
		payload,
	}
}

export const seasonTransitionIsActiveToggleAction = (payload: boolean): SeasonTransitionIsActiveToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SEASON_TRANSITION_DISABLED_TOGGLE, payload }
}
