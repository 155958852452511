import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { Game } from '../../config/enums'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { getGameValue } from '../../utils/getGameValue'
import { useFeaturedGame } from '../../utils/hooks/dashboardInputs/useFeaturedGame'

const gameNames = Object.values(Game)
const selectValues: {
	text: string
	value: Game
}[] = gameNames.map((gameName) => ({ text: gameName, value: gameName }))

function FeaturedGame(): FunctionComponentElement<typeof CardWrapper> {
	const [featuredGame, featuredGameCommandState, updateFeaturedGame, sendOverwriteFeaturedGame] = useFeaturedGame()

	const onFeaturedGameChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			currentTarget: { value },
		} = event

		const featuredGame = getGameValue(value)
		if (!featuredGame) {
			throw new TypeError('Invalid Featured Game selected')
		}
		updateFeaturedGame(featuredGame)
	}

	if (featuredGameCommandState.error) {
		console.error(featuredGameCommandState.error)
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Arcade Show Game</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Next Game: </label>
					<select value={String(featuredGame)} onChange={onFeaturedGameChange}>
						{selectValues.map((game) => (
							<option key={`${game.text}`} value={String(game.value)}>
								{game.text}
							</option>
						))}
					</select>
				</CardFieldSet>
				<CardFieldSet>
					<CardButton type="button" disabled={featuredGameCommandState.isUpdating} onClick={() => sendOverwriteFeaturedGame()}>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default FeaturedGame
