import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StartGameFailureAction } from '../interfaces/actions/StartGameFailureAction'
import type { StartGameStartAction } from '../interfaces/actions/StartGameStartAction'
import type { StartGameSuccessAction } from '../interfaces/actions/StartGameSuccessAction'
import type { StartGame } from '../interfaces/StartGame'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const startGameStart = (): StartGameStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_GAME_START }
}

const startGameFailure = (): StartGameFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_GAME_FAILURE }
}

const startGameSuccess = (): StartGameSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_GAME_SUCCESS }
}

export default function startGame() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(startGameStart())

		try {
			const startGameCommand: StartGame = {
				command: 'startGame',
			}
			await sendCommandToStudio<string>(startGameCommand)
			dispatch(startGameSuccess())
		} catch {
			dispatch(startGameFailure())
		}
	}
}
