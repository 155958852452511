import { Game } from '../config/enums'
import type { BaseGameSettingsState, BlockbusterSettingsState, GameStageSettings } from '../redux/dashboardInputs/interfaces/GamesSettings'
import { formatLevel } from './formatLevel'

export interface BaseParsedGameSettings {
	level: string
	stage: GameStageSettings
}

export interface BlockbusterParsedGameSettings extends BaseParsedGameSettings {
	shots: string
}

export const parseBlockbusterSettingsValues = (blockbusterSettingsState: BlockbusterSettingsState): BlockbusterParsedGameSettings => {
	return {
		level: formatLevel(Game.Blockbuster, blockbusterSettingsState.level.value),
		stage: blockbusterSettingsState.stage.value,
		shots: Object.values(blockbusterSettingsState.shots.value).join(','),
	}
}

export const parseBaseGameSettingsValues = (baseGameSettingsState: BaseGameSettingsState, game: Game): BaseParsedGameSettings => {
	return {
		level: formatLevel(game, baseGameSettingsState.level.value),
		stage: baseGameSettingsState.stage.value,
	}
}
