import type { StopShowActions } from '../interfaces/actions/StopShowActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function stopShow(state = initialState, action: StopShowActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_FAILURE:
			console.error('STOP_SHOW_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_SUCCESS:
			console.log('STOP_SHOW_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
