import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React, { useState } from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import type { SetUserReferralCode } from '../../redux/studioCommand/interfaces/SetUserReferralCode'
import { sendCommandToStudio } from '../../services/api/serverless'

const validationRegex = /^[a-z0-9]{0,6}$/i

function ReferralCode(): FunctionComponentElement<typeof CardWrapper> {
	const [isUpdating, setIsUpdating] = useState<boolean>(false)
	const [playFabId, setPlayFabId] = useState<string>('')
	const [referralCode, setReferralCode] = useState<string>('')
	const isValidInput = playFabId.length > 0 && referralCode.length >= 4 && referralCode.length <= 6

	const onPlayFabIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		setPlayFabId(value)
	}

	const onReferralCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		if (!validationRegex.test(value)) {
			return
		}

		setReferralCode(value.toUpperCase())
	}

	const clearFields = (): void => {
		setPlayFabId('')
		setReferralCode('')
	}

	const onSubmitClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()
		setIsUpdating(true)

		try {
			const setUserReferralCodeCommand: SetUserReferralCode = {
				command: 'setUserReferralCode',
				playFabId,
				referralCode,
			}

			const result = await sendCommandToStudio(setUserReferralCodeCommand)
			console.info(`Set referral code to ${referralCode} for user with ID ${playFabId}`, result)
			clearFields()
		} catch {
			console.error('Error setting referral code')
		} finally {
			setIsUpdating(false)
		}
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Set custom referral code</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>PlayFab ID</label>
					<CardInput value={playFabId} type="text" onChange={onPlayFabIdChange} />
				</CardFieldSet>
				<CardFieldSet>
					<label>Referral code</label>
					<CardInput value={referralCode} type="text" onChange={onReferralCodeChange} />
				</CardFieldSet>
				<CardFieldSet>
					<CardButton type="button" disabled={isUpdating || !isValidInput} onClick={onSubmitClick}>
						Submit
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default ReferralCode
