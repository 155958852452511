import type { ChangeEvent, FunctionComponentElement } from 'react'
import React, { Fragment } from 'react'
import { CardFieldSet, CardInput, CardTextArea } from '../../containers/Card'
import type { ArticleImage, ArticleText } from '../../redux/news/interfaces/NewsState'
import { Alignment, TextColor, TextFont } from '../../redux/news/interfaces/NewsState'
import EnumSelect from '../EnumSelect'

interface Props {
	onChangeInputTypeEdit: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
	onAddField: () => void
	textItem?: ArticleText
	imageItem?: ArticleImage
	elementName: string
	index: number
	id: string
}

function ArticleInputTypeEdit({
	onChangeInputTypeEdit,
	onAddField,
	textItem,
	imageItem,
	index,
	id,
	elementName,
}: Props): FunctionComponentElement<typeof HTMLDivElement> {
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
			<CardFieldSet>
				<label>{elementName + '-' + index}</label>
				{elementName === 'message' ? (
					<CardTextArea value={textItem?.text} name="text" onChange={onChangeInputTypeEdit} />
				) : (
					<CardInput
						value={imageItem?.link || textItem?.text || ''}
						isValid={true}
						type="text"
						name={imageItem?.link !== undefined ? 'link' : 'text'}
						onChange={onChangeInputTypeEdit}
					/>
				)}
			</CardFieldSet>
			<CardFieldSet>
				<label>alignment: </label>
				<EnumSelect
					style={{ width: '100px' }}
					selectedEnum={imageItem?.alignment || textItem?.alignment || 0}
					name="alignment"
					keyElement={`${id}-${index}-${elementName}-alignment-select`}
					Enum={Alignment}
					onChangeEnumSelect={onChangeInputTypeEdit}
				/>
			</CardFieldSet>
			{!imageItem && (
				<Fragment>
					<CardFieldSet>
						<label>font: </label>
						<EnumSelect
							style={{ width: '100px' }}
							selectedEnum={textItem?.font || 0}
							name="font"
							keyElement={`${id}-${index}-${elementName}-font-select`}
							Enum={TextFont}
							onChangeEnumSelect={onChangeInputTypeEdit}
						/>
					</CardFieldSet>
					<CardFieldSet>
						<label>color: </label>
						<EnumSelect
							style={{ width: '100px' }}
							selectedEnum={textItem?.color || 0}
							name="color"
							keyElement={`${id}-${index}-${elementName}-color-select`}
							Enum={TextColor}
							onChangeEnumSelect={onChangeInputTypeEdit}
						/>
					</CardFieldSet>
				</Fragment>
			)}
			<button onClick={onAddField} style={{ width: '25px', height: '25px' }}>
				+
			</button>
		</div>
	)
}

export default ArticleInputTypeEdit
