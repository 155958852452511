import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import type { GameStage } from '../../config/enums'
import { Game } from '../../config/enums'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { useOverwriteGameSettingsCommand } from '../../utils/hooks/commands/useGameSettingsOverwriteCommand'
import { useBlockbusterSettings } from '../../utils/hooks/dashboardInputs/useBlockbusterSettings'
import { getOrdinal } from '../../utils/ordinal/getOrdinal'
import ArtLevelSelect from '../GameStageSelect'
import ShotSelect from '../ShotSelect'

function BlockbusterSettings(): FunctionComponentElement<typeof CardWrapper> {
	const gameName = Game.Blockbuster
	const [blockbusterSettings, updateBlockbusterSettings] = useBlockbusterSettings()
	const { level, stage, shots } = blockbusterSettings
	const [isOverwriteGameCommandState, overwriteGameSettingsCommand] = useOverwriteGameSettingsCommand(gameName)

	const onChangeShots = (event: ChangeEvent<HTMLSelectElement>, shotNumber: number) => {
		const {
			currentTarget: { value },
		} = event

		const newNextShots = { ...shots?.value, [shotNumber]: Number(value) }

		updateBlockbusterSettings({
			level: level.value,
			shots: newNextShots,
			stage: stage.value,
		})
	}

	const onChangeLevel = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateBlockbusterSettings({
			shots: shots.value,
			stage: stage.value,
			level: Number(value),
		})
	}

	const onChangeStageName = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateBlockbusterSettings({
			shots: shots.value,
			stage: { ...stage.value, name: value as GameStage },
			level: level.value,
		})
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Game ({gameName})</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Level</label>
					<CardInput type="number" value={level.value} isValid={level.isValid} step={1} min={0} placeholder="0" onChange={onChangeLevel} />
				</CardFieldSet>
				<CardFieldSet>
					<label>Stage: </label>
					<ArtLevelSelect keyValue="art-level" value={stage.value} onChangeName={onChangeStageName} gameName={gameName} />
				</CardFieldSet>
				{shots !== null &&
					shots.value &&
					Array(3)
						.fill('')
						.map((_, index) => {
							const rootKey = `nextshots-${index}`
							return (
								<CardFieldSet key={rootKey}>
									<label>{getOrdinal(index + 1) + ' shot: '}</label>
									<ShotSelect value={shots.value} shotNumber={index} keyValue={rootKey} onChange={onChangeShots} />
								</CardFieldSet>
							)
						})}
				<CardFieldSet>
					<CardButton type="button" disabled={isOverwriteGameCommandState} onClick={overwriteGameSettingsCommand}>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default BlockbusterSettings
