import type { Dispatch } from 'redux'
import type { SeasonWinnerUpdateAction } from '../interfaces/SeasonWinnerState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const seasonWinnerStateUpdate = (userId: string): SeasonWinnerUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.SEASON_WINNER_STATE_UPDATE,
		userId,
	}
}

export default function seasonWinnerState(userId: string) {
	return (dispatch: Dispatch): void => {
		dispatch(seasonWinnerStateUpdate(userId))
	}
}
