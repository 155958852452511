import type { Dispatch } from 'redux'
import { TimeFrame } from '../../../interfaces/TimeFrame'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { getNextGoals } from '../../../utils/getNextGoals'
import type { StateTree } from '../../interfaces'
import type { OverwriteNextGoalsFailureAction } from '../interfaces/actions/OverwriteNextGoalsFailureAction'
import type { OverwriteNextGoalsStartAction } from '../interfaces/actions/OverwriteNextGoalsStartAction'
import type { OverwriteNextGoalsSuccessAction } from '../interfaces/actions/OverwriteNextGoalsSuccessAction'
import type { OverwriteNextDailyGoals } from '../interfaces/OverwriteNextDailyGoals'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const overwriteNextGoalsStart = (): OverwriteNextGoalsStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_GOALS_COMMAND_START }
}

const overwriteNextGoalsFailure = (): OverwriteNextGoalsFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_GOALS_COMMAND_FAILURE }
}

const overwriteNextGoalsSuccess = (): OverwriteNextGoalsSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_GOALS_COMMAND_SUCCESS }
}

export default function overwriteNextGoals(timeFrame: TimeFrame) {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(overwriteNextGoalsStart())

		try {
			const {
				dashboardInputs: { nextDailyGoals, nextMonthlyGoals, nextWeeklyGoals },
			} = getState()

			const selectedGoal =
				timeFrame === TimeFrame.Daily
					? { nextDailyGoals }
					: timeFrame === TimeFrame.Weekly
						? { nextWeeklyGoals }
						: timeFrame === TimeFrame.Monthly
							? { nextMonthlyGoals }
							: {}

			const nextGoals = getNextGoals(selectedGoal, true)

			const studioCommand: OverwriteNextDailyGoals = {
				command: 'overwriteGoals',
				goals: nextGoals,
			}

			await sendCommandToStudio(studioCommand)

			dispatch(overwriteNextGoalsSuccess())
		} catch {
			dispatch(overwriteNextGoalsFailure())
		}
	}
}
