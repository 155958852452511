import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import startGame from '../../../redux/studioCommand/actions/startGame'
import toggleStartGame from '../../../redux/studioCommand/actions/toggleStartGame'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UseStartGame = [PromiseVoid, ToggleCommand]

export default function useStartGame(): UseStartGame {
	const dispatch = useAppDispatch()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				startGame: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const toggleStartGameButton = useCallback((isAvailable: boolean) => toggleStartGame(isAvailable)(dispatch), [dispatch])
	const sendStartGame = useCallback(() => startGame()(dispatch), [dispatch])

	useEffect(() => {
		toggleStartGameButton(isAvailable)
	}, [isAvailable, toggleStartGameButton])

	return [sendStartGame, toggleStartGameButton]
}
