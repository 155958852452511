import { timeFramedGoals } from '../../../config/app'
import { TimeFrame } from '../../../interfaces/TimeFrame'
import type { NextWeeklyGoalsActions } from '../interfaces/goals/weekly/NextWeeklyGoalsActions'
import type { NextWeeklyGoalsState } from '../interfaces/goals/weekly/NextWeeklyGoalsState'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const initialState: NextWeeklyGoalsState = {
	value: { 0: timeFramedGoals[TimeFrame.Weekly][0], 1: timeFramedGoals[TimeFrame.Weekly][0], 2: timeFramedGoals[TimeFrame.Weekly][0] },
	reset: false,
	isValid: true,
}

export default function nextWeeklyGoalsState(state = initialState, action: NextWeeklyGoalsActions): NextWeeklyGoalsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_WEEKLY_GOALS_UPDATE: {
			const nextWeeklyGoals = action.props
			return {
				...state,
				isValid: initialState.isValid,
				value: nextWeeklyGoals,
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_WEEKLY_GOALS_RESET: {
			return {
				...state,
				reset: !state.reset,
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.NEXT_WEEKLY_GOALS_VALID: {
			const { isNextWeeklyGoalsValid } = action.props
			return {
				...state,
				isValid: isNextWeeklyGoalsValid,
			}
		}
		default:
			return state
	}
}
