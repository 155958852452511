import type { Dispatch } from 'redux'
import type { StartCountdownAndShowToggleAction } from '../interfaces/actions/StartCountdownAndShowToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const startCountdownAndShowToggle = (isAvailable: boolean): StartCountdownAndShowToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_TOGGLE, isAvailable }
}

export default function toggleStartCountdownAndShow(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(startCountdownAndShowToggle(isAvailable))
	}
}
