import { ShowType } from '../../../config/enums'
import type { ShowTypeSelectAction } from '../interfaces/actions/ShowTypeSelectAction'
import type { ShowTypeSelectState } from '../interfaces/reducers/ShowTypeSelectState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: ShowTypeSelectState = {
	showType: ShowType.DailyArcadeTournament,
}

export default function showTypeSelect(state = initialState, action: ShowTypeSelectAction): ShowTypeSelectState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.SHOW_TYPE_SELECT:
			return { showType: action.payload }
		default:
			return state
	}
}
