import { useCallback } from 'react'
import type { Country } from '../../config/interfaces/Country'
import { selectCountryUpdate } from '../../redux/auth/actions/selectCountry'
import type { SelectCountryUpdateAction } from '../../redux/auth/interfaces/actions/SelectCountry/SelectCountryUpdateAction'
import type { SelectCountryState } from '../../redux/auth/interfaces/reducers/SelectCountryState'
import type { StateTree } from '../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '.'

interface UseCountry {
	updateCountry: (country: Country) => SelectCountryUpdateAction
	selectedCountry: SelectCountryState
}

export const useCountry = (): UseCountry => {
	const dispatch = useAppDispatch()
	const { selectedCountry } = useAppSelector(({ country }: StateTree) => ({ selectedCountry: country }))

	const updateCountry = useCallback((country: Country) => dispatch(selectCountryUpdate({ value: country })), [dispatch])

	return { updateCountry, selectedCountry }
}
