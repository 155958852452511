import { useCallback } from 'react'
import { Game } from '../../../config/enums'
import { validateDashboardInputValues } from '../../../redux/dashboardInputs/interfaces/DashboardInputValues'
import overwriteDefaultValues, { overwriteDefaultValuesFailure } from '../../../redux/studioCommand/actions/overwriteDefaultValues'
import type { GetDefaultValues } from '../../../redux/studioCommand/interfaces/GetDefaultValues'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { useAppDispatch, useAppStore } from '..'
import { useAnalyticsCuePoints } from '../dashboardInputs/useAnalyticsCuePoints'
import { useBaseGameSettings } from '../dashboardInputs/useBaseGameSettings'
import { useBeatGuestMessagesState } from '../dashboardInputs/useBeatGuestMessages'
import { useBlockbusterSettings } from '../dashboardInputs/useBlockbusterSettings'
import { useFeaturedGame } from '../dashboardInputs/useFeaturedGame'
import { useForceUpdateVersionState } from '../dashboardInputs/useForceUpdateVersionState'
import { useGuestInfoUpdate } from '../dashboardInputs/useGuestInfoUpdate'
import { useNextDailyGoalsState } from '../dashboardInputs/useNextDailyGoals'
import { useNextMonthlyGoalsState } from '../dashboardInputs/useNextMonthlyGoals'
import { useNextShowDateTime } from '../dashboardInputs/useNextShowTime'
import { useNextWeeklyGoalsState } from '../dashboardInputs/useNextWeeklyGoals'
import { usePrizesState } from '../dashboardInputs/usePrizes'
import { useTimestampAutomationSettings } from '../dashboardInputs/useTimestampAutomationSettings'
import { useVideoState } from '../dashboardInputs/useVideoState'

export const useDefaultValues = (): [() => Promise<void>, () => Promise<void>] => {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const [, , , , updateBeatGuestMessagesState] = useBeatGuestMessagesState()
	const [, updateForceUpdateVersionState] = useForceUpdateVersionState()
	const [, updateGuestInfoState] = useGuestInfoUpdate()
	const [, updateBlockbusterSettings] = useBlockbusterSettings()
	const [, updateCentipedeSettings] = useBaseGameSettings(Game.Centipede)
	const [, updateBeatSettings] = useBaseGameSettings(Game.Beat)
	const [, updateRallySettings] = useBaseGameSettings(Game.Rally)
	const [, updateBreakoutSettings] = useBaseGameSettings(Game.Breakout)
	const [, , updateFeaturedGame] = useFeaturedGame()
	const [, updateNextDailyGoalsState] = useNextDailyGoalsState()
	const [, updateNextWeeklyGoalsState] = useNextWeeklyGoalsState()
	const [, updateNextMonthlyGoalsState] = useNextMonthlyGoalsState()
	const [, updateNextShowDateTimeState] = useNextShowDateTime()
	const [, , , , , , , updatePrizesState] = usePrizesState()
	const [, , , , updateVideoState] = useVideoState()
	const [, , updateTimestampAutomationSettingsState] = useTimestampAutomationSettings()
	const [, , updateAnalyticsCuePointsState] = useAnalyticsCuePoints()

	const sendOverwriteDefaultValues = useCallback(() => overwriteDefaultValues()(dispatch, getState), [dispatch, getState])

	const confirmOverwriteDefaultValues = async (): Promise<void> => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'Are you sure you want to overwrite the default values?'

		if (confirmDialog(confirmMessage)) {
			await sendOverwriteDefaultValues()
		} else {
			console.warn('action not confirmed')
			dispatch(overwriteDefaultValuesFailure())
		}
	}

	const replaceValuesWithDefaults = useCallback(async () => {
		const getDefaultValuesCommand: GetDefaultValues = {
			command: 'getDefaultValues',
		}
		const response = await sendCommandToStudio<unknown>(getDefaultValuesCommand)
		if (!validateDashboardInputValues(response)) {
			console.error(validateDashboardInputValues.errors)
			alert('Default values were not applied: invalid default values structure received from the server, check the logs for more information')
			return
		}
		const {
			video,
			forceUpdateVersion,
			beatGuestMessages,
			guestInfo,
			nextShowDateTime,
			prizes,
			blockbusterSettings,
			centipedeSettings,
			beatSettings,
			rallySettings,
			breakoutSettings,
			featuredGame,
			nextDailyGoals,
			nextWeeklyGoals,
			nextMonthlyGoals,
			timestampsAutomationSettings,
			analyticsCuePoints,
		} = response

		updateVideoState(video)
		updateForceUpdateVersionState(forceUpdateVersion)
		updateBeatGuestMessagesState(beatGuestMessages)
		updateGuestInfoState(guestInfo)
		updateBlockbusterSettings(blockbusterSettings)
		updateCentipedeSettings(centipedeSettings)
		updateBeatSettings(beatSettings)
		updateRallySettings(rallySettings)
		updateBreakoutSettings(breakoutSettings)
		updateFeaturedGame(featuredGame)
		updateNextDailyGoalsState(nextDailyGoals)
		updateNextWeeklyGoalsState(nextWeeklyGoals)
		updateNextMonthlyGoalsState(nextMonthlyGoals)
		updateNextShowDateTimeState(nextShowDateTime)
		updatePrizesState(prizes)
		updateTimestampAutomationSettingsState(timestampsAutomationSettings)
		updateAnalyticsCuePointsState(analyticsCuePoints)
	}, [
		updateBeatGuestMessagesState,
		updateForceUpdateVersionState,
		updateGuestInfoState,
		updateBlockbusterSettings,
		updateCentipedeSettings,
		updateBeatSettings,
		updateRallySettings,
		updateBreakoutSettings,
		updateFeaturedGame,
		updateNextDailyGoalsState,
		updateNextWeeklyGoalsState,
		updateNextMonthlyGoalsState,
		updateNextShowDateTimeState,
		updatePrizesState,
		updateVideoState,
		updateTimestampAutomationSettingsState,
		updateAnalyticsCuePointsState,
	])

	return [confirmOverwriteDefaultValues, replaceValuesWithDefaults]
}
