import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import updateNews from '../../../redux/studioCommand/actions/updateNews'
import type { UpdateNewsCommandState } from '../../../redux/studioCommand/interfaces/reducers/UpdateNewsCommand'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid } from '../useCommands'

type UseNewsUpdateCommand = [UpdateNewsCommandState, PromiseVoid]

export const useNewsUpdateCommand = (): UseNewsUpdateCommand => {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()

	const updateNewsCommandState = useAppSelector(({ commands: { updateNews } }: StateTree) => updateNews)

	const updateNewsCommand = useCallback(() => updateNews()(dispatch, getState), [dispatch, getState])

	const confirmUpdateNews = async (): Promise<void> => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This will override the existent news in the Database. Are you sure you want to proceed?'

		if (confirmDialog(confirmMessage)) {
			await updateNewsCommand()
		} else {
			console.warn('action not confirmed')
		}
	}

	return [updateNewsCommandState, confirmUpdateNews]
}
