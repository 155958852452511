import type { UpdateNewsActions } from '../interfaces/actions/UpdateNewsActions'
import type { UpdateNewsCommandState } from '../interfaces/reducers/UpdateNewsCommand'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: UpdateNewsCommandState = {
	isUpdating: false,
}

export default function updateNextShowTime(state = initialState, action: UpdateNewsActions): UpdateNewsCommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEWS_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEWS_COMMAND_FAILURE:
			console.error('UPDATE_NEXT_SHOW_TIME_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEWS_COMMAND_SUCCESS:
			console.log('UPDATE_NEXT_SHOW_TIME_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
