import type { Dispatch } from 'redux'
import type { NextWeeklyGoalsResetAction } from '../interfaces/goals/weekly/NextWeeklyGoalsResetAction'
import type { NextWeeklyGoalsUpdateAction } from '../interfaces/goals/weekly/NextWeeklyGoalsUpdateAction'
import type { NextWeeklyGoalsUpdateActionProps } from '../interfaces/goals/weekly/NextWeeklyGoalsUpdateActionProps'
import type { NextWeeklyGoalsValidAction } from '../interfaces/goals/weekly/NextWeeklyGoalsValidAction'
import type { NextWeeklyGoalsValidActionProps } from '../interfaces/goals/weekly/NextWeeklyGoalsValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const nextWeeklyGoalsStateUpdate = (props: NextWeeklyGoalsUpdateActionProps): NextWeeklyGoalsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_WEEKLY_GOALS_UPDATE,
		props,
	}
}

export const nextWeeklyGoalsStateValid = (props: NextWeeklyGoalsValidActionProps): NextWeeklyGoalsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_WEEKLY_GOALS_VALID,
		props,
	}
}

export const nextWeeklyGoalsReset = (): NextWeeklyGoalsResetAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_WEEKLY_GOALS_RESET,
	}
}

export default function nextWeeklyGoalsState(props: NextWeeklyGoalsUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		dispatch(nextWeeklyGoalsStateUpdate(props))
	}
}
