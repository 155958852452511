import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { getShowInformation } from '../../../utils/getShowInformation'
import type { StateTree } from '../../interfaces'
import updateShowCalendarState from '../../showCalendar/actions/updateShowCalendarState'
import type {
	SaveScheduledShowInformationFailureAction,
	SaveScheduledShowInformationStartAction,
	SaveScheduledShowInformationSuccessAction,
} from '../interfaces/actions/SaveScheduledShowInformationActions'
import type { SaveScheduledShowInformation } from '../interfaces/SaveScheduledShowInformation'
import type { ShowInformation } from '../interfaces/ShowInformation'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const saveScheduledShowInformationStart = (): SaveScheduledShowInformationStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_START }
}

export const saveScheduledShowInformationFailure = (): SaveScheduledShowInformationFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_FAILURE }
}

const saveScheduledShowInformationSuccess = (): SaveScheduledShowInformationSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SAVE_SCHEDULED_SHOW_INFORMATION_COMMAND_SUCCESS }
}

export default function saveShowInformation() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(saveScheduledShowInformationStart())

		try {
			const stateTree = getState()
			const showInformation: ShowInformation = getShowInformation(stateTree)
			const {
				showCalendar: { selectedDate: date },
			} = stateTree

			const saveScheduledShowInformationCommand: SaveScheduledShowInformation = {
				command: 'saveScheduledShowInformation',
				date,
				showInformation,
			}

			await sendCommandToStudio(saveScheduledShowInformationCommand)

			dispatch(saveScheduledShowInformationSuccess())
			updateShowCalendarState({ newSavedShow: date })(dispatch)
		} catch {
			dispatch(saveScheduledShowInformationFailure())
		}
	}
}
