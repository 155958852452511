import CalendarWidget from 'react-widgets/Calendar'
import styled from 'styled-components'

interface DayProps {
	hasData?: boolean
}

export const CalendarDay = styled.span<DayProps>`
	${(props) => (props.hasData ? 'font-weight: 900;' : '')}
`

export const Calendar = styled(CalendarWidget)`
	font-size: 10px;

	.rw-calendar-header {
		padding: 0;
	}

	.rw-calendar-btn {
		padding: 0 0.4em;
	}
`
