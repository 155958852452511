import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import startCountdownAndShow, {
	startCountdownAndShowFailure,
	startCountdownAndShowStart,
} from '../../../redux/studioCommand/actions/startCountdownAndShow'
import toggleStartCountdownAndShow from '../../../redux/studioCommand/actions/toggleStartCountdownAndShow'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'
import { useCountdownShowValidation } from '../validations/useCountdownShowValidation'

type UseCountdownShow = [PromiseVoid, ToggleCommand]

export default function useCountdownShow(): UseCountdownShow {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const { startCountdownShowAvailable } = useAppSelector(({ wsMessage: { startCountdownShowAvailable } }: StateTree) => ({
		startCountdownShowAvailable,
	}))
	const toggleStartCountdownAndShowButton = useCallback((isAvailable: boolean) => toggleStartCountdownAndShow(isAvailable)(dispatch), [dispatch])
	const sendStartCountdownAndShow = useCallback(() => startCountdownAndShow()(dispatch, getState), [dispatch, getState])
	const validate = useCountdownShowValidation()

	useEffect(() => {
		const { gameCountdown, startLiveShow } = startCountdownShowAvailable
		const isAvailable = gameCountdown && startLiveShow ? false : true
		toggleStartCountdownAndShowButton(isAvailable)
	}, [startCountdownShowAvailable, toggleStartCountdownAndShowButton])

	const sendStartWithValidation = async (): Promise<void> => {
		dispatch(startCountdownAndShowStart())
		const isValid = await validate()

		if (isValid) {
			sendStartCountdownAndShow()
		} else {
			console.error('invalid videoUrl&guestScore')
			dispatch(startCountdownAndShowFailure())
		}
	}

	return [sendStartWithValidation, toggleStartCountdownAndShowButton]
}
