import { TimestampAutomationCommand } from '../../../config/enums'
import type { TimestampProps } from '../interfaces/TimestampProps'
import type { TimestampsAutomationSettingsActions, TimestampsAutomationState } from '../interfaces/TimestampsAutomation'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const initialSettingsState: TimestampProps = {
	minute: 0,
	second: 0,
}

export const initialState: TimestampsAutomationState = {
	value: Object.fromEntries(Object.values(TimestampAutomationCommand).map((commandName) => [commandName, initialSettingsState])) as Record<
		TimestampAutomationCommand,
		TimestampProps
	>,
	isValid: true,
}

export default function timestampsAutomationSettingsState(
	state = initialState,
	action: TimestampsAutomationSettingsActions,
): TimestampsAutomationState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.TIMESTAMPS_AUTOMATION_SETTINGS_STATE_UPDATE: {
			const { isValid, value } = state

			return {
				...state,
				isValid,
				value: { ...value, ...action.props },
			}
		}
		case REDUX_DASHBOARD_INPUT_TYPES.TIMESTAMPS_AUTOMATION_SETTINGS_STATE_VALID: {
			const { value } = state

			return {
				...state,
				...value,
				isValid: action.props.isValid,
			}
		}
		default:
			return state
	}
}
