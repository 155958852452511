import type { Dispatch } from 'redux'
import type { DateTimeProps, DateTimeValidations, NextShowDateTimeUpdateAction, NextShowDateTimeValidAction } from '../interfaces/NextShowTimeActions'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const nextShowTimeStateUpdate = (payload: DateTimeProps): NextShowDateTimeUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_SHOW_DATE_TIME_UPDATE,
		payload,
	}
}

export const nextShowTimeStateValid = (validations: DateTimeValidations): NextShowDateTimeValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_SHOW_DATE_TIME_VALID,
		validations,
	}
}

export default function nextShowDateTimeState(props: DateTimeProps) {
	return (dispatch: Dispatch): void => {
		dispatch(nextShowTimeStateUpdate(props))
	}
}
