import type { Dispatch } from 'redux'
import type { RallySettingsUpdateAction, RallySettingsValidAction } from '../interfaces/GameSettingsActions'
import type { BaseGameSettingsProps, BaseGameSettingsValidActionProps } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const rallySettingsStateUpdate = (props: BaseGameSettingsProps): RallySettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.RALLY_UPDATE,
		props,
	}
}

export const rallySettingsStateValid = (props: BaseGameSettingsValidActionProps): RallySettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.RALLY_VALID,
		props,
	}
}

export default function rallySettingsState(props: BaseGameSettingsProps) {
	return (dispatch: Dispatch): void => {
		dispatch(rallySettingsStateUpdate(props))
	}
}
