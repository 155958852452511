import { useCallback } from 'react'
import blockbusterSettingsState from '../../../redux/dashboardInputs/actions/blockbusterSettingsState'
import type { BlockbusterSettingsProps, BlockbusterSettingsState } from '../../../redux/dashboardInputs/interfaces/GamesSettings'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseBlockbusterSettings = [BlockbusterSettingsState, (props: BlockbusterSettingsProps) => void]

export const useBlockbusterSettings = (): UseBlockbusterSettings => {
	const blockbusterSettings = useAppSelector(({ dashboardInputs: { blockbusterSettings } }: StateTree) => blockbusterSettings)
	const dispatch = useAppDispatch()
	const updateBlockbusterSettingsState = useCallback((props: BlockbusterSettingsProps) => blockbusterSettingsState(props)(dispatch), [dispatch])

	return [blockbusterSettings, updateBlockbusterSettingsState]
}
