import type { PrizesState } from '../../redux/dashboardInputs/interfaces/PrizesState'
import type { PrizesValidActionProps } from '../../redux/dashboardInputs/interfaces/PrizesValidActionProps'
import { isLeaguePrizeValueValid, isPrizeValueValid } from './isPrizeValueValid'

export const validatePrizes = ({
	tournamentChampion,
	tournamentTop10,
	beatGuestWinner,
	luckyWinner,
	coLuckyWinner,
	leagueWinner,
}: PrizesState): PrizesValidActionProps => {
	const isTournamentChampionValid = isPrizeValueValid(tournamentChampion)
	const isTournamentTop10Valid = isPrizeValueValid(tournamentTop10)
	const isBeatGuestWinnerValid = isPrizeValueValid(beatGuestWinner)
	const isLuckyWinnerValid = isPrizeValueValid(luckyWinner)
	const isCoLuckyWinnerValid = isPrizeValueValid(coLuckyWinner)
	const isLeagueWinnerValid = isLeaguePrizeValueValid(leagueWinner)

	return {
		isTournamentChampionValid,
		isTournamentTop10Valid,
		isBeatGuestWinnerValid,
		isLuckyWinnerValid,
		isCoLuckyWinnerValid,
		isLeagueWinnerValid,
	}
}
