import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import displayBeatGuest from '../../../redux/studioCommand/actions/displayBeatGuest'
import toggleDisplayBeatGuest from '../../../redux/studioCommand/actions/toggleDisplayBeatGuest'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'
import { useBeatGuestValidation } from '../validations/useBeatGuestValidation'

type UseDisplayBeatGuest = [PromiseVoid, ToggleCommand]

export default function useDisplayBeatGuest(): UseDisplayBeatGuest {
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				displayBeatGuest: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const toggleDisplayBeatGuestButton = useCallback((isAvailable: boolean) => toggleDisplayBeatGuest(isAvailable)(dispatch), [dispatch])
	const sendDisplayBeatGuest = useCallback(() => displayBeatGuest()(dispatch, getState), [dispatch, getState])
	const validate = useBeatGuestValidation()

	useEffect(() => {
		toggleDisplayBeatGuestButton(isAvailable)
	}, [isAvailable, toggleDisplayBeatGuestButton])

	const triggerSendDisplayBeatGuest = async (): Promise<void> => {
		if (validate()) {
			await sendDisplayBeatGuest()
		}
	}

	return [triggerSendDisplayBeatGuest, toggleDisplayBeatGuestButton]
}
