import { useBeatGuestValidation } from './useBeatGuestValidation'
import { useCountdownShowValidation } from './useCountdownShowValidation'
import { useGuestInfoValidation } from './useGuestInfoValidation'
import { usePickWinnersValidation } from './usePickWinnersValidation'

export const useAllFieldsValidation = (): (() => Promise<boolean>) => {
	const validateBeatGuest = useBeatGuestValidation()
	const validateVideoGuestScore = useCountdownShowValidation()
	const validateGuestInfo = useGuestInfoValidation()
	const validatePickWinnersData = usePickWinnersValidation()

	const validate = async (): Promise<boolean> => {
		const isBeatGuestValid = validateBeatGuest()
		const isPickWinnersDataValid = validatePickWinnersData()

		const promiseValidations = [validateVideoGuestScore(), validateGuestInfo()]

		const [isVideoGuestScoreValid, isGuestInfoValid] = await Promise.all(promiseValidations)

		return isBeatGuestValid && isPickWinnersDataValid && isVideoGuestScoreValid && isGuestInfoValid
	}

	return validate
}
