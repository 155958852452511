import { GameStage } from '../../../config/enums'
import type { BlockbusterActions } from '../interfaces/GameSettingsActions'
import type { BlockbusterSettingsState, BlockbusterShotsState } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'
import { levelInitialState } from './centipedeSettingsState'

const shotsInitialState: BlockbusterShotsState = {
	value: { 0: 0, 1: 0, 2: 0 },
	isValid: true,
}

const stageInitialState = {
	value: { name: GameStage.FutureCity, beatified: false },
	isValid: true,
}

const initialState: BlockbusterSettingsState = {
	level: levelInitialState,
	stage: stageInitialState,
	shots: shotsInitialState,
}

export default function blockbusterSettingsState(state = initialState, action: BlockbusterActions): BlockbusterSettingsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.BLOCKBUSTER_UPDATE: {
			const updatedValues: BlockbusterSettingsState = {
				level: { ...state.level, value: action.props.level },
				stage: { ...state.stage, value: action.props.stage },
				shots: { ...state.shots, value: action.props.shots },
			}
			return { ...state, ...updatedValues }
		}
		case REDUX_DASHBOARD_INPUT_TYPES.BLOCKBUSTER_VALID: {
			const updatedValues: BlockbusterSettingsState = {
				level: { ...state.level, isValid: action.props.isLevelValid },
				stage: { ...state.stage, isValid: action.props.isStageValid },
				shots: { ...state.shots, isValid: action.props.isShotsValid },
			}
			return { ...state, ...updatedValues }
		}
		default:
			return state
	}
}
