import type { Dispatch } from 'redux'
import type { PickWinnersActions } from '../interfaces/actions/PickWinnersActions'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const displayPickWinnersToggle = (isAvailable: boolean): PickWinnersActions => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_TOGGLE, isAvailable }
}

export default function togglePickWinners(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(displayPickWinnersToggle(isAvailable))
	}
}
