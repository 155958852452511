import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { FetchNews } from '../../studioCommand/interfaces/FetchNews'
import type { NewsFetchFailureAction } from '../interfaces/NewsFetchFailureAction'
import type { NewsFetchStartAction } from '../interfaces/NewsFetchStartAction'
import type { NewsFetchSuccessAction } from '../interfaces/NewsFetchSuccessAction'
import type { NewsArticles } from '../interfaces/NewsState'
import { REDUX_NEWS_TYPES } from '../types'

const fetchNewsStart = (): NewsFetchStartAction => {
	return {
		type: REDUX_NEWS_TYPES.NEWS_FETCH_START,
	}
}

const fetchNewsFailure = (): NewsFetchFailureAction => {
	return {
		type: REDUX_NEWS_TYPES.NEWS_FETCH_FAILURE,
	}
}

const fetchNewsSuccess = (payload: NewsArticles): NewsFetchSuccessAction => {
	return {
		type: REDUX_NEWS_TYPES.NEWS_FETCH_SUCCESS,
		payload,
	}
}

export default function fetchNews() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(fetchNewsStart())

		try {
			const getNewsCommand: FetchNews = {
				command: 'getNews',
			}
			const news = await sendCommandToStudio<NewsArticles>(getNewsCommand)

			dispatch(fetchNewsSuccess(news))
		} catch {
			dispatch(fetchNewsFailure())
		}
	}
}
