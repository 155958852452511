import type { SelectCountryUpdateAction } from '../interfaces/actions/SelectCountry/SelectCountryUpdateAction'
import type { SelectCountryUpdateActionProps } from '../interfaces/actions/SelectCountry/SelectCountryUpdateActionProps'
import type { SelectCountryValidAction } from '../interfaces/actions/SelectCountry/SelectCountryValidAction'
import type { SelectCountryValidActionProps } from '../interfaces/actions/SelectCountry/SelectCountryValidActionProps'
import { REDUX_AUTH_TYPES } from '../types'

export const selectCountryUpdate = (props: SelectCountryUpdateActionProps): SelectCountryUpdateAction => {
	return {
		type: REDUX_AUTH_TYPES.SELECT_COUNTRY_UPDATE,
		props,
	}
}

export const selectCountryValid = (props: SelectCountryValidActionProps): SelectCountryValidAction => {
	return {
		type: REDUX_AUTH_TYPES.SELECT_COUNTRY_VALID,
		props,
	}
}
