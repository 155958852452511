import type { Dispatch } from 'redux'
import type { AnalyticsCuePoints } from '../../../config/enums'
import type { AnalyticsCuePointsUpdateAction } from '../interfaces/AnalyticsCuePointsState'
import type { TimestampProps } from '../interfaces/TimestampProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const analyticsCuePointsStateUpdate = (props: Record<AnalyticsCuePoints, TimestampProps>): AnalyticsCuePointsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.ANALYTICS_CUE_POINTS_STATE_UPDATE,
		props,
	}
}

export default function analyticsCuePointsState(props: Record<AnalyticsCuePoints, TimestampProps>) {
	return (dispatch: Dispatch): void => {
		dispatch(analyticsCuePointsStateUpdate(props))
	}
}
