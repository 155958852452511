import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { getSelectedRegionTimeZone } from './getSelectedRegionTimeZone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const isTimeValid = (inputTime: number): boolean => {
	const nowTime = dayjs().tz(getSelectedRegionTimeZone()).valueOf()

	return inputTime > nowTime
}
