import type { Dispatch } from 'redux'
import type { TimestampAutomationCommand } from '../../../config/enums'
import type { TimestampProps } from '../interfaces/TimestampProps'
import type {
	TimestampsAutomationSettingsUpdateAction,
	TimestampsAutomationSettingsValidAction,
	TimestampsAutomationSettingsValidActionProps,
} from '../interfaces/TimestampsAutomation'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const timestampsAutomationSettingsStateUpdate = (
	props: Record<TimestampAutomationCommand, TimestampProps>,
): TimestampsAutomationSettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.TIMESTAMPS_AUTOMATION_SETTINGS_STATE_UPDATE,
		props,
	}
}

export const timestampsAutomationSettingsStateValid = (
	props: TimestampsAutomationSettingsValidActionProps,
): TimestampsAutomationSettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.TIMESTAMPS_AUTOMATION_SETTINGS_STATE_VALID,
		props,
	}
}

export default function timestampsAutomationSettingsState(props: Record<TimestampAutomationCommand, TimestampProps>) {
	return (dispatch: Dispatch): void => {
		dispatch(timestampsAutomationSettingsStateUpdate(props))
	}
}
