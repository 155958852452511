import { Game } from '../../../config/enums'
import type { FeatureGameUpdateAction } from '../interfaces/GameSettingsActions'
import type { FeaturedGame } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const initialState: FeaturedGame = Game.Blockbuster

export default function featuredGameState(state = initialState, action: FeatureGameUpdateAction): FeaturedGame {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.FEATURED_GAME_UPDATE: {
			return action.props
		}
		default:
			return state
	}
}
