import type { Goal } from '../interfaces/Goal'
import { TimeFrame } from '../interfaces/TimeFrame'
import type { NextDailyGoalsState } from '../redux/dashboardInputs/interfaces/goals/daily/NextDailyGoalsState'
import type { NextMonthlyGoalsState } from '../redux/dashboardInputs/interfaces/goals/monthly/NextMonthlyGoalsState'
import type { NextWeeklyGoalsState } from '../redux/dashboardInputs/interfaces/goals/weekly/NextWeeklyGoalsState'

export const getNextGoals = (
	{
		nextDailyGoals,
		nextWeeklyGoals,
		nextMonthlyGoals,
	}: {
		nextDailyGoals?: NextDailyGoalsState
		nextWeeklyGoals?: NextWeeklyGoalsState
		nextMonthlyGoals?: NextMonthlyGoalsState
	},
	overwrite = false,
): Partial<Record<TimeFrame, Goal[]>> => {
	const daily: Goal[] | undefined = nextDailyGoals?.value
		? Object.values(nextDailyGoals.value).map((goal) => ({ ...goal, timeFrame: TimeFrame.Daily }))
		: undefined
	const weekly: Goal[] | undefined =
		nextWeeklyGoals?.value && (nextWeeklyGoals?.reset || overwrite)
			? Object.values(nextWeeklyGoals.value).map((goal) => ({ ...goal, timeFrame: TimeFrame.Weekly }))
			: undefined
	const monthly: Goal[] | undefined =
		nextMonthlyGoals?.value && (nextMonthlyGoals?.reset || overwrite)
			? Object.values(nextMonthlyGoals.value).map((goal) => ({ ...goal, timeFrame: TimeFrame.Monthly }))
			: undefined

	return { daily, weekly, monthly }
}
