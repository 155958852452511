import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { ChatBanPlayer } from '../../studioCommand/interfaces/ChatBanPlayer'
import type { BannedPlayer } from '../interfaces/BannedPlayer'
import type { ChatBanPlayerFailureAction, ChatBanPlayerStartAction, ChatBanPlayerSuccessAction } from '../interfaces/ChatBanActions'
import { REDUX_CHAT_BANS_TYPES } from '../types'

const chatBanPlayerStartAction = (payload: string): ChatBanPlayerStartAction => {
	return { type: REDUX_CHAT_BANS_TYPES.CHAT_BAN_PLAYER_START, payload }
}

const chatBanPlayerFailureAction = (playFabId: string, error: string): ChatBanPlayerFailureAction => {
	return { type: REDUX_CHAT_BANS_TYPES.CHAT_BAN_PLAYER_FAILURE, payload: { playFabId, error } }
}

const chatBanPlayerSuccessAction = (payload: BannedPlayer): ChatBanPlayerSuccessAction => {
	return { type: REDUX_CHAT_BANS_TYPES.CHAT_BAN_PLAYER_SUCCESS, payload }
}

export const chatBanPlayer =
	(playerToBan: BannedPlayer) =>
	async (dispatch: Dispatch): Promise<void> => {
		const chatBanPlayerCommand: ChatBanPlayer = {
			command: 'chatBanPlayer',
			userId: playerToBan.playFabId,
			name: playerToBan.name,
			content: playerToBan.content,
		}

		dispatch(chatBanPlayerStartAction(playerToBan.playFabId))

		try {
			await sendCommandToStudio(chatBanPlayerCommand)
			dispatch(chatBanPlayerSuccessAction(playerToBan))
		} catch {
			dispatch(chatBanPlayerFailureAction(playerToBan.playFabId, `Failed to ban user ${playerToBan.name}`))
		}
	}
