import type { Dispatch } from 'redux'
import type { NextMonthlyGoalsResetAction } from '../interfaces/goals/monthly/NextMonthlyGoalsResetAction'
import type { NextMonthlyGoalsUpdateAction } from '../interfaces/goals/monthly/NextMonthlyGoalsUpdateAction'
import type { NextMonthlyGoalsUpdateActionProps } from '../interfaces/goals/monthly/NextMonthlyGoalsUpdateActionProps'
import type { NextMonthlyGoalsValidAction } from '../interfaces/goals/monthly/NextMonthlyGoalsValidAction'
import type { NextMonthlyGoalsValidActionProps } from '../interfaces/goals/monthly/NextMonthlyGoalsValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const nextMonthlyGoalsStateUpdate = (props: NextMonthlyGoalsUpdateActionProps): NextMonthlyGoalsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_MONTHLY_GOALS_UPDATE,
		props,
	}
}

export const nextMonthlyGoalsStateValid = (props: NextMonthlyGoalsValidActionProps): NextMonthlyGoalsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_MONTHLY_GOALS_VALID,
		props,
	}
}

export const nextMonthlyGoalReset = (): NextMonthlyGoalsResetAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_MONTHLY_GOALS_RESET,
	}
}

export default function nextMonthlyGoalsState(props: NextMonthlyGoalsUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		dispatch(nextMonthlyGoalsStateUpdate(props))
	}
}
