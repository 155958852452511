import { useCallback } from 'react'
import { beatGuestMessagesStateValid } from '../../../redux/dashboardInputs/actions/beatGuestMessagesState'
import type { BeatGuestMessagesValidActionProps } from '../../../redux/dashboardInputs/interfaces/BeatGuestMessagesValidActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { validateBeatGuestMessages } from '../../validation/beatGuestMessages'
import { useAppDispatch, useAppSelector } from '..'

export const useBeatGuestValidation = (): (() => boolean) => {
	const beatGuestMessages = useAppSelector(({ dashboardInputs: { beatGuestMessages } }: StateTree) => beatGuestMessages)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: BeatGuestMessagesValidActionProps) => {
			dispatch(beatGuestMessagesStateValid(props))
		},
		[dispatch],
	)

	return (): boolean => {
		const { isYesFirstValid, isYesSecondValid, isNoFirstValid, isNoSecondValid } = validateBeatGuestMessages(beatGuestMessages)

		if (isYesFirstValid && isYesSecondValid && isNoFirstValid && isNoSecondValid) {
			return true
		}

		triggerDispatchValidation({ isYesFirstValid, isYesSecondValid, isNoFirstValid, isNoSecondValid })

		return false
	}
}
