import type { Dispatch } from 'redux'
import type { GuestInfoStateValidAction } from '../interfaces/GuestInfoStateValidAction'
import type { GuestInfoStateValidProps } from '../interfaces/GuestInfoStateValidProps'
import type { UpdateGuestInfoStateAction } from '../interfaces/UpdateGuestInfoStateAction'
import type { UpdateGuestInfoStateProps } from '../interfaces/UpdateGuestInfoStateProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const updateGuestInfoState = (props: UpdateGuestInfoStateProps): UpdateGuestInfoStateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.GUEST_INFO_STATE_UPDATE,
		props,
	}
}

export const isValidGuestInfoState = (props: GuestInfoStateValidProps): GuestInfoStateValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.GUEST_INFO_STATE_VALID,
		props,
	}
}

export default function guestInfoState(props: UpdateGuestInfoStateProps) {
	return (dispatch: Dispatch): void => {
		dispatch(updateGuestInfoState(props))
	}
}
