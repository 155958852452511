import type { Dispatch } from 'redux'
import type { NextDailyGoalsUpdateAction } from '../interfaces/goals/daily/NextDailyGoalsUpdateAction'
import type { NextDailyGoalsUpdateActionProps } from '../interfaces/goals/daily/NextDailyGoalsUpdateActionProps'
import type { NextDailyGoalsValidAction } from '../interfaces/goals/daily/NextDailyGoalsValidAction'
import type { NextDailyGoalsValidActionProps } from '../interfaces/goals/daily/NextDailyGoalsValidActionProps'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const nextDailyGoalsStateUpdate = (props: NextDailyGoalsUpdateActionProps): NextDailyGoalsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_DAILY_GOALS_UPDATE,
		props,
	}
}

export const nextDailyGoalsStateValid = (props: NextDailyGoalsValidActionProps): NextDailyGoalsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.NEXT_DAILY_GOALS_VALID,
		props,
	}
}

export default function nextDailyGoalsState(props: NextDailyGoalsUpdateActionProps) {
	return (dispatch: Dispatch): void => {
		dispatch(nextDailyGoalsStateUpdate(props))
	}
}
