import type { Dispatch } from 'redux'
import type { BreakoutSettingsUpdateAction, BreakoutSettingsValidAction } from '../interfaces/GameSettingsActions'
import type { BaseGameSettingsProps, BaseGameSettingsValidActionProps } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const breakoutSettingsStateUpdate = (props: BaseGameSettingsProps): BreakoutSettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BREAKOUT_UPDATE,
		props,
	}
}

export const breakoutSettingsStateValid = (props: BaseGameSettingsValidActionProps): BreakoutSettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BREAKOUT_VALID,
		props,
	}
}

export default function breakoutSettingsState(props: BaseGameSettingsProps) {
	return (dispatch: Dispatch): void => {
		dispatch(breakoutSettingsStateUpdate(props))
	}
}
