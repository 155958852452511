import type { Dispatch } from 'redux'
import type { ShowCalendarStateUpdateAction } from '../interfaces/actions/ShowCalendarActions'
import type { ShowCalendarStateUpdateProps } from '../interfaces/reducers/ShowCalendarState'
import { REDUX_SHOW_CALENDAR_TYPES } from '../types'

const showCalendarStateUpdate = (props: ShowCalendarStateUpdateProps): ShowCalendarStateUpdateAction => {
	return {
		type: REDUX_SHOW_CALENDAR_TYPES.SHOW_CALENDAR_STATE_UPDATE,
		props,
	}
}

export default function showCalendarState(props: ShowCalendarStateUpdateProps) {
	return (dispatch: Dispatch): void => {
		dispatch(showCalendarStateUpdate(props))
	}
}
