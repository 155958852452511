import type { Dispatch } from 'redux'
import type { BeatSettingsUpdateAction, BeatSettingsValidAction } from '../interfaces/GameSettingsActions'
import type { BaseGameSettingsProps, BaseGameSettingsValidActionProps } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const beatSettingsStateUpdate = (props: BaseGameSettingsProps): BeatSettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BEAT_UPDATE,
		props,
	}
}

export const beatSettingsStateValid = (props: BaseGameSettingsValidActionProps): BeatSettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BEAT_VALID,
		props,
	}
}

export default function beatSettingsState(props: BaseGameSettingsProps) {
	return (dispatch: Dispatch): void => {
		dispatch(beatSettingsStateUpdate(props))
	}
}
