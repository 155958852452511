import type { FunctionComponentElement } from 'react'
import React from 'react'
import { timestampAutomationCommandTitles } from '../../config/app'
import type { TimestampAutomationCommand } from '../../config/enums'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import {
	maxMinuteSecondNumber,
	minTimeNumber,
	useTimestampAutomationSettings,
} from '../../utils/hooks/dashboardInputs/useTimestampAutomationSettings'

function TimestampsAutomation(): FunctionComponentElement<typeof CardWrapper> {
	const [timestampsSettings, updateTimestampSettingsState] = useTimestampAutomationSettings()

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Timestamps</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<table style={{ margin: '0 auto' }}>
						<thead>
							<tr>
								<th>Command</th>
								<th>Minute</th>
								<th>Second</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(timestampsSettings).map(([keyProperty, { minute, second }], index) => {
								const commandName = keyProperty as TimestampAutomationCommand
								const title = timestampAutomationCommandTitles[commandName]
								const onChangeFunction = updateTimestampSettingsState(commandName)

								return (
									<tr key={`${commandName}-${index}`}>
										<td>{title}</td>
										<td>
											<input
												type="number"
												value={minute}
												name="minute"
												min={minTimeNumber}
												max={maxMinuteSecondNumber}
												onChange={onChangeFunction}
											/>
										</td>
										<td>
											<input
												type="number"
												value={second}
												name="second"
												min={minTimeNumber}
												max={maxMinuteSecondNumber}
												onChange={onChangeFunction}
											/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default TimestampsAutomation
