import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { useSeasonWinner } from '../../utils/hooks/dashboardInputs/useSeasonWinner'

function SeasonWinnerElement(): FunctionComponentElement<typeof CardWrapper> {
	const [userId, updateSeasonWinnerState] = useSeasonWinner()

	const onUserIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateSeasonWinnerState(value)
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Season Winner</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>PlayFab ID</label>
					<CardInput type="text" value={userId} isValid={true} onChange={onUserIdChange} />
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default SeasonWinnerElement
