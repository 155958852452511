import dayjs from 'dayjs'
import { useCallback } from 'react'
import { Game } from '../../../config/enums'
import type { StateTree } from '../../../redux/interfaces'
import type { GetScheduledShowInformation } from '../../../redux/studioCommand/interfaces/GetScheduledShowInformation'
import type { ShowInformation } from '../../../redux/studioCommand/interfaces/ShowInformation'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { useAppSelector } from '..'
import { useAnalyticsCuePoints } from '../dashboardInputs/useAnalyticsCuePoints'
import { useBaseGameSettings } from '../dashboardInputs/useBaseGameSettings'
import { useBeatGuestMessagesState } from '../dashboardInputs/useBeatGuestMessages'
import { useBlockbusterSettings } from '../dashboardInputs/useBlockbusterSettings'
import { useFeaturedGame } from '../dashboardInputs/useFeaturedGame'
import { useGuestInfoUpdate } from '../dashboardInputs/useGuestInfoUpdate'
import { useNextDailyGoalsState } from '../dashboardInputs/useNextDailyGoals'
import { useNextMonthlyGoalsState } from '../dashboardInputs/useNextMonthlyGoals'
import { useNextShowDateTime } from '../dashboardInputs/useNextShowTime'
import { useNextWeeklyGoalsState } from '../dashboardInputs/useNextWeeklyGoals'
import { usePrizesState } from '../dashboardInputs/usePrizes'
import { useSeasonWinner } from '../dashboardInputs/useSeasonWinner'
import { useTimestampAutomationSettings } from '../dashboardInputs/useTimestampAutomationSettings'
import { useVideoState } from '../dashboardInputs/useVideoState'
import { useLeaguesResetToggle } from './useLeaguesResetToggle'
import { useSeasonTransitionToggle } from './useSeasonTransitionToggle'
import { useShowType } from './useShowType'

export const useGetScheduledShow = (): (() => Promise<void>) => {
	const { selectedDate } = useAppSelector(({ showCalendar: { selectedDate } }: StateTree) => ({ selectedDate }))
	const [, , , , updateBeatGuestMessagesState] = useBeatGuestMessagesState()
	const [, updateBlockbusterSettings] = useBlockbusterSettings()
	const [, updateCentipedeSettings] = useBaseGameSettings(Game.Centipede)
	const [, updateBeatSettings] = useBaseGameSettings(Game.Beat)
	const [, updateRallySettings] = useBaseGameSettings(Game.Rally)
	const [, updateBreakoutSettings] = useBaseGameSettings(Game.Breakout)
	const [, , updateFeaturedGame] = useFeaturedGame()
	const [, , , , , , , updatePrizesState] = usePrizesState()
	const [, , , , updateVideoState] = useVideoState()
	const [, , updateTimestampAutomationSettingsState] = useTimestampAutomationSettings()
	const [, , updateAnalyticsCuePointsState] = useAnalyticsCuePoints()
	const [, updateNextDailyGoalsState] = useNextDailyGoalsState()
	const [nextWeeklyGoalsState, updateNextWeeklyGoalsState, toggleWeeklyGoalsReset] = useNextWeeklyGoalsState()
	const [nextMonthlyGoalsState, updateNextMonthlyGoalsState, toggleMonthlyGoalsReset] = useNextMonthlyGoalsState()
	const [, updateNextShowDateTimeState] = useNextShowDateTime()
	const [leaguesResetState, toggleLeaguesReset] = useLeaguesResetToggle()
	const [seasonTransitionState, toggleSeasonTransition] = useSeasonTransitionToggle()
	const [, updateGuestInfoState] = useGuestInfoUpdate()
	const [showTypeState, updateShowType] = useShowType()
	const [seasonWinnerIdState, updateSeasonWinnerState] = useSeasonWinner()

	const getScheduledShow = useCallback(async () => {
		const getScheduledShowInformationCommand: GetScheduledShowInformation = {
			command: 'getScheduledShowInformation',
			date: selectedDate,
		}
		const response = await sendCommandToStudio<ShowInformation>(getScheduledShowInformationCommand)
		if (!response) {
			console.error('Saved Show data was not successfully loaded! Check if it was saved in advance!')
			return
		}
		const {
			video,
			beatGuestMessages,
			prizes,
			timestampAutomationSettings,
			analyticsCuePoints,
			featuredGame,
			gameSettingsInputs: {
				[Game.Blockbuster]: blockbusterSettings,
				[Game.Centipede]: centipedeSettings,
				[Game.Beat]: beatSettings,
				[Game.Rally]: rallySettings,
				[Game.Breakout]: breakoutSettings,
			},
			nextGoalsInputs: { nextDailyGoals, nextWeeklyGoals, nextMonthlyGoals },
			nextShowTimeStamp,
			leaguesReset,
			seasonTransition,
			showType,
			seasonWinnerUserId,
			nextGuestPosterInfo,
		} = response

		updateVideoState(video)
		updateBeatGuestMessagesState(beatGuestMessages)
		updateFeaturedGame(featuredGame)
		updatePrizesState(prizes)
		updateTimestampAutomationSettingsState(timestampAutomationSettings)
		if (analyticsCuePoints) updateAnalyticsCuePointsState(analyticsCuePoints)
		updateBlockbusterSettings(blockbusterSettings)
		updateCentipedeSettings(centipedeSettings)
		updateBeatSettings(beatSettings)
		updateRallySettings(rallySettings)
		updateBreakoutSettings(breakoutSettings)
		updateNextShowDateTimeState({
			date: dayjs(nextShowTimeStamp).format('YYYY-MM-DD'),
			time: dayjs(nextShowTimeStamp).format('HH:mm'),
		})
		if (nextDailyGoals) updateNextDailyGoalsState(nextDailyGoals)
		if (nextWeeklyGoals) {
			updateNextWeeklyGoalsState(nextWeeklyGoals)
			if (!nextWeeklyGoalsState.reset) toggleWeeklyGoalsReset()
		}
		if (nextMonthlyGoals) {
			updateNextMonthlyGoalsState(nextMonthlyGoals)
			if (!nextMonthlyGoalsState.reset) toggleMonthlyGoalsReset()
		}

		if (showType !== showTypeState.showType) {
			updateShowType(showType)
		}

		if (leaguesReset === undefined) {
			toggleLeaguesReset(false) // when loading a show which was saved before the introduction of the leagues reset, disable the leagues reset
		} else if (leaguesResetState.leaguesResetStatus !== leaguesReset) {
			toggleLeaguesReset()
		}

		if (seasonTransitionState.seasonTransitionStatus !== seasonTransition) {
			toggleSeasonTransition()

			if (seasonTransition) {
				if (seasonWinnerUserId !== seasonWinnerIdState) updateSeasonWinnerState(seasonWinnerUserId)
			}
		}

		if (nextGuestPosterInfo) {
			updateGuestInfoState(nextGuestPosterInfo)
		}

		console.info('Saved Show data was succesfully loaded!')
	}, [
		updateBeatGuestMessagesState,
		updateFeaturedGame,
		updatePrizesState,
		updateVideoState,
		updateTimestampAutomationSettingsState,
		updateAnalyticsCuePointsState,
		selectedDate,
		nextMonthlyGoalsState.reset,
		nextWeeklyGoalsState.reset,
		seasonTransitionState.seasonTransitionStatus,
		showTypeState.showType,
		toggleMonthlyGoalsReset,
		toggleSeasonTransition,
		updateShowType,
		updateSeasonWinnerState,
		toggleWeeklyGoalsReset,
		updateBeatSettings,
		updateBlockbusterSettings,
		updateCentipedeSettings,
		updateRallySettings,
		updateBreakoutSettings,
		updateNextDailyGoalsState,
		updateNextMonthlyGoalsState,
		updateNextShowDateTimeState,
		updateNextWeeklyGoalsState,
	])

	return getScheduledShow
}
