import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import updateNextShowTime from '../../../redux/studioCommand/actions/updateNextShowTime'
import type { UpdateNextShowTimeCommandState } from '../../../redux/studioCommand/interfaces/reducers/UpdateNextShowTimeCommandState'
import { useAppDispatch, useAppSelector, useAppStore } from '..'
import { useNextShowTimeValidation } from '../validations/useNextShowTimeValidation'

type UseNextShowTimeUpdateCommand = [UpdateNextShowTimeCommandState, () => Promise<void>]

export const useNextShowTimeUpdateCommand = (): UseNextShowTimeUpdateCommand => {
	const nextShowTimeUpdateCommandState = useAppSelector(
		({ commands: { updateNextShowTime: updateNextShowTimeCommandState } }: StateTree) => updateNextShowTimeCommandState,
	)
	const dispatch = useAppDispatch()
	const { getState } = useAppStore()
	const sendUpdateNextShowTime = useCallback(() => updateNextShowTime()(dispatch, getState), [dispatch, getState])
	const validate = useNextShowTimeValidation()

	const overwriteConfirmation = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This will overwrite the countdown timer shown on the main screen. Are you sure you want to proceed?'

		return confirmDialog(confirmMessage)
	}

	const sendUpdateWithValidation = async (): Promise<void> => {
		const isValid = validate()

		if (!isValid) {
			console.error('Invalid date/time')
			return
		}

		if (overwriteConfirmation()) {
			await sendUpdateNextShowTime()
		}
	}

	return [nextShowTimeUpdateCommandState, sendUpdateWithValidation]
}
