import type { PickWinnersResult } from '../../redux/studioCommand/interfaces/PickWinnersResult'

export const showPickWinnersTable = ({ coLuckyWinner, liveShowWinners, randomWinner, tournamentsWinners }: PickWinnersResult): void => {
	Object.entries(tournamentsWinners).forEach(([gameName, winners]) => {
		const table = Object.fromEntries(
			winners.map(({ DisplayName, PlayFabId, StatValue }, index) => [
				index + 1,
				{
					Player: DisplayName || 'PlayerOne',
					Score: StatValue?.toLocaleString() || 'No value',
					'Playfab Id': PlayFabId || 'No PlayFabId',
				},
			]),
		)
		console.info(`${gameName} Champion and Top 10 Winners`)
		console.table(table)
	})

	const liveShowWinnersTable = Object.fromEntries(
		liveShowWinners.map((winner, index) => [
			index + 1,
			{
				Player: winner?.DisplayName || 'No name',
				'Playfab Id': winner?.PlayFabId,
			},
		]),
	)
	console.info('Beat-the-guest winners')
	console.table(liveShowWinnersTable)

	console.table({
		'Lucky Winner': {
			Player: randomWinner?.DisplayName || 'No name',
			'Playfab Id': randomWinner?.PlayFabId,
		},
	})
	console.table({
		'Co-Lucky Winner': {
			Player: coLuckyWinner?.DisplayName || 'No name',
			'Playfab Id': coLuckyWinner?.PlayFabId,
		},
	})
}
