import type { WarmUpActions } from '../interfaces/actions/WarmUpActions'
import type { WarmUpState } from '../interfaces/reducers/WarmUpState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: WarmUpState = {
	isLoading: false,
	responseTime: 0,
}

export default function warmUp(state = initialState, action: WarmUpActions): WarmUpState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.WARMUP_COMMAND_START: {
			const beforeTime = new Date().getTime()
			return { ...initialState, isLoading: true, responseTime: beforeTime }
		}
		case REDUX_STUDIO_COMMAND_TYPES.WARMUP_COMMAND_FAILURE:
			console.error('WARMUP_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.WARMUP_COMMAND_SUCCESS: {
			const afterTime = new Date().getTime()
			const { responseTime } = state
			const newResponseTime = afterTime - responseTime
			console.log(`Warm Up, took: ${newResponseTime} ms`)
			return { ...initialState, responseTime: newResponseTime }
		}
		default:
			return state
	}
}
