import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../redux/interfaces'
import { listSavedShowDays } from '../../redux/showCalendar/actions/listSavedShowsDays'
import updateShowCalendarState from '../../redux/showCalendar/actions/updateShowCalendarState'
import type { ShowCalendarState } from '../../redux/showCalendar/interfaces/reducers/ShowCalendarState'
import { getDay } from '../getDay'
import { getMonth } from '../getMonth'
import { useAppDispatch, useAppSelector } from '.'

type UseShowCalendar = [ShowCalendarState, (newDate: Date) => void, (newDate: Date) => void]

export const useShowCalendar = (): UseShowCalendar => {
	const dispatch = useAppDispatch()
	const showCalendarState = useAppSelector(({ showCalendar }: StateTree) => showCalendar)
	const onMonthViewChange = useCallback((newDate: Date) => updateShowCalendarState({ viewedMonth: getMonth(newDate) })(dispatch), [dispatch])
	const onDateChange = useCallback(
		(newDate: Date) => updateShowCalendarState({ selectedDate: getDay(newDate), viewedMonth: getMonth(newDate) })(dispatch),
		[dispatch],
	)
	const listSavedDays = useCallback((month: string) => listSavedShowDays(month)(dispatch), [dispatch])

	const { viewedMonth } = showCalendarState
	useEffect(() => {
		listSavedDays(viewedMonth)
	}, [listSavedDays, viewedMonth])

	return [showCalendarState, onMonthViewChange, onDateChange]
}
