import styled from 'styled-components'

const Column = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
	align-items: center;
	justify-content: center;
`

export default Column
