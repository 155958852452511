import type { FeatureFlagsState } from '../interfaces/FeatureFlagsState'
import type { FeatureFlagsStateActions } from '../interfaces/FeatureFlagsStateActions'
import { REDUX_FEATURE_FLAGS_TYPES } from '../types'

export const initialFeatureFlagsState: FeatureFlagsState = {
	data: {},
	isLoading: false,
}

export default function featureFlagsState(state = initialFeatureFlagsState, action: FeatureFlagsStateActions): FeatureFlagsState {
	switch (action.type) {
		case REDUX_FEATURE_FLAGS_TYPES.FEATURE_FLAGS_FETCH_START: {
			return { ...initialFeatureFlagsState, isLoading: true }
		}
		case REDUX_FEATURE_FLAGS_TYPES.FEATURE_FLAGS_FETCH_FAILURE: {
			return initialFeatureFlagsState
		}
		case REDUX_FEATURE_FLAGS_TYPES.FEATURE_FLAGS_FETCH_SUCCESS: {
			return { ...initialFeatureFlagsState, data: action.payload }
		}
		default:
			return state
	}
}
