import { useCallback } from 'react'
import nextDailyGoalsStateUpdate from '../../../redux/dashboardInputs/actions/nextDailyGoalsState'
import type { NextDailyGoalsState } from '../../../redux/dashboardInputs/interfaces/goals/daily/NextDailyGoalsState'
import type { NextDailyGoalsUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/goals/daily/NextDailyGoalsUpdateActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseNextDailyGoalsState = [NextDailyGoalsState, (props: NextDailyGoalsUpdateActionProps) => void]

export const useNextDailyGoalsState = (): UseNextDailyGoalsState => {
	const { nextDailyGoals } = useAppSelector(({ dashboardInputs: { nextDailyGoals } }: StateTree) => ({ nextDailyGoals }))
	const dispatch = useAppDispatch()
	const updateNextDailyGoalsState = useCallback((props: NextDailyGoalsUpdateActionProps) => nextDailyGoalsStateUpdate(props)(dispatch), [dispatch])

	return [nextDailyGoals, updateNextDailyGoalsState]
}
