import type { OverwriteDefaultValuesActions } from '../interfaces/actions/OverwriteDefaultValuesActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function overwriteDefaultValues(state = initialState, action: OverwriteDefaultValuesActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_DEFAULT_VALUES_COMMAND_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_DEFAULT_VALUES_COMMAND_FAILURE:
			console.error('UPDATE_DEFAULT_VALUES_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.OVERWRITE_DEFAULT_VALUES_COMMAND_SUCCESS:
			console.log('UPDATE_DEFAULT_VALUES_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
