import type { VerifyJWTActions } from '../interfaces/actions/verifyJWT/verifyJWTActions'
import type { TokenState } from '../interfaces/reducers/TokenState'
import { REDUX_AUTH_TYPES } from '../types'

const initialState: TokenState = {
	isLoading: false,
	jwt: null,
	studioKey: null,
	tokenExpirationTime: null,
	userName: null,
}

export default function verifyJWT(state = initialState, action: VerifyJWTActions): TokenState {
	switch (action.type) {
		case REDUX_AUTH_TYPES.VERIFY_JWT_START:
			return { ...state, isLoading: true }
		case REDUX_AUTH_TYPES.VERIFY_JWT_FAILURE:
			return { ...state, isLoading: false }
		case REDUX_AUTH_TYPES.VERIFY_JWT_SUCCESS: {
			const { payload: jwt, studioKey } = action
			const [, data] = jwt.split('.')
			const { exp: tokenExpirationTime, DisplayName: userName } = JSON.parse(atob(data))

			return {
				...initialState,
				jwt,
				studioKey,
				tokenExpirationTime,
				userName,
			}
		}
		default:
			return state
	}
}
