import { useCallback } from 'react'
import { chatBanPlayer } from '../../redux/chatBans/actions/chatBanPlayer'
import type { BannedPlayer } from '../../redux/chatBans/interfaces/BannedPlayer'
import type { StateTree } from '../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '.'

type UseChatBanPlayer = [string[], (player: BannedPlayer) => void]

export const useChatBanPlayer = (): UseChatBanPlayer => {
	const dispatch = useAppDispatch()
	const tryingToBan = useAppSelector(({ chatBans: { tryingToBan } }: StateTree) => tryingToBan)

	const chatBanPlayerCommand = useCallback((data: BannedPlayer) => chatBanPlayer(data)(dispatch), [dispatch])
	const handleBanClick = (player: BannedPlayer) => {
		const message = `Are you sure you want to ban ${player.name} (ID: ${player.playFabId})?`
		if (window.confirm(message)) {
			chatBanPlayerCommand(player)
		}
	}

	return [tryingToBan, handleBanClick]
}
