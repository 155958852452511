import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { TimeFrame } from '../../interfaces/TimeFrame'
import { useNextGoalsOverwriteCommand } from '../../utils/hooks/commands/useNextGoalsOverwriteCommand'
import { useNextDailyGoalsState } from '../../utils/hooks/dashboardInputs/useNextDailyGoals'
import { getOrdinal } from '../../utils/ordinal/getOrdinal'
import GoalSelect from '../GoalSelect'

function NextDailyGoals(): FunctionComponentElement<typeof CardWrapper> {
	const [nextDailyGoals, updateNextDailyGoals] = useNextDailyGoalsState()
	const [nextGoalsOverwriteState, overwriteNextGoalsCommand] = useNextGoalsOverwriteCommand(TimeFrame.Daily)

	const onChangeNextDailyGoals = (event: ChangeEvent<HTMLSelectElement>, goalNumber: number) => {
		const {
			currentTarget: { value },
		} = event

		const newDailyGoals = { ...nextDailyGoals.value, [goalNumber]: JSON.parse(value) }

		updateNextDailyGoals({
			...newDailyGoals,
		})
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Daily Goals</CardHeading>
			</CardHeader>
			<CardBody>
				{Array(3)
					.fill('')
					.map((_, index) => {
						const rootKey = `nextDailyGoals-${index}`
						return (
							<CardFieldSet key={rootKey}>
								<label>{getOrdinal(index + 1) + ': '}</label>
								<GoalSelect
									timeFrame={TimeFrame.Daily}
									value={nextDailyGoals.value}
									goalNumber={index}
									rootKey={rootKey}
									onChange={onChangeNextDailyGoals}
								/>
							</CardFieldSet>
						)
					})}
				<CardFieldSet>
					<CardButton type="button" disabled={nextGoalsOverwriteState.isUpdating} onClick={overwriteNextGoalsCommand}>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default NextDailyGoals
