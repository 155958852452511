import type { UpdateNextShowTimeActions } from '../interfaces/actions/UpdateNextShowTimeActions'
import type { UpdateNextShowTimeCommandState } from '../interfaces/reducers/UpdateNextShowTimeCommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: UpdateNextShowTimeCommandState = {
	isUpdating: false,
}

export default function updateNextShowTime(state = initialState, action: UpdateNextShowTimeActions): UpdateNextShowTimeCommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_SHOW_TIME_COMMAND_START:
			return { ...initialState, isUpdating: true }
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_SHOW_TIME_COMMAND_FAILURE:
			console.error('UPDATE_NEXT_SHOW_TIME_COMMAND_FAILURE')
			return initialState
		case REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEXT_SHOW_TIME_COMMAND_SUCCESS:
			console.log('UPDATE_NEXT_SHOW_TIME_COMMAND_SUCCESS')
			return initialState
		default:
			return state
	}
}
