import type { ChangeEvent, FunctionComponentElement, KeyboardEvent } from 'react'
import React, { useState } from 'react'
import { CardBody, CardFieldSet } from '../../containers/Card'
import { useSendWSMessage } from '../../utils/hooks/useSendWSMessage'
import ChatMessages from '../ChatMessages'
import {
	LiveChatButton,
	LiveChatContainer,
	LiveChatFollowNewMessagesToggle,
	LiveChatHeader,
	LiveChatHeading,
	LiveChatInput,
	LiveChatWrapper,
} from './LiveChatContainer'

function LiveChat(): FunctionComponentElement<typeof LiveChatContainer> {
	const [isActive, setIsActive] = useState(false)
	const [followNewMessages, setFollowNewMessages] = useState(true)
	const [inputMessage, setInputMessage] = useState('')
	const [messages, addMessage] = useSendWSMessage()

	const toggleFollowNewMessages = () => {
		setFollowNewMessages(!followNewMessages)
	}

	const toggleChat = () => {
		if (!isActive) setFollowNewMessages(true)
		setIsActive(!isActive)
	}

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event

		setInputMessage(value)
	}

	const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
			key,
		} = event

		if (key !== 'Enter') {
			return
		}

		const isValid = value && value.length >= 0 && value.trim()

		if (isValid) {
			addMessage(value)
			setInputMessage('')
		}
	}

	return (
		<LiveChatContainer>
			{isActive && (
				<LiveChatWrapper>
					<LiveChatHeader onClick={toggleChat}>
						<LiveChatHeading>Live chat</LiveChatHeading>
					</LiveChatHeader>
					<CardBody>
						<ChatMessages messages={messages} followNewMessages={followNewMessages} />
						<CardFieldSet>
							<LiveChatInput
								type="text"
								placeholder="Type your message…"
								value={inputMessage}
								onChange={onChange}
								onKeyDown={onKeyDown}
								isValid={true}
								autoFocus
							/>
						</CardFieldSet>
					</CardBody>
					<LiveChatFollowNewMessagesToggle label="Follow new messages" status={followNewMessages} onChange={toggleFollowNewMessages} />
				</LiveChatWrapper>
			)}
			<LiveChatButton onClick={toggleChat}>Chat</LiveChatButton>
		</LiveChatContainer>
	)
}

export default LiveChat
