import { send } from '@giantmachines/redux-websocket/dist'
import type { Dispatch } from 'redux'

interface SendChatMessage {
	event: 'sendMessage'
	content: string
}

export const sendChatMessage =
	(content: string) =>
	(dispatch: Dispatch): void => {
		const message: SendChatMessage = {
			event: 'sendMessage',
			content,
		}

		dispatch(send(message))
	}
