import styled from 'styled-components'

interface AuthContainerProps {
	app_env: string
}

export const AuthContainer = styled.div<AuthContainerProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-size: 3em;
	background: ${({ app_env }: AuthContainerProps) => (app_env === 'prod' ? 'orangered' : 'inherit')};

	input,
	button {
		font-size: inherit;
	}

	input,
	select {
		margin: 0 20px;
		padding: 0 5px;
	}

	select {
		font-size: 0.4em;
	}

	button {
		padding: 10px;
		box-shadow: 0 5px #999;
	}

	button:hover,
	button:focus {
		background-color: darkgrey;
	}

	button:active {
		background-color: #3e8e41;
		box-shadow: 0 2px #666;
		transform: translateY(4px);
	}
`
