import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { ShowTopThreeFailureAction } from '../interfaces/actions/ShowTopThreeFailureAction'
import type { ShowTopThreeStartAction } from '../interfaces/actions/ShowTopThreeStartAction'
import type { ShowTopThreeSuccessAction } from '../interfaces/actions/ShowTopThreeSuccessAction'
import type { ShowTopThree } from '../interfaces/ShowTopThree'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const showTopThreeStart = (): ShowTopThreeStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_TOP_THREE_START }
}

const showTopThreeFailure = (): ShowTopThreeFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_TOP_THREE_FAILURE }
}

const showTopThreeSuccess = (): ShowTopThreeSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SHOW_TOP_THREE_SUCCESS }
}

export default function showTopThree() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(showTopThreeStart())

		try {
			const showTopThreeCommand: ShowTopThree = {
				command: 'showTopThree',
			}
			await sendCommandToStudio(showTopThreeCommand)
			dispatch(showTopThreeSuccess())
		} catch {
			dispatch(showTopThreeFailure())
		}
	}
}
