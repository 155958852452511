import { useCallback } from 'react'
import type { ShowType } from '../../../config/enums'
import type { StateTree } from '../../../redux/interfaces'
import { showTypeSelectAction } from '../../../redux/studioCommand/actions/showTypeSelect'
import type { ShowTypeSelectState } from '../../../redux/studioCommand/interfaces/reducers/ShowTypeSelectState'
import { useAppDispatch, useAppSelector } from '..'

type UseShowType = [ShowTypeSelectState, (showType: ShowType) => void]

export const useShowType = (): UseShowType => {
	const dispatch = useAppDispatch()
	const showType = useAppSelector(({ commands: { showType } }: StateTree) => showType)
	const updateShowType = useCallback(
		(showType: ShowType) => {
			dispatch(showTypeSelectAction(showType))
		},
		[dispatch, showType],
	)

	return [showType, updateShowType]
}
