import styled from 'styled-components'
import { liveChatButtonDistance, liveChatContainerBottom } from '../../config/styles/theme'
import { CardButton, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import Toggle from '../Toggle'

export const LiveChatContainer = styled.div`
	position: fixed;
	bottom: ${liveChatContainerBottom}px;
	right: ${liveChatButtonDistance + 45}px;
	color: white;
`

export const LiveChatWrapper = styled(CardWrapper)`
	width: 450px;
	background: darkslategray;
`

export const LiveChatHeader = styled(CardHeader)`
	cursor: pointer;
	padding: 5px 0;
`

export const LiveChatHeading = styled(CardHeading)`
	margin-top: 10px;
	margin-bottom: 5px;
`

export const LiveChatButton = styled(CardButton)`
	background: darkslategray;
	width: 80px;
	position: fixed;
	color: #fff;
	bottom: ${liveChatButtonDistance}px;
	right: ${liveChatButtonDistance}px;
`

export const LiveChatInput = styled(CardInput)`
	text-align: left;

	&:focus {
		border-bottom-color: transparent;
		outline: 0;
	}
`

export const LiveChatFollowNewMessagesToggle = styled(Toggle)`
	flex-direction: row;
	justify-content: flex-end;
	padding: 0 32px 0 0;
	> label {
		margin: 0 5px;
	}
`
