import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StopShowFailureAction, StopShowStartAction, StopShowSuccessAction } from '../interfaces/actions/StopShowActions'
import type { StopShow } from '../interfaces/StopShow'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const stopShowStart = (): StopShowStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_START }
}

const stopShowFailure = (): StopShowFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_FAILURE }
}

const stopShowSuccess = (): StopShowSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.STOP_SHOW_SUCCESS }
}

export default function stopShow() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(stopShowStart())

		try {
			const stopShowCommand: StopShow = {
				command: 'stopShow',
			}

			await sendCommandToStudio(stopShowCommand)

			dispatch(stopShowSuccess())
		} catch {
			dispatch(stopShowFailure())
		}
	}
}
