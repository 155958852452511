import { GameStage } from '../../../config/enums'
import type { BeatActions } from '../interfaces/GameSettingsActions'
import type { BaseGameSettingsState } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const levelInitialState = {
	value: 1,
	isValid: true,
}

export const stageInitialState = {
	value: { name: GameStage.FutureCity, beatified: true },
	isValid: true,
}

const initialState: BaseGameSettingsState = {
	level: levelInitialState,
	stage: stageInitialState,
}

export default function centipedeSettingsState(state = initialState, action: BeatActions): BaseGameSettingsState {
	switch (action.type) {
		case REDUX_DASHBOARD_INPUT_TYPES.BEAT_UPDATE: {
			const updatedValues: BaseGameSettingsState = {
				level: { ...state.level, value: action.props.level },
				stage: { ...state.stage, value: action.props.stage },
			}
			return { ...state, ...updatedValues }
		}
		case REDUX_DASHBOARD_INPUT_TYPES.BEAT_VALID: {
			const updatedValues: BaseGameSettingsState = {
				level: { ...state.level, isValid: action.props.isLevelValid },
				stage: { ...state.stage, isValid: action.props.isStageValid },
			}
			return { ...state, ...updatedValues }
		}
		default:
			return state
	}
}
