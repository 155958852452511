import type { ChangeEvent, FunctionComponentElement } from 'react'
import React from 'react'
import { countries } from '../../config/app'

interface Props {
	value: string
	keyValue: string
	onChange: (event: ChangeEvent<HTMLSelectElement>) => void
}

function CountrySelect({ value, keyValue, onChange }: Props): FunctionComponentElement<typeof HTMLElement> {
	return (
		<select name={keyValue} id={keyValue} value={value} onChange={onChange}>
			{Object.entries(countries).map(([key, { name }], index) => (
				<option key={`${keyValue}-${index}`} value={key}>
					{name}
				</option>
			))}
		</select>
	)
}

export default CountrySelect
