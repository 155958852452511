import type { Dispatch } from 'redux'
import type { DisplayBeatGuestToggleAction } from '../interfaces/actions/DisplayBeatGuestToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const displayBeatGuestToggle = (isAvailable: boolean): DisplayBeatGuestToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_BEAT_GUEST_TOGGLE, isAvailable }
}

export default function toggleDisplayBeatGuest(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(displayBeatGuestToggle(isAvailable))
	}
}
