import { useCallback, useState } from 'react'
import featuredGameState from '../../../redux/dashboardInputs/actions/gameSettingsState'
import type { FeaturedGame } from '../../../redux/dashboardInputs/interfaces/GamesSettings'
import type { StateTree } from '../../../redux/interfaces'
import type { OverwriteFeaturedGameCommand } from '../../../redux/studioCommand/interfaces/OverwriteFeaturedGameCommand'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { useAppDispatch, useAppSelector } from '..'

interface FeaturedGameCommandState {
	isUpdating: boolean
	error: null | string
}

type UseFeaturedGame = [FeaturedGame, FeaturedGameCommandState, (props: FeaturedGame) => void, () => Promise<void>]

export const useFeaturedGame = (): UseFeaturedGame => {
	const featuredGame = useAppSelector(({ dashboardInputs: { featuredGame } }: StateTree) => featuredGame)
	const dispatch = useAppDispatch()
	const updateFeaturedGameState = useCallback((props: FeaturedGame) => featuredGameState(props)(dispatch), [dispatch])

	const [featuredGameCommandState, setFeaturedGameCommandState] = useState<FeaturedGameCommandState>({
		isUpdating: false,
		error: null,
	})

	const sendOverwriteFeaturedGameCommand = async () => {
		setFeaturedGameCommandState({
			...featuredGameCommandState,
			isUpdating: true,
		})
		try {
			const overwriteFeaturedGameCommand: OverwriteFeaturedGameCommand = {
				command: 'overwriteNextArcadeGame',
				gameName: featuredGame,
			}

			await sendCommandToStudio(overwriteFeaturedGameCommand)
			setFeaturedGameCommandState({
				...featuredGameCommandState,
				isUpdating: false,
			})
		} catch (error) {
			setFeaturedGameCommandState({
				...featuredGameCommandState,
				error: String(error),
			})
		}
	}

	const confirmSendOverwrite = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This will overwrite the current Featured Game on the ongoing 24H Tournament. Are you sure you want to proceed?'

		return confirmDialog(confirmMessage)
	}

	const sendOverwriteWithValidation = async (): Promise<void> => {
		if (confirmSendOverwrite()) {
			await sendOverwriteFeaturedGameCommand()
		}
	}
	return [featuredGame, featuredGameCommandState, updateFeaturedGameState, sendOverwriteWithValidation]
}
