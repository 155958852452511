import { useCallback } from 'react'
import pickSeasonWinner from '../../../redux/studioCommand/actions/pickSeasonWinner'
import { useAppDispatch } from '..'
import type { PromiseVoid } from '../useCommands'

type UsePickSeasonWinner = PromiseVoid

export default function usePickSeasonWinner(): UsePickSeasonWinner {
	const dispatch = useAppDispatch()

	const pickSeasonWinnerCommand = useCallback(() => pickSeasonWinner()(dispatch), [dispatch])

	const sendPickSeasonWinner = async (): Promise<void> => {
		const { confirm: confirmDialog } = window
		const confirmMessage =
			'This is a read only operation but with high resource/time complexity, should be used with caution. Are you sure you want to proceed?'

		if (confirmDialog(confirmMessage)) {
			await pickSeasonWinnerCommand()
		}
	}

	return sendPickSeasonWinner
}
