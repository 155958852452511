import type { Dispatch } from 'redux'
import type { FeatureGameUpdateAction } from '../interfaces/GameSettingsActions'
import type { FeaturedGame } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

export const featuredGameUpdate = (props: FeaturedGame): FeatureGameUpdateAction => {
	return { type: REDUX_DASHBOARD_INPUT_TYPES.FEATURED_GAME_UPDATE, props }
}

export default function featuredGameState(props: FeaturedGame) {
	return (dispatch: Dispatch): void => {
		dispatch(featuredGameUpdate(props))
	}
}
