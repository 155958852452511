export const darkTheme = {
	body: '#363537',
	text: '#FAFAFA',
	boxShadow: '0 0 20px rgba(0, 0, 0, 1), 0 0px 40px rgba(0, 0, 0, 0.08)',
	delimiter: '#FFF',
}

export const liveChatButtonDistance = 20
export const liveChatContainerBottom = liveChatButtonDistance + 30
export const commandsMenuWidth = 150
