import { countries } from '../config/app'
import { store } from '../redux/store'

export function getSelectedRegionTimeZone(): string {
	const { getState } = store
	const {
		country: { value: country },
	} = getState()

	return countries[country].timeZone
}
