import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { WarmUpFailureAction } from '../interfaces/actions/WarmUpFailureAction'
import type { WarmUpStartAction } from '../interfaces/actions/WarmUpStartAction'
import type { WarmUpSuccessAction } from '../interfaces/actions/WarmUpSuccessAction'
import type { WarmUp } from '../interfaces/WarmUp'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const warmUpStart = (): WarmUpStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.WARMUP_COMMAND_START }
}

const warmUpFailure = (): WarmUpFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.WARMUP_COMMAND_FAILURE }
}

const warmUpSuccess = (payload: string): WarmUpSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.WARMUP_COMMAND_SUCCESS, payload }
}

export const warmUp =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(warmUpStart())

		try {
			const ping: WarmUp = { command: 'ping' }
			const result = await sendCommandToStudio<string>(ping)

			dispatch(warmUpSuccess(result))
		} catch {
			dispatch(warmUpFailure())
		}
	}
