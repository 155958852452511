import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { NewsArticles } from '../../news/interfaces/NewsState'
import type { UpdateNewsFailureAction } from '../interfaces/actions/UpdateNewsFailureAction'
import type { UpdateNewsStartAction } from '../interfaces/actions/UpdateNewsStartAction'
import type { UpdateNewsSuccessAction } from '../interfaces/actions/UpdateNewsSuccessAction'
import type { UpdateNews } from '../interfaces/UpdateNews'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const updateNewsStart = (): UpdateNewsStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEWS_COMMAND_START }
}

export const updateNewsFailure = (): UpdateNewsFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEWS_COMMAND_FAILURE }
}

const updateNewsSuccess = (): UpdateNewsSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_NEWS_COMMAND_SUCCESS }
}

export default function updateNews() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(updateNewsStart())

		try {
			const {
				news: { data },
			} = getState()

			if (Object.keys(data).length === 0) {
				return
			}

			const updateNewsCommand: UpdateNews = {
				command: 'updateNews',
				news: data as NewsArticles,
			}
			await sendCommandToStudio(updateNewsCommand)

			dispatch(updateNewsSuccess())
		} catch {
			dispatch(updateNewsFailure())
		}
	}
}
