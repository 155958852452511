import { useCallback } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import { seasonTransitionIsActiveToggleAction, seasonTransitionToggleAction } from '../../../redux/studioCommand/actions/seasonTransitionToggle'
import type { SeasonTransitionToggleState } from '../../../redux/studioCommand/interfaces/reducers/SeasonTransitionToggleState'
import { useAppDispatch, useAppSelector } from '..'

type UseSeasonTransitionToggle = [SeasonTransitionToggleState, (seasonTransitionStatus?: boolean) => void, (isAvailable: boolean) => void]

export const useSeasonTransitionToggle = (): UseSeasonTransitionToggle => {
	const dispatch = useAppDispatch()
	const seasonTransition = useAppSelector(({ commands: { seasonTransition } }: StateTree) => seasonTransition)
	const toggleSeasonTransition = useCallback(
		(seasonTransitionStatus?: boolean) => {
			dispatch(seasonTransitionToggleAction(seasonTransitionStatus ?? !seasonTransition.seasonTransitionStatus))
		},
		[dispatch, seasonTransition],
	)
	const toggleSeasonTransitionAvailability = useCallback(
		(isAvailable: boolean) => {
			dispatch(seasonTransitionIsActiveToggleAction(isAvailable))
		},
		[dispatch],
	)

	const sendToggleSeasonTransition = (seasonTransitionStatus?: boolean): void => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This setting should be used when starting a NEW SEASON. Are you sure you want to proceed?'

		if (seasonTransition.seasonTransitionStatus || confirmDialog(confirmMessage)) {
			toggleSeasonTransition(seasonTransitionStatus)
		}
	}

	return [seasonTransition, sendToggleSeasonTransition, toggleSeasonTransitionAvailability]
}
