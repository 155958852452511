import { useCallback } from 'react'
import nextMonthlyGoalsStateUpdate, { nextMonthlyGoalReset } from '../../../redux/dashboardInputs/actions/nextMonthlyGoalsState'
import type { NextMonthlyGoalsState } from '../../../redux/dashboardInputs/interfaces/goals/monthly/NextMonthlyGoalsState'
import type { NextMonthlyGoalsUpdateActionProps } from '../../../redux/dashboardInputs/interfaces/goals/monthly/NextMonthlyGoalsUpdateActionProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseNextMonthlyGoalsState = [NextMonthlyGoalsState, (props: NextMonthlyGoalsUpdateActionProps) => void, () => void]

export const useNextMonthlyGoalsState = (): UseNextMonthlyGoalsState => {
	const { nextMonthlyGoals } = useAppSelector(({ dashboardInputs: { nextMonthlyGoals } }: StateTree) => ({ nextMonthlyGoals }))
	const dispatch = useAppDispatch()
	const updateNextMonthlyGoalsState = useCallback(
		(props: NextMonthlyGoalsUpdateActionProps) => nextMonthlyGoalsStateUpdate(props)(dispatch),
		[dispatch],
	)

	const toggleMonthlyReset = () => {
		dispatch(nextMonthlyGoalReset())
	}

	return [nextMonthlyGoals, updateNextMonthlyGoalsState, toggleMonthlyReset]
}
