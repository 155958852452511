import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React, { useState } from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { sendCommandToStudio } from '../../services/api/serverless'
import Toggle from '../Toggle'

function MaintenanceMode(): FunctionComponentElement<typeof CardWrapper> {
	const [toggleStatus, setToggleStatus] = useState<boolean>(false)
	const [message, setMessage] = useState('')
	const [isUpdating, setIsUpdating] = useState<boolean>(false)

	const onToggleChange = (): void => {
		setToggleStatus(!toggleStatus)
	}

	const onMessageChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		setMessage(value)
	}

	const confirmUpdate = (): boolean => {
		const { confirm: confirmDialog } = window
		const confirmMessage = 'This action can block players from accessing the app. Are you sure you want to proceed?'

		return confirmDialog(confirmMessage)
	}

	const onUpdateClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()

		if (!confirmUpdate()) {
			return
		}

		setIsUpdating(true)

		await updateAppStatus(toggleStatus, message)

		setIsUpdating(false)
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Maintenance Mode</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Status Message</label>
					<CardInput type="text" value={message} isValid={true} onChange={onMessageChange} />
				</CardFieldSet>
				<CardFieldSet>
					<Toggle label={toggleStatus ? 'Maintenance' : 'OK'} status={toggleStatus} onChange={onToggleChange} activeColor="darkorange" />
					<CardButton type="button" disabled={isUpdating} onClick={onUpdateClick}>
						Update
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

async function updateAppStatus(underMaintenance: boolean, message: string): Promise<void> {
	try {
		await sendCommandToStudio({ command: 'updateAppStatus', underMaintenance, message })
		console.info('App Status - updated successfully!')
	} catch {
		console.error('Error updating app status')
	}
}

export default MaintenanceMode
