import type { Dispatch } from 'redux'
import type { BlockbusterSettingsUpdateAction, BlockbusterSettingsValidAction } from '../interfaces/GameSettingsActions'
import type { BlockbusterSettingsProps, BlockbusterSettingsValidActionProps } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const blockbusterSettingsStateUpdate = (props: BlockbusterSettingsProps): BlockbusterSettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BLOCKBUSTER_UPDATE,
		props,
	}
}

export const blockbusterSettingsStateValid = (props: BlockbusterSettingsValidActionProps): BlockbusterSettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.BLOCKBUSTER_VALID,
		props,
	}
}

export default function blockbusterSettingsState(props: BlockbusterSettingsProps) {
	return (dispatch: Dispatch): void => {
		dispatch(blockbusterSettingsStateUpdate(props))
	}
}
