import { showPickWinnersTable } from '../../../utils/showWinnersTable/showPickWinnersTable'
import type { PickWinnersActions } from '../interfaces/actions/PickWinnersActions'
import type { PickWinnersState } from '../interfaces/PickWinnersState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: PickWinnersState = {
	isLoading: false,
	isAvailable: true,
	result: null,
}

export default function pickWinners(state = initialState, action: PickWinnersActions): PickWinnersState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_START:
			return { ...state, isLoading: true, result: null }
		case REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_FAILURE:
			console.error('PICK_WINNERS_FAILURE')
			return { ...state, isLoading: false, result: null }
		case REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_SUCCESS:
			console.log('PICK_WINNERS_SUCCESS')
			showPickWinnersTable(action.payload)
			return { ...state, isLoading: false, result: action.payload }
		case REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
