import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import { getShowInformation } from '../../../utils/getShowInformation'
import type { StateTree } from '../../interfaces'
import type { SaveShowInformationFailureAction } from '../interfaces/actions/SaveShowInformationFailureAction'
import type { SaveShowInformationStartAction } from '../interfaces/actions/SaveShowInformationStartAction'
import type { SaveShowInformationSuccessAction } from '../interfaces/actions/SaveShowInformationSuccessAction'
import type { SaveShowInformation } from '../interfaces/SaveShowInformation'
import type { ShowInformation } from '../interfaces/ShowInformation'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const saveShowInformationStart = (): SaveShowInformationStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SAVE_SHOW_INFORMATION_COMMAND_START }
}

export const saveShowInformationFailure = (): SaveShowInformationFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SAVE_SHOW_INFORMATION_COMMAND_FAILURE }
}

const saveShowInformationSuccess = (): SaveShowInformationSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.SAVE_SHOW_INFORMATION_COMMAND_SUCCESS }
}

export default function saveShowInformation() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(saveShowInformationStart())

		try {
			const showInformation: ShowInformation = getShowInformation(getState())

			const saveShowInformationCommand: SaveShowInformation = {
				command: 'setShowInformation',
				showInformation,
			}

			await sendCommandToStudio(saveShowInformationCommand)

			dispatch(saveShowInformationSuccess())
		} catch {
			dispatch(saveShowInformationFailure())
		}
	}
}
