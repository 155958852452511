import { useCallback } from 'react'
import guestInfoState from '../../../redux/dashboardInputs/actions/guestInfoState'
import type { GuestInfoState } from '../../../redux/dashboardInputs/interfaces/GuestInfoState'
import type { UpdateGuestInfoStateProps } from '../../../redux/dashboardInputs/interfaces/UpdateGuestInfoStateProps'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

type UseGuestInfoUpdate = [GuestInfoState, (props: UpdateGuestInfoStateProps) => void]

export const useGuestInfoUpdate = (): UseGuestInfoUpdate => {
	const { guestInfo } = useAppSelector(({ dashboardInputs: { guestInfo } }: StateTree) => ({
		guestInfo,
	}))
	const dispatch = useAppDispatch()
	const sendUpdateGuestInfoState = useCallback((props: UpdateGuestInfoStateProps) => guestInfoState(props)(dispatch), [dispatch])

	return [guestInfo, sendUpdateGuestInfoState]
}
