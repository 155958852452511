import { disconnect } from '@giantmachines/redux-websocket'
import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../redux/interfaces'
import connect from '../../services/websocket'
import { useAppDispatch, useAppSelector } from '.'
import { useToken } from './useToken'

export const useWebSocket = (): void => {
	const connected = useAppSelector(({ wsMessage: { connected } }: StateTree) => connected)
	const dispatch = useAppDispatch()
	const wsConnect = useCallback((token: string) => connect(token)(dispatch), [dispatch])
	const wsDisconnect = useCallback(() => dispatch(disconnect as never), [dispatch])

	const token = useToken()

	useEffect(() => {
		if (!token || connected) {
			return
		}

		wsConnect(token)

		return (): void => {
			wsDisconnect()
		}
	}, [token, connected, wsConnect, wsDisconnect])
}
