import { captureException } from '@sentry/react'
import { appDomain, appEnv, isProduction, proxyApiUrl } from '../../config'
import { store } from '../../redux/store'
import validJSONParse from '../validJSONParse'

export class ServerError extends Error {
	response?: unknown

	constructor(message: string, response?: unknown) {
		super(message)

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ServerError)
		}

		this.name = 'ServerError'
		this.response = response
	}
}

const generateFetchConfig = (config: RequestInit): RequestInit => {
	return {
		...config,
		headers: {
			...(config && config.headers),
		},
	}
}

const apiUrl = (endpoint: string, api?: string): string => {
	const { getState } = store
	const {
		country: { value: country },
	} = getState()

	const serverless = isProduction ? `https://serverless.${country}.${appDomain}/${appEnv}/${endpoint}` : `${proxyApiUrl}/${endpoint}`

	const apis: { [key: string]: string } = {
		serverless,
	}

	return api ? apis[api] : serverless
}

export default async function request<T>(endpoint: string, config: RequestInit, api?: string): Promise<T> {
	const generatedConfig = generateFetchConfig(config)
	const url = apiUrl(endpoint, api)

	const result = await fetch(url, generatedConfig)

	let response = {}

	try {
		response = validJSONParse(await result.text())
	} catch (error) {
		if (error instanceof Error) {
			const errorMessage = `Response parsing error: ${error.message}`
			captureException(errorMessage)

			console.error(errorMessage)
			throw new ServerError(errorMessage)
		}
		throw error
	}

	if (!result.ok) {
		const errorMessage = `External api request result error: ${result.status}: ${result.statusText}`
		captureException(errorMessage)

		console.error(errorMessage)
		throw new ServerError(errorMessage, response)
	}

	return response as T
}
