import { useCallback, useEffect } from 'react'
import { chatUnbanPlayer } from '../../redux/chatBans/actions/chatUnbanPlayer'
import { getChatBannedPlayers } from '../../redux/chatBans/actions/getChatBannedPlayers'
import type { BannedPlayer } from '../../redux/chatBans/interfaces/BannedPlayer'
import type { ChatBansState } from '../../redux/chatBans/interfaces/ChatBansState'
import type { StateTree } from '../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '.'

type UseChatBans = [ChatBansState, () => void, (bannedPlayer: BannedPlayer) => void]

export const useChatBans = (): UseChatBans => {
	const dispatch = useAppDispatch()
	const chatBans = useAppSelector(({ chatBans }: StateTree) => chatBans)
	const requestGetChatBans = useCallback(() => getChatBannedPlayers()(dispatch), [dispatch])

	const chatUnbanPlayerCommand = useCallback((userId: string) => chatUnbanPlayer(userId)(dispatch), [dispatch])
	const handleUnbanClick = (bannedPlayer: BannedPlayer) => {
		const message = `Are you sure you want to unban ${bannedPlayer.name} (ID: ${bannedPlayer.playFabId})?`
		if (window.confirm(message)) {
			chatUnbanPlayerCommand(bannedPlayer.playFabId)
		}
	}

	useEffect(() => {
		requestGetChatBans()
	}, [requestGetChatBans])
	return [chatBans, requestGetChatBans, handleUnbanClick]
}
