import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { PrepareCelebrationFailureAction } from '../interfaces/actions/PrepareCelebrationFailureAction'
import type { PrepareCelebrationStartAction } from '../interfaces/actions/PrepareCelebrationStartAction'
import type { PrepareCelebrationSuccessAction } from '../interfaces/actions/PrepareCelebrationSuccessAction'
import type { PrepareCelebration } from '../interfaces/PrepareCelebration'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const prepareCelebrationStart = (): PrepareCelebrationStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_START }
}

const prepareCelebrationFailure = (): PrepareCelebrationFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_FAILURE }
}

const prepareCelebrationSuccess = (): PrepareCelebrationSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_SUCCESS }
}

export default function prepareCelebration() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(prepareCelebrationStart())

		try {
			const prepareCelebrationCommand: PrepareCelebration = {
				command: 'prepareCelebration',
			}
			await sendCommandToStudio<string>(prepareCelebrationCommand)
			dispatch(prepareCelebrationSuccess())
		} catch {
			dispatch(prepareCelebrationFailure())
		}
	}
}
