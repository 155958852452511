import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { useForceUpdateVersionCommand } from '../../utils/hooks/commands/useForceUpdateVersionCommand'
import { useForceUpdateVersionState } from '../../utils/hooks/dashboardInputs/useForceUpdateVersionState'
import type { Versions } from './Versions'

function ForceUpdateVersion(): FunctionComponentElement<typeof CardWrapper> {
	const [forceUpdateVersionState, forceUpdateVersionUpdateState] = useForceUpdateVersionState()
	const [forceUpdateVersionCommandState, forceUpdateCommand] = useForceUpdateVersionCommand()

	const getForceUpdateVersionValues = () => {
		const {
			android: { value: android },
			iOS: { value: iOS },
		} = forceUpdateVersionState

		return {
			android,
			iOS,
		}
	}

	const onChangeAndroidVersion = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		forceUpdateVersionUpdateState({
			...getForceUpdateVersionValues(),
			android: value,
		})
	}

	const onChangeIOSVersion = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		forceUpdateVersionUpdateState({
			...getForceUpdateVersionValues(),
			iOS: value,
		})
	}

	const onUpdateClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.stopPropagation()

		const versions: Versions = getForceUpdateVersionValues()

		const { android: minVersionAndroid, iOS: minVersionIos } = versions

		forceUpdateCommand({ minVersionAndroid, minVersionIos })
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Version Enforcement</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Android Version</label>
					<CardInput
						type="text"
						value={forceUpdateVersionState.android.value}
						onChange={onChangeAndroidVersion}
						isValid={forceUpdateVersionState.android.isValid}
						placeholder="1.0.0"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>iOS Version</label>
					<CardInput
						type="text"
						value={forceUpdateVersionState.iOS.value}
						onChange={onChangeIOSVersion}
						isValid={forceUpdateVersionState.iOS.isValid}
						placeholder="1.0.0"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<CardButton type="button" onClick={onUpdateClick} disabled={forceUpdateVersionCommandState.isUpdating}>
						Update
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default ForceUpdateVersion
