import styled from 'styled-components'

export const DashboardContainer = styled.div`
	flex: 1 0;
	padding: 0 15px;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
`
