import type { GetStepFunctionExecutionIdActions, GetStepFunctionExecutionIdState } from '../interfaces/GetStepFunctionExecutionId'
import { REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES } from '../types'

export const initialState: GetStepFunctionExecutionIdState = {
	isLoading: false,
	status: false,
}

export default function getStepFunctionExecutionId(state = initialState, action: GetStepFunctionExecutionIdActions): GetStepFunctionExecutionIdState {
	switch (action.type) {
		case REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES.GET_STEP_FUNCTION_EXECUTION_ID_START:
			return { ...state, isLoading: true }
		case REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES.GET_STEP_FUNCTION_EXECUTION_ID_FAILURE:
			console.error('GET_STEP_FUNCTION_EXECUTION_ID_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_GET_STEP_FUNCTION_EXECUTION_ID_TYPES.GET_STEP_FUNCTION_EXECUTION_ID_SUCCESS: {
			const { payload } = action

			const status = payload !== undefined

			return { ...initialState, status }
		}
		default:
			return state
	}
}
