import type { StartCountdownAndShowActions } from '../interfaces/actions/StartCountdownAndShowActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function startCountdownAndShow(state = initialState, action: StartCountdownAndShowActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_FAILURE:
			console.error('START_COUNTDOWN_AND_SHOW_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_SUCCESS:
			console.log('START_COUNTDOWN_AND_SHOW_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.START_COUNTDOWN_AND_SHOW_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
