import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { UpdateGuestInfoFailureAction } from '../interfaces/actions/UpdateGuestInfoFailureAction'
import type { UpdateGuestInfoStartAction } from '../interfaces/actions/UpdateGuestInfoStartAction'
import type { UpdateGuestInfoSuccessAction } from '../interfaces/actions/UpdateGuestInfoSuccessAction'
import type { UpdateMainMenuContent } from '../interfaces/UpdateMainMenuContent'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

export const updateGuestInfoStart = (): UpdateGuestInfoStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_GUEST_INFO_COMMAND_START }
}

export const updateGuestInfoFailure = (): UpdateGuestInfoFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_GUEST_INFO_COMMAND_FAILURE }
}

const updateGuestInfoSuccess = (): UpdateGuestInfoSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.UPDATE_GUEST_INFO_COMMAND_SUCCESS }
}

export default function updateGuestInfo() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(updateGuestInfoStart())

		try {
			const {
				dashboardInputs: {
					guestInfo: {
						guestDetails: { value: guestDetails },
						guestDetailsPictureURL: { value: guestDetailsPictureURL },
						guestExternalURL: { value: guestExternalURL },
						guestRoomPosterPictureURL: { value: guestRoomPosterPictureURL },
						guestTitle: { value: guestTitle },
					},
				},
			} = getState()

			const guestData: UpdateMainMenuContent = {
				command: 'updateMainMenuContent',
				guestDetails,
				guestDetailsPictureURL,
				guestExternalURL,
				guestRoomPosterPictureURL,
				guestTitle,
			}
			await sendCommandToStudio(guestData)

			dispatch(updateGuestInfoSuccess())
		} catch {
			dispatch(updateGuestInfoFailure())
		}
	}
}
