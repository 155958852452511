import type { PrepareCelebrationActions } from '../interfaces/actions/PrepareCelebrationActions'
import type { CommandState } from '../interfaces/CommandState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: CommandState = {
	isLoading: false,
	isAvailable: true,
}

export default function prepareCelebration(state = initialState, action: PrepareCelebrationActions): CommandState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_FAILURE:
			console.log('PREPARE_CELEBRATION_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_SUCCESS:
			console.log('PREPARE_CELEBRATION_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.PREPARE_CELEBRATION_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
