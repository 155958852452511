import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled from 'styled-components'

interface HeaderItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
	width?: string
}

export const NewsListContainer = styled.div``

export const NewsListContainerHeader = styled.h3`
	text-transform: uppercase;
`

export const NewsListHeader = styled.div`
	display: flex;
	justify-content: center;
`

export const HeaderItem = styled.span<HeaderItemProps>`
	width: ${({ width }) => width ?? '100%'};
`

export const NewsListUL = styled.ul`
	list-style: none;
	padding: 0;
`
