import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { FetchFeatureFlags } from '../../studioCommand/interfaces/FetchFeatureFlags'
import type { FeatureFlagsFetchFailureAction } from '../interfaces/FeatureFlagsFetchFailureAction'
import type { FeatureFlagsFetchStartAction } from '../interfaces/FeatureFlagsFetchStartAction'
import type { FeatureFlagsFetchSuccessAction } from '../interfaces/FeatureFlagsFetchSuccessAction'
import type { FeatureFlags } from '../interfaces/FeatureFlagsState'
import { REDUX_FEATURE_FLAGS_TYPES } from '../types'

const fetchFeatureFlagsStart = (): FeatureFlagsFetchStartAction => {
	return {
		type: REDUX_FEATURE_FLAGS_TYPES.FEATURE_FLAGS_FETCH_START,
	}
}

const fetchFeatureFlagsFailure = (): FeatureFlagsFetchFailureAction => {
	return {
		type: REDUX_FEATURE_FLAGS_TYPES.FEATURE_FLAGS_FETCH_FAILURE,
	}
}

const fetchFeatureFlagsSuccess = (payload: FeatureFlags): FeatureFlagsFetchSuccessAction => {
	return {
		type: REDUX_FEATURE_FLAGS_TYPES.FEATURE_FLAGS_FETCH_SUCCESS,
		payload,
	}
}

export default function fetchFeatureFlags() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(fetchFeatureFlagsStart())

		try {
			const getFeatureFlagsCommand: FetchFeatureFlags = {
				command: 'getFeatureFlags',
			}
			const featureFlags = await sendCommandToStudio<FeatureFlags>(getFeatureFlagsCommand)

			dispatch(fetchFeatureFlagsSuccess(featureFlags))
		} catch {
			dispatch(fetchFeatureFlagsFailure())
		}
	}
}
