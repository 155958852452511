import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { PickSeasonWinnerFailureAction } from '../interfaces/actions/PickSeasonWinnerFailureAction'
import type { PickSeasonWinnerStartAction } from '../interfaces/actions/PickSeasonWinnerStartAction'
import type { PickSeasonWinnerSuccessAction } from '../interfaces/actions/PickSeasonWinnerSuccessAction'
import type { PickSeasonWinner } from '../interfaces/PickSeasonWinner'
import type { PickSeasonWinnerResult } from '../interfaces/PickSeasonWinnerResult'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const pickSeasonWinnerStart = (): PickSeasonWinnerStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_SEASON_WINNER_START }
}

const pickSeasonWinnerFailure = (): PickSeasonWinnerFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_SEASON_WINNER_FAILURE }
}

const pickSeasonWinnerSuccess = (payload: PickSeasonWinnerResult): PickSeasonWinnerSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_SEASON_WINNER_SUCCESS, payload }
}

export default function pickSeasonWinner() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(pickSeasonWinnerStart())

		try {
			const pickSeasonWinnerCommand: PickSeasonWinner = {
				command: 'pickSeasonWinner',
			}

			const winner = await sendCommandToStudio<PickSeasonWinnerResult>(pickSeasonWinnerCommand)

			dispatch(pickSeasonWinnerSuccess(winner))
		} catch {
			dispatch(pickSeasonWinnerFailure())
		}
	}
}
