import type { Dispatch } from 'redux'
import type { CentipedeSettingsUpdateAction, CentipedeSettingsValidAction } from '../interfaces/GameSettingsActions'
import type { BaseGameSettingsProps, BlockbusterSettingsValidActionProps } from '../interfaces/GamesSettings'
import { REDUX_DASHBOARD_INPUT_TYPES } from '../types'

const centipedeSettingsStateUpdate = (props: BaseGameSettingsProps): CentipedeSettingsUpdateAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.CENTIPEDE_UPDATE,
		props,
	}
}

export const centipedeSettingsStateValid = (props: BlockbusterSettingsValidActionProps): CentipedeSettingsValidAction => {
	return {
		type: REDUX_DASHBOARD_INPUT_TYPES.CENTIPEDE_VALID,
		props,
	}
}

export default function centipedeSettingsState(props: BaseGameSettingsProps) {
	return (dispatch: Dispatch): void => {
		dispatch(centipedeSettingsStateUpdate(props))
	}
}
