import * as Sentry from '@sentry/react'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './components/App/App'
import { appEnv, isProduction } from './config'
import { sentryDSN, version } from './config/app'
import { store } from './redux/store'
import * as serviceWorker from './serviceWorker'

Sentry.init({
	dsn: sentryDSN,
	enabled: isProduction,
	release: `live-ops-panel@${version}`,
	environment: appEnv,
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root')
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
	<StrictMode>
		<Provider store={store}>
			<Sentry.ErrorBoundary>
				<App />
			</Sentry.ErrorBoundary>
		</Provider>
	</StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
