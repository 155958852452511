import type { Dispatch } from 'redux'
import type { StartGameToggleAction } from '../interfaces/actions/StartGameToggleAction'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const startGameToggle = (isAvailable: boolean): StartGameToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.START_GAME_TOGGLE, isAvailable }
}

export default function toggleStartGame(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(startGameToggle(isAvailable))
	}
}
