import { useCallback } from 'react'
import { nextShowTimeStateValid } from '../../../redux/dashboardInputs/actions/nextShowDateTimeState'
import type { DateTimeValidations } from '../../../redux/dashboardInputs/interfaces/NextShowTimeActions'
import type { StateTree } from '../../../redux/interfaces'
import { isDateTimeValid } from '../../validation/isDateTimeValid'
import { isDefinedNotNull } from '../../validation/isDefinedNotNull'
import { useAppDispatch, useAppSelector } from '..'

export const useNextShowTimeValidation = (): (() => boolean) => {
	const { time, date } = useAppSelector(
		({
			dashboardInputs: {
				nextShowDateTime: {
					time: { value: time },
					date: { value: date },
				},
			},
		}: StateTree) => ({
			time,
			date,
		}),
	)
	const dispatch = useAppDispatch()
	const triggerDispatchValidation = useCallback(
		(props: DateTimeValidations) => {
			dispatch(nextShowTimeStateValid(props))
		},
		[dispatch],
	)

	return (): boolean => {
		const isTimeValid = isDefinedNotNull(time)
		const isDateValid = isDefinedNotNull(date)

		if (isTimeValid && isDateValid && isDateTimeValid(date, time)) {
			return true
		}

		triggerDispatchValidation({ isDateValid: false, isTimeValid: false })

		return false
	}
}
