import { connect } from '@giantmachines/redux-websocket'
import type { Dispatch } from 'redux'
import { appDomain, appEnv } from '../../config'
import { store } from '../../redux/store'

export default function wsConnect(token: string): (dispatch: Dispatch) => void {
	const { getState } = store
	const {
		country: { value: country },
	} = getState()

	const webSocketURL = `wss://websocket.${appEnv}.${country}.${appDomain}`

	return (dispatch: Dispatch): void => {
		dispatch(connect(webSocketURL + '?Auth=Bearer+' + token))
	}
}
